import React from 'react';
import { footerElements } from './utils';
import styled from 'styled-components';
import {Link} from "react-router-dom";
const CopywriteWrapper = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 8px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  color: #ffffff;
  position: relative;

  @media only screen and (max-width: 600px) {
    font-size: 12px;
    margin-top: -75px;
    width: 100%;
    justify-content: center;
    margin-right: 0px;
  }
`;

const SocialWrapper = styled.div`
  display: flex;
`;

export const CustomFooter = () => {
  const date = new Date();

  return (
    <div
      style={{
        padding: 0,
      }}
    >
      <div className={"p-6 h-full bg-text-primary-900 flex flex-col lg:flex-row items-center justify-end justify-self-end align-self-end"}>
        <CopywriteWrapper className={"py-4 mt-4"}>
          {'© Ninja Protocol All rights reserved ' + date.getFullYear()}
        </CopywriteWrapper>
        <SocialWrapper>
          {footerElements.map((elem, index) => {
            return <a  href={elem.link}>
              {
                <span
                  className={"w-6 lg:w-8  h-6 lg:h-8"}
                  style={{
                    backgroundColor: '#F8F8F8',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: 3,
                    marginRight: 3,
                  }}
                >
        <img src={elem.img} height={20} alt="img" />
      </span>
              }
            </a>;
          })}
        </SocialWrapper>
      </div>
    </div>
  );
};

import React, { Component, useState } from 'react';
import { Typography } from 'antd';

const { Title } = Typography;

export const ErrorBoundary = ({children}) => {
  const [hasError, setHasError] = useState<boolean>(false);


    return hasError ? (
        <div>
          <div>
            <Title level={2}>Something went wrong.</Title>
            <Title level={4}>Please try again later.</Title>
          </div>
        </div>
      ) : children

}

import { NinjaTrade, Volume } from './types';


export default class NinjaApi {
  static URL: string = 'https://new-dex-api.ninjaprotocol.io/';

  static async get(path: string) {
    try {

      const response = await fetch(this.URL + path);
      if (response.ok) {
        const responseJson = await response.json();
        return responseJson.success ? responseJson.data : null;
      }
    } catch (err) {
      console.log(`Error fetching from API ${path}: ${err}`);
    }
    return null;
  }

  static async getRecentTrades(
    marketAddress: string,
  ): Promise<NinjaTrade[] | null> {
    return NinjaApi.get(`trades/address/${marketAddress}`);
  }

  static async getVolume(
    marketAddress: string,
  ): Promise<Volume | null> {
    return NinjaApi.get(`volumes/address/${marketAddress}`);
  }

  static async getAllVolumes(): Promise<Volume[] | null> {
    return NinjaApi.get(`/volumes`)
  }

}
export const NINJA_DATA_FEED = 'https://new-dex-api.ninjaprotocol.io/tv/';

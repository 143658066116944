import React from 'react';
import Link from '../components/Link';
import toast from 'react-hot-toast';
import { Toast } from 'flowbite-react';
import { CheckIcon, XIcon, InformationCircleIcon } from '@heroicons/react/solid';


interface MessageProps {
  message: string,
  description?: string |  JSX.Element,
  closeToast?: any,
  toastProps?: any
}
const Message = ({message, description, closeToast, toastProps}: MessageProps) => {
  return (
    <div className={"flex flex-col justify-center"}>
      <p className={"text-text-primary-100 m-0"}>{message}</p>
      {description && (<p className={"text-text-primary-300 m-0"}>{description}</p>)}
    </div>

  )
}
export function notify({
  message,
  description,
  txid,
  address,
  type = 'info',
  placement = 'bottomLeft',
}: {
  message: string;
  description?: string | JSX.Element;
  txid?: string;
  address?: string;
  type?: string;
  placement?: string;
}) {
  if (txid) {
    description = description ?
      <>
        {description}
        <Link
          external
          to={'https://explorer.solana.com/tx/' + txid}
          style={{ color: '#1890ff' }}
        >
          View transaction {txid.slice(0, 8)}...{txid.slice(txid.length - 8)}
        </Link>
      </>:(
      <Link
        external
        to={'https://explorer.solana.com/tx/' + txid}
        style={{ color: '#1890ff' }}
      >
        View transaction {txid.slice(0, 8)}...{txid.slice(txid.length - 8)}
      </Link>
    );
  }
  if (address) {
    description = description ?
      <>
        {description}
        <Link
          external
          to={'https://explorer.solana.com/address/' + address}
          style={{ color: '#1890ff' }}
        >
          View {address.slice(0, 8)}...{address.slice(address.length - 8)}
        </Link>
      </>:(
        <Link
          external
          to={'https://explorer.solana.com/address/' + address}
          style={{ color: '#1890ff' }}
        >
          View {address.slice(0, 8)}...{address.slice(address.length - 8)}
        </Link>
      );
  }
  console.log("toasting")
  toast.custom((t) => (
    <div
      className={`${
        t.visible ? 'animate-enter' : 'animate-leave'
      } max-w-md w-full bg-primary-bg  rounded-lg pointer-events-auto flex shadow-lineShadow`}
    >
      <div className="flex-1 w-0 p-4">
        <div className="flex items-center">
          <div className="flex-shrink-0 pt-0.5 mr-2">
            <div className={`inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg ${type=== "success" ? "text-green-400" : type === "error" ? "text-red-400" : "text-blue-600"}`}>
              {type === "success" ? (<CheckIcon className="h-5 w-5" />) : type === "error" ?  <XIcon className="h-5 w-5" /> : <InformationCircleIcon />}
            </div>
          </div>
          <Message message={message} description={description} />
        </div>
      </div>
      <div className="flex border-l border-gray-400">
        <button
          onClick={() => toast.dismiss(t.id)}
          className="w-full rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-text-primary hover:text-primary-400"
        >
          <XIcon className="h-5 w-5" />
        </button>
      </div>
    </div>
  ))
}

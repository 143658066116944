import * as BufferLayout from 'buffer-layout';
import { AccountInfo, Connection, PublicKey } from '@solana/web3.js';
import { WRAPPED_SOL_MINT } from '@project-serum/serum/lib/token-instructions';
import { TokenAccount, WalletToken } from './types';
import { useAllMarkets, useCustomMarkets, useTokenAccounts } from './markets';
import { getMultipleSolanaAccounts } from './send';
import { useAsyncData } from './fetch-loop';
import tuple from 'immutable-tuple';
import BN from 'bn.js';
import { useEffect, useMemo, useState } from 'react';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import {
  Strategy,
  TokenInfo,
  TokenListProvider,
} from '@solana/spl-token-registry';
import { AccountLayout, MintLayout } from '@solana/spl-token';
import { chunk } from '@metaplex-foundation/js';

export const ACCOUNT_LAYOUT = BufferLayout.struct([
  BufferLayout.blob(32, 'mint'),
  BufferLayout.blob(32, 'owner'),
  BufferLayout.nu64('amount'),
  BufferLayout.blob(93),
]);

export const MINT_LAYOUT = BufferLayout.struct([
  BufferLayout.blob(36),
  BufferLayout.blob(8, 'supply'),
  BufferLayout.u8('decimals'),
  BufferLayout.u8('initialized'),
  BufferLayout.blob(36),
]);

export function parseTokenAccountData(
  data: any,
): { mint: PublicKey; owner: PublicKey; tokenAmount: { amount: string
    decimals: number
    uiAmount: number
    uiAmountString: string }, state: string, isNative:boolean } {
  let { mint, owner, tokenAmount, state, isNative } = data.info;
  return {
    mint: new PublicKey(mint),
    owner: new PublicKey(owner),
    tokenAmount,
    state,
    isNative
  };
}

export interface MintInfo {
  decimals: number;
  initialized: boolean;
  supply: BN;
}

export function parseTokenMintData(data): MintInfo {
  let { decimals, initialized, supply } = MINT_LAYOUT.decode(data);
  return {
    decimals,
    initialized: !!initialized,
    supply: new BN(supply, 10, 'le'),
  };
}

export function getOwnedAccountsFilters(publicKey: PublicKey) {
  return [
    {
      memcmp: {
        offset: ACCOUNT_LAYOUT.offsetOf('owner'),
        bytes: publicKey.toBase58(),
      },
    },
    {
      dataSize: ACCOUNT_LAYOUT.span,
    },
  ];
}

export const TOKEN_PROGRAM_ID = new PublicKey(
  'TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA',
);

export async function getOwnedTokenAccounts(
  connection: Connection,
  publicKey: PublicKey,
): Promise<Array<{ publicKey: PublicKey; accountInfo: AccountInfo<any> }>> {
  let filters = getOwnedAccountsFilters(publicKey);
  // @ts-ignore
  let resp = await connection._rpcRequest('getProgramAccounts', [
    TOKEN_PROGRAM_ID.toBase58(),
    {
      encoding: 'jsonParsed',
      commitment: connection.commitment,
      filters,
    },
  ]);

  if (resp.error) {
    throw new Error(
      'failed to get token accounts owned by ' +
        publicKey.toBase58() +
        ': ' +
        resp.error.message,
    );
  }
  return resp.result.map(
    ({ pubkey, account: { data, executable, owner, lamports } }) => ({
      publicKey: new PublicKey(pubkey),
      accountInfo: {
        data: data.parsed,
        executable,
        owner: new PublicKey(owner),
        lamports,
      },
    }),
  );
}

export async function getTokenAccountInfo(
  connection: Connection,
  ownerAddress: PublicKey,
) {
  let [splAccounts, account] = await Promise.all([
    getOwnedTokenAccounts(connection, ownerAddress),
    connection.getAccountInfo(ownerAddress),
  ]);
  const parsedSplAccounts: TokenAccount[] = splAccounts.map(
    ({ publicKey, accountInfo }) => {
      return {
        pubkey: publicKey,
        account: accountInfo,
        effectiveMint: parseTokenAccountData(accountInfo.data).mint,
      };
    },
  );
  return parsedSplAccounts.concat({
    pubkey: ownerAddress,
    account,
    effectiveMint: WRAPPED_SOL_MINT,
  });
}

// todo: use this to map custom mints to custom tickers. Add functionality once custom markets store mints
export function useMintToTickers(): { [mint: string]: string } {
  const { customMarkets } = useCustomMarkets();
  const { mints } = useTokenMints();

  return useMemo(() => {
    return Object.fromEntries(
      (mints || []).map((mint) => [mint.address, mint.symbol]),
    );
    // eslint-disable-next-line
  }, [customMarkets.length]);
}

export function useTokenMints(): {
  mints: (TokenInfo)[] | null;
  tokenMintsLoaded: boolean;
  refreshTokenMints: () => void;
} {

  const [loaded, setLoaded] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [mints, setMints] = useState<(TokenInfo)[] | null>(null)
  const refreshTokenMints = () => {
    setRefresh((prev) => prev + 1);
  };
  useEffect(() => {
    const getAllMints = async () => {
      setLoaded(false);
      const _mints = (await new TokenListProvider().resolve(Strategy.Static)).getList()
      setMints(_mints);
      setLoaded(true);
    };
    getAllMints()
  }, [refresh])
  return {
    mints,
    tokenMintsLoaded: loaded,
    refreshTokenMints,
  };
}

export function useWalletTokens(): {
  tokens: (WalletToken  | undefined)[] | null;
  walletTokensLoaded: boolean;
  refreshWalletTokens: () => void;
} {
  const { connection } = useConnection();
  const { connected, wallet, publicKey } = useWallet();
  const { mints, refreshTokenMints, tokenMintsLoaded } = useTokenMints();
  const [loaded, setLoaded] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [tokens, setTokens] = useState<
    (WalletToken  | undefined)[] | null
    >(null);
  const [lastRefresh, setLastRefresh] = useState(0);

  const refreshTokens = () => {
    if (new Date().getTime() - lastRefresh > 10 * 1000) {
      setRefresh((prev) => prev + 1);
    } else {
      console.log('not refreshing');
    }
  };
  useEffect(() => {
    setRefresh(prevState => prevState + 1)
  }, [tokenMintsLoaded, connected])
  useEffect(() => {
    if (connected && wallet && mints ) {
      const getWalletTokens = async () => {
        setLoaded(false);
        let _tokens:(WalletToken )[] = [];
        let walletTokens = await connection.getProgramAccounts(TOKEN_PROGRAM_ID, {filters:getOwnedAccountsFilters(publicKey!)})

        _tokens = walletTokens.map( token => {
          let decodedToken = AccountLayout.decode(token.account.data);
          return {
            token: mints.find(mint => mint.address === new PublicKey(decodedToken.mint).toBase58()),
            address: token.pubkey,
            tokenAccount: decodedToken,
            mint: undefined
          }
        })
        let mintAccounts = (await Promise.all(chunk(_tokens, 99).map(async tokens => await connection.getMultipleAccountsInfo(tokens.map(t => t?.tokenAccount.mint))))).flat(1)
        for (let i = 0; i < _tokens.length; i++) {
          if (mintAccounts[i]) {
            _tokens[i].mint = MintLayout.decode(mintAccounts[i]?.data)
          }
        }
        setTokens(_tokens);
        setLastRefresh(new Date().getTime());
        setLoaded(true);
      };
      getWalletTokens();
    }
  }, [connection, connected, wallet, refresh]);
  return {
    tokens,
    walletTokensLoaded: loaded,
    refreshWalletTokens: refreshTokens,
  };
};

const _VERY_SLOW_REFRESH_INTERVAL = 5000 * 1000;
const _SLOW_REFRESH_INTERVAL = 5 * 1000;
// todo: move this to using mints stored in static market infos once custom markets support that.
export function useMintInfos(): [
  (
    | {
        [mintAddress: string]: {
          decimals: number;
          initialized: boolean;
        } | null;
      }
    | null
    | undefined
  ),
  boolean,
] {
  const { connection } = useConnection();
  const [tokenAccounts] = useTokenAccounts();
  const [allMarkets] = useAllMarkets();
  const allMints = (tokenAccounts || [])
    .map((account) => account.effectiveMint)
    .concat(
      (allMarkets || []).map((marketInfo) => marketInfo.market.baseMintAddress),
    )
    .concat(
      (allMarkets || []).map(
        (marketInfo) => marketInfo.market.quoteMintAddress,
      ),
    );
  const uniqueMints = [...new Set(allMints.map((mint) => mint.toBase58()))].map(
    (stringMint) => new PublicKey(stringMint),
  );

  const getAllMintInfo = async () => {
    const mintInfos = await getMultipleSolanaAccounts(connection, uniqueMints);
    return Object.fromEntries(
      Object.entries(mintInfos.value).map(([key, accountInfo]) => [
        key,
        accountInfo && parseTokenMintData(accountInfo.data),
      ]),
    );
  };

  return useAsyncData(
    getAllMintInfo,
    tuple(
      'getAllMintInfo',
      connection,
      (tokenAccounts || []).length,
      (allMarkets || []).length,
    ),
    { refreshInterval: _VERY_SLOW_REFRESH_INTERVAL },
  );
}

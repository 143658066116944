import React, {
  ButtonHTMLAttributes,
  FC,
  FunctionComponent,
  InputHTMLAttributes, MouseEvent, MouseEventHandler,
  ReactNode, useCallback, useEffect,
  useId, useMemo, useRef, useState,
} from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletIcon } from '@solana/wallet-adapter-react-ui/src/WalletIcon';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import { Spinner } from 'flowbite-react';

interface ButtonProps {
  onClick?: (x?) => void;
  disabled?: boolean;
  className?: string;
  primary?: boolean;
  loading?: boolean;
  children?: ReactNode | string
  props?: ButtonHTMLAttributes<any>

}

interface ToggleButtonProps {
  classNames?: string,
  onChange: (e) => void,
  checked: boolean,
  children: ReactNode | string,
  props?: InputHTMLAttributes<any>
}

export const ToggleButton =  ({ classNames, onChange, children, checked, props}: ToggleButtonProps) => {
  const toggleId = useId();
  return (
    <div className={"flex items-center"}>
       <span
         className="mr-2 text-sm font-medium text-white">{children}</span>
    <label htmlFor={toggleId}
           className="inline-flex relative items-center cursor-pointer">

      <input type="checkbox" value="" id={toggleId}
             className="sr-only peer" checked={checked} onChange={onChange} />
        <div
          className={`w-11 h-6 bg-primary-bg-300 peer-focus:outline-none rounded-full border border-primary-bg-700 peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-secondary-bg after:rounded-full after:h-5 after:w-5 after:transition-all ${checked? "bg-secondary-bg-500 after:bg-primary-bg-300" : ""}`} />

    </label>
    </div>
  )
}
export const WalletMultiButton: FC<ButtonProps> = ({ className, ...props }) => {
  const { publicKey, wallet, disconnect } = useWallet();
  const { setVisible } = useWalletModal();
  const [copied, setCopied] = useState(false);
  const [active, setActive] = useState(false);
  const ref = useRef<HTMLUListElement>(null);

  const base58 = useMemo(() => publicKey?.toBase58(), [publicKey]);
  const content = useMemo(() => {
    if (!wallet || !base58) return null;
    return base58.slice(0, 4) + '..' + base58.slice(-4);
  }, [wallet, base58]);

  const copyAddress = useCallback(async () => {
    if (base58) {
      await navigator.clipboard.writeText(base58);
      setCopied(true);
      setTimeout(() => setCopied(false), 400);
    }
  }, [base58]);

  const openDropdown = useCallback(() => {
    setActive(true);
  }, []);

  const closeDropdown = useCallback(() => {
    setActive(false);
  }, []);

  const openModal = useCallback(() => {
    setVisible(true);
    closeDropdown();
  }, [closeDropdown]);

  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      const node = ref.current;

      // Do nothing if clicking dropdown or its descendants
      if (!node || node.contains(event.target as Node)) return;

      closeDropdown();
    };
    //@ts-ignore
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      //@ts-ignore
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, closeDropdown]);

  if (!wallet) return <WalletModalButton {...props}></WalletModalButton>;
  if (!base58) return <WalletConnectButton {...props}></WalletConnectButton>;

  return (
    <div className={`wallet-adapter-dropdown ${className}`}>
      <Button
        aria-expanded={active}
        className={className}
        primary={true}
        onClick={openDropdown}
        {...props}
      >
        {content}
      </Button>
      <ul
        aria-label="dropdown-list "
        className={`absolute z-10 grid grid-rows-[1fr] gap-y-2 p-2 top-[100%] right-0 m-0 bg-primary-bg rounded-lg opacity-0 ${active && 'wallet-adapter-dropdown-list-active'} shadow-lineShadow`}
        ref={ref}
        role="menu"
      >
        <li onClick={copyAddress} className="wallet-adapter-dropdown-list-item hover:bg-secondary-bg" role="menuitem">
          {copied ? 'Copied' : 'Copy address'}
        </li>
        <li onClick={openModal} className="wallet-adapter-dropdown-list-item hover:bg-secondary-bg" role="menuitem">
          Change wallet
        </li>
        <li onClick={disconnect} className="wallet-adapter-dropdown-list-item hover:bg-secondary-bg" role="menuitem">
          Disconnect
        </li>
      </ul>
    </div>
  );
};
export const WalletModalButton: FC<ButtonProps> = ({ className = 'Select Wallet', onClick, ...props }) => {
  const { visible, setVisible } = useWalletModal();

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (onClick) onClick(event);
      if (!event.defaultPrevented) setVisible(!visible);
    },
    [onClick, visible]
  );

  return (
    <Button className={className} onClick={handleClick} primary={true} {...props}>
      Connect
    </Button>
  );
};

export const WalletConnectButton: FC<ButtonProps> = ({  disabled, onClick, className, ...props }) => {
  const { wallet, connect, connecting, connected } = useWallet();

  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      if (onClick) onClick(event);
      if (!event.defaultPrevented) connect().catch(() => {});
    },
    [onClick, connect]
  );

  const content = useMemo(() => {
    if (connecting) return 'Connecting ...';
    if (connected) return 'Connected';
    if (wallet) return 'Connect';
    return 'Connect Wallet';
  }, [ connecting, connected, wallet]);

  return (
    <Button
      className={className}
      primary={true}
      disabled={disabled || !wallet || connecting || connected}
      onClick={handleClick}
      {...props}
    >
      {content}
    </Button>
  );
};

export const Button: FunctionComponent<ButtonProps> = ({
                                                         children,
                                                         onClick,
                                                         disabled = false,
                                                         className,
  primary,
  loading,
                                                         ...props
                                                       }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`${primary? "bg-secondary-bg-400 hover:bg-secondary-bg-500 shadow-secondary-bg-400/50  font-medium rounded-xl text-md hover:brightness-[1.1] px-10 py-1.5 mr-2 mb-2" : "bg-primary-bg"} whitespace-nowrap  px-6 py-2 font-bold  focus:outline-none disabled:cursor-not-allowed disabled:bg-text-primary-700 disabled:hover:none ${className}`}
      {...props}
    >
      {
        loading ? (<div className={"w-full h-full flex items-center justify-center"}>
          <Spinner className={"bg-text-primary text-text-primary"} />
        </div>) :  children
      }
    </button>
  );
};


export const LinkButton: FunctionComponent<ButtonProps> = ({
                                                             children,
                                                             onClick,
                                                             disabled = false,
                                                             className,
                                                             primary,
                                                             ...props
                                                           }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`border-0 font-bold ${
        primary ? 'text-th-primary' : 'text-th-fgd-2'
      } underline hover:no-underline hover:opacity-60 focus:outline-none disabled:cursor-not-allowed disabled:underline disabled:opacity-60 ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};


interface DropDownButtonProps {
  onClick?: (x?) => void;
  disabled?: boolean;
  className?: string;
  primary?: boolean;
  expanded?: boolean;
  children: ReactNode | string
}

export const DropDownButton: FunctionComponent<DropDownButtonProps> = ({
                                                                     children,
                                                                     onClick,
                                                                     disabled = false,
                                                                     className,
                                                                     primary,
                                                                      expanded,
                                                                     ...props
                                                                   }) => {
  return (
    <div className="relative inline-block shadow-paneShadow rounded-xl">
      <button
        type="button"
        className="bg-transparent p-2"
        onClick={onClick}
      >
        <svg height="32px" id="Layer_1" version="1.1" viewBox="0 0 32 32" width="32px"><path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z" style={{fill:"#fff"}}/>
        </svg>

      </button>
      {expanded && (
        <div className="absolute rounded-2 right-0 origin-center mt-2 w-48 shadow-xl z-1 p-2 bg-primary-bg z-40">
          <div
            className="rounded divide-y divide-gray-100">
            {children}
          </div>
        </div>
      )}

    </div>
  );
};


export const ExternalLinkButton: FunctionComponent<ButtonProps> = ({
                                                                     children,
                                                                     onClick,
                                                                     disabled = false,
                                                                     className,
                                                                     primary,
                                                                     ...props
                                                                   }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`border-0 font-bold
       underline text-white-600 hover:no-underline hover:opacity-60 focus:outline-none disabled:cursor-not-allowed disabled:underline disabled:opacity-60 ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};


export const IconButton: FunctionComponent<ButtonProps> = ({
                                                             children,
                                                             onClick,
                                                             disabled = false,
                                                             className,
                                                             ...props
                                                           }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`${className} flex h-7 w-7 items-center justify-center rounded-full bg-th-bkg-4 text-th-fgd-1 hover:text-th-primary focus:outline-none disabled:cursor-not-allowed
      disabled:bg-th-bkg-4 disabled:text-th-fgd-4 disabled:hover:text-th-fgd-4`}
      {...props}
    >
      {children}
    </button>
  );
};

interface ButtonGroupProps {
  activeValue: string;
  className?: string;
  onChange: (x) => void;
  unit?: string;
  values: Array<any>;
  names?: Array<string>;
}

export const ButtonGroup: FunctionComponent<ButtonGroupProps> = ({
                                                                   activeValue,
                                                                   className,
                                                                   unit,
                                                                   values,
                                                                   onChange,
                                                                   names,
                                                                 }) => {
  return (
    <div className='rounded-md bg-th-bkg-3'>
      <div className='relative flex'>
        {activeValue && values.includes(activeValue) ? (
          <div
            className={`default-transition absolute left-0 top-0 h-full transform rounded-md bg-th-bkg-4`}
            style={{
              transform: `translateX(${
                values.findIndex((v) => v === activeValue) * 100
              }%)`,
              width: `${100 / values.length}%`,
            }}
          />
        ) : null}
        {values.map((v, i) => (
          <button
            className={`${className} default-transition relative w-1/2 cursor-pointer rounded-md px-2 py-1.5 text-center text-xs font-normal
              ${
              v === activeValue
                ? `text-th-primary`
                : `text-th-fgd-2 hover:text-th-primary`
            }
            `}
            key={`${v}${i}`}
            onClick={() => onChange(v)}
            style={{
              width: `${100 / values.length}%`,
            }}
          >
            {names ? (unit ? names[i] + unit : names[i]) : unit ? v + unit : v}
          </button>
        ))}
      </div>
    </div>
  );
};


import React, { useState } from 'react';
import { Col, Row, Select, Typography } from 'antd';
import {
  getMarketInfos,
  MarketProvider,
  useCustomMarkets,
} from '../../utils/markets';
import { notify } from '../../utils/notifications';
import FloatingElement from '../Layout/FloatingElement';
import WalletConnect from '../WalletConnect';
import { ConvertFormSubmit } from './subcomponents';
import { useWallet } from '@solana/wallet-adapter-react';

const { Option } = Select;
const { Title } = Typography;

export default function ConvertForm() {
  const { connected, wallet } = useWallet();

  const { customMarkets } = useCustomMarkets();
  const marketInfos = getMarketInfos(customMarkets);
  const [marketAddress, setMarketAddress] = useState<string | null>(null);

  const [fromToken, setFromToken] = useState<string | undefined>(undefined);
  const [toToken, setToToken] = useState<string | undefined>(undefined);
  const [size, setSize] = useState<number | undefined>(undefined);

  const marketInfosbyName = Object.fromEntries(
    marketInfos.map((market) => [market.name, market]),
  );

  const tokenConvertMap: Map<string, Set<string>> = new Map();
  Object.keys(marketInfosbyName).forEach((market) => {
    let [base, quote] = market.split('/');
    !tokenConvertMap.has(base)
      ? tokenConvertMap.set(base, new Set([quote]))
      : tokenConvertMap.set(
        base,
        new Set([...(tokenConvertMap.get(base) || []), quote]),
      );
    !tokenConvertMap.has(quote)
      ? tokenConvertMap.set(quote, new Set([base]))
      : tokenConvertMap.set(
        quote,
        new Set([...(tokenConvertMap.get(quote) || []), base]),
      );
  });

  const setMarket = (toToken) => {
    const marketInfo = marketInfos
      .filter((marketInfo) => !marketInfo.deprecated)
      .find(
        (marketInfo) =>
          marketInfo.name === `${fromToken}/${toToken}` ||
          marketInfo.name === `${toToken}/${fromToken}`,
      );
    if (!marketInfo) {
      console.warn(
        `Could not find market info for market names ${fromToken}/${toToken} or ${toToken}/${fromToken}`,
      );
      notify({
        message: 'Invalid market',
        type: 'error',
      });
      return;
    }
    setMarketAddress(marketInfo.address.toBase58());
    setToToken(toToken);
  };

  return (
    <FloatingElement style={{ maxWidth: 500 }}>
      <Title level={3}>Convert</Title>
      {!connected && (
        <Row justify="center">
          <Col>
            <WalletConnect />
          </Col>
        </Row>
      )}
      {tokenConvertMap && connected && (
        <>
          <Row style={{ marginBottom: 8 }}>
            <Col>
              <Select
                style={{ minWidth: 300 }}
                placeholder="Select a token"
                value={fromToken}
                onChange={(token) => {
                  setFromToken(token);
                  setToToken(undefined);
                }}
              >
                {Array.from(tokenConvertMap.keys()).map((token) => (
                  <Option value={token} key={token}>
                    {token}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
          {fromToken && (
            <Row style={{ marginBottom: 8 }}>
              <Col>
                <Select
                  style={{ minWidth: 300 }}
                  value={toToken}
                  onChange={setMarket}
                >
                  {[...(tokenConvertMap.get(fromToken) || [])].map((token) => (
                    <Option value={token} key={token}>
                      {token}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          )}
          {fromToken && toToken && (
            <MarketProvider
              marketAddress={marketAddress}
              setMarketAddress={setMarketAddress}
            >
              <ConvertFormSubmit
                size={size}
                setSize={setSize}
                fromToken={fromToken}
                toToken={toToken}
                wallet={wallet?.adapter}
                customMarkets={customMarkets}
              />
            </MarketProvider>
          )}
        </>
      )}
    </FloatingElement>
  );
}


import {
  Input,
  Radio,
  Slider,
  Switch,
} from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import {
  useFeeDiscountKeys,
  useLocallyStoredFeeDiscountKey,
  useMarket,
  useMarkPrice,
  useSelectedBaseCurrencyAccount,
  useSelectedBaseCurrencyBalances,
  useSelectedOpenOrdersAccount,
  useSelectedQuoteCurrencyAccount,
  useSelectedQuoteCurrencyBalances,
} from '../../utils/markets';
import { notify } from '../../utils/notifications';
import {
  floorToDecimal,
  getDecimalCount,
  roundToDecimal,
} from '../../utils/utils';
import FloatingElement from '../Layout/FloatingElement';
import { getUnixTs, placeOrder } from '../../utils/send';
import { SwitchChangeEventHandler } from 'antd/es/switch';
import { refreshCache } from '../../utils/fetch-loop';
import tuple from 'immutable-tuple';

import styled from 'styled-components';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { PrefixSuffixInput, SuffixInput } from '../Input';
import { Button, ToggleButton } from '../Button';


export default function TradeForm({
  setChangeOrderRef,
}: {
  style?: any;
  setChangeOrderRef?: (
    // @ts-ignore
    ref: ({ size, price }: { size?: number; price?: number }) => void,
  ) => void;
}) {
  const [side, setSide] = useState<'buy' | 'sell'>('buy');
  const { baseCurrency, quoteCurrency, market } = useMarket();
  const baseCurrencyBalances = useSelectedBaseCurrencyBalances();
  const quoteCurrencyBalances = useSelectedQuoteCurrencyBalances();
  const baseCurrencyAccount = useSelectedBaseCurrencyAccount();
  const quoteCurrencyAccount = useSelectedQuoteCurrencyAccount();
  const openOrdersAccount = useSelectedOpenOrdersAccount(true);
  const { wallet, connected, publicKey, sendTransaction } = useWallet();
  const { connection } = useConnection();
  const markPrice = useMarkPrice();
  useFeeDiscountKeys();
  const { storedFeeDiscountKey: feeDiscountKey } =
    useLocallyStoredFeeDiscountKey();

  const [postOnly, setPostOnly] = useState(false);
  const [ioc, setIoc] = useState(false);
  const [baseSize, setBaseSize] = useState<number | undefined>(undefined);
  const [quoteSize, setQuoteSize] = useState<number | undefined>(undefined);
  const [price, setPrice] = useState<number | undefined>(undefined);
  const [submitting, setSubmitting] = useState(false);
  const [sizeFraction, setSizeFraction] = useState(0);

  const availableQuote =
    openOrdersAccount && market
      ? market.quoteSplSizeToNumber(openOrdersAccount.quoteTokenFree)
      : 0;

  let quoteBalance = (quoteCurrencyBalances || 0) + (availableQuote || 0);
  let baseBalance = baseCurrencyBalances || 0;
  let sizeDecimalCount =
    market?.minOrderSize && getDecimalCount(market.minOrderSize);
  let priceDecimalCount = market?.tickSize && getDecimalCount(market.tickSize);


  useEffect(() => {
    setChangeOrderRef && setChangeOrderRef(doChangeOrder);
  }, [setChangeOrderRef]);

  useEffect(() => {
    baseSize && price && onSliderChange(sizeFraction);
  }, [side]);

  useEffect(() => {
    updateSizeFraction();
  }, [price, baseSize, quoteSize]);

  useEffect(() => {
    const warmUpCache = async () => {
      try {
        if (!wallet || !publicKey || !market) {
          console.log(`Skipping refreshing accounts`);
          return;
        }
        const startTime = getUnixTs();
        console.log(`Refreshing accounts for ${market.address}`);
        await market?.findOpenOrdersAccountsForOwner(connection, publicKey);
        await market?.findBestFeeDiscountKey(connection, publicKey);
        const endTime = getUnixTs();
        console.log(
          `Finished refreshing accounts for ${market.address} after ${
            endTime - startTime
          }`,
        );
      } catch (e) {
        console.log(`Encountered error when refreshing trading accounts: ${e}`);
      }
    };
    warmUpCache();
    const id = setInterval(warmUpCache, 30_000);
    return () => clearInterval(id);
  }, [market, connection, wallet, publicKey]);

  const onSetBaseSize = (baseSize: number | undefined) => {
    setBaseSize(baseSize);
    if (!baseSize) {
      setQuoteSize(undefined);
      return;
    }
    let usePrice = price || markPrice;
    if (!usePrice) {
      setQuoteSize(undefined);
      return;
    }
    const rawQuoteSize = baseSize * usePrice;
    const quoteSize =
      baseSize && roundToDecimal(rawQuoteSize, sizeDecimalCount);
    setQuoteSize(quoteSize);
  };

  const onSetQuoteSize = (quoteSize: number | undefined) => {
    setQuoteSize(quoteSize);
    if (!quoteSize) {
      setBaseSize(undefined);
      return;
    }
    let usePrice = price || markPrice;
    if (!usePrice) {
      setBaseSize(undefined);
      return;
    }
    const rawBaseSize = quoteSize / usePrice;
    const baseSize = quoteSize && roundToDecimal(rawBaseSize, sizeDecimalCount);
    setBaseSize(baseSize);
  };

  const doChangeOrder = ({
    size,
    price,
  }: {
    size?: number;
    price?: number;
  }) => {
    const formattedSize = size && roundToDecimal(size, sizeDecimalCount);
    const formattedPrice = price && roundToDecimal(price, priceDecimalCount);
    formattedSize && onSetBaseSize(formattedSize);
    formattedPrice && setPrice(formattedPrice);
  };

  const updateSizeFraction = () => {
    const rawMaxSize =
      side === 'buy' ? quoteBalance / (price || markPrice || 1) : baseBalance;
    console.log(rawMaxSize, baseBalance)
    const maxSize = floorToDecimal(rawMaxSize, sizeDecimalCount);
    const sizeFraction = Math.min(((baseSize || 0) / maxSize) * 100, 100);
    setSizeFraction(sizeFraction);
  };

  const onSliderChange = (value) => {
    if (!value || !value.target) return
    value = Number(value.target.value)
    if (!price && markPrice) {
      let formattedMarkPrice: number | string = priceDecimalCount
        ? markPrice.toFixed(priceDecimalCount)
        : markPrice;
      setPrice(
        typeof formattedMarkPrice === 'number'
          ? formattedMarkPrice
          : parseFloat(formattedMarkPrice),
      );
    }

    let newSize;
    if (side === 'buy') {
      if (price || markPrice) {
        newSize = ((quoteBalance / (price || markPrice || 1)) * value) / 100;
      }
    } else {
      newSize = (baseBalance * value) / 100;
    }
    setSizeFraction(newSize === 0 ? 0 : value)

    // round down to minOrderSize increment
    let formatted = floorToDecimal(newSize, sizeDecimalCount);

    onSetBaseSize(formatted);

  };

  const postOnChange: SwitchChangeEventHandler = (checked) => {
    if (checked) {
      setIoc(false);
    }
    setPostOnly(checked);
  };
  const iocOnChange: SwitchChangeEventHandler = (checked) => {
    if (checked) {
      setPostOnly(false);
    }
    setIoc(checked);
  };

  async function onSubmit() {
    if (!connected) {
      notify({
        message: 'Connect wallet to continue',
        type: 'error',
      });
      return;
    }
    else if (!price) {
      console.warn('Missing price');
      notify({
        message: 'Missing price',
        type: 'error',
      });
      return;
    } else if (!baseSize) {
      console.warn('Missing size');
      notify({
        message: 'Missing size',
        type: 'error',
      });
      return;
    }

    setSubmitting(true);
    try {
      if (!wallet) {
        return null;
      }

      await placeOrder({
        side,
        price,
        size: baseSize,
        orderType: ioc ? 'ioc' : postOnly ? 'postOnly' : 'limit',
        market,
        connection: connection,
        wallet: wallet.adapter,
        baseCurrencyAccount: baseCurrencyAccount?.pubkey,
        quoteCurrencyAccount: quoteCurrencyAccount?.pubkey,
        feeDiscountPubkey: feeDiscountKey,
        sendTransaction
      });
      refreshCache(tuple('getTokenAccounts', wallet, connected));
      setPrice(undefined);
      onSetBaseSize(undefined);
    } catch (e) {
      console.warn(e);
      notify({
        message: 'Error placing order',
        description: (e as any).message,
        type: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  }


  return (
    <div className={'rounded-2xl bg-primary-bg w-full shadow-lineShadow h-full'}>
      <ul  className="inline-flex w-full ">
        <li
          className={`px-4 py-2 font-semibold rounded-tl-xl w-1/2 flex justify-center ${side === 'buy' ? "border-b-2 border-r-2 text-green-500" : " bg-secondary-bg-500"}`} onClick={() => setSide("buy")}>
          <span>BUY</span></li>
        <li
          className={`px-4 py-2 font-semibold  rounded-tr-xl w-1/2 flex justify-center ${side === 'sell' ? "border-b-2  text-red-500 border-l-2" : " bg-secondary-bg-500"}` } onClick={() => setSide("sell")}>
          <span>SELL</span></li>
      </ul>
      <div className={"flex flex-col max-h-fit h-fit px-5"}>
        <div >
          <span className={"text-xs font-bold text-text-primary-300"}>Price:</span>

          <SuffixInput onChange={(e) => setPrice(parseFloat(e.target.value))} suffix={quoteCurrency || "-"} props={{step: market?.minOrderSize || 1,
                              value: price, type: "number"}} />
        </div>
        <div className={"grid gap-y-2 mt-4"}>
          <span className={"text-xs font-bold text-text-primary-300"}>Size:</span>
          <div className={"xl:grid-cols-2 grid gap-y-2 sm:grid-cols-2 lg:grid-cols-1 gap-x-2 mb-4"}>
          <SuffixInput onChange={(e) => onSetBaseSize(parseFloat(e.target.value))} suffix={baseCurrency || "-"} props={{step: market?.tickSize || 1,
            value:baseSize, type: "number", }} />
          <SuffixInput onChange={(e) => onSetQuoteSize(parseFloat(e.target.value))} suffix={quoteCurrency || "-"} props={{step: market?.minOrderSize || 1,
            value:quoteSize, type: "number", }} />
          </div>
        </div>
        <div className={"grid grid-cols-1 grid-rows mb-2"}>
          <div className={"flex justify-between width-full text-xs font-bold text-text-primary-300"}>
            <span>Total:</span>
            <span>{baseCurrency}</span>
          </div>
          <div className={"flex flex-col space-y-2 pb-2 w-full"}>
              <input value={sizeFraction} onChange={onSliderChange} type="range" className="w-full accent-secondary-bg-400 border-none" min="0" max="100" step="0.1" list={"size-ranges"}/>
              <ul className="flex justify-between w-full px-[10px] h-4">
                <li className="flex justify-center relative"><span
                  className="absolute">0%</span></li>
                <li className="flex justify-center relative"><span
                  className="absolute">25%</span></li>
                <li className="flex justify-center relative"><span
                  className="absolute">50%</span></li>
                <li className="flex justify-center relative"><span
                  className="absolute">75%</span></li>
                <li className="flex justify-center relative"><span
                  className="absolute">100%</span></li>
                <datalist id={"size-ranges"}>
                  <option>0</option>
                  <option>25</option>
                  <option>50</option>
                  <option>75</option>
                  <option>100</option>
                </datalist>
              </ul>

          </div>
        </div>
        <div className={"flex justify-between items-center"}>
          <div className={"flex items-center"}>
            <ToggleButton  onChange={(e) => setPostOnly(!postOnly)} checked={postOnly}>POST</ToggleButton>
            <ToggleButton  classNames={"ml-2"} onChange={(e) => setIoc(!ioc)} checked={ioc}>IOC</ToggleButton>
          </div>

        </div>
        <SubmitButton disabled={false} loading={submitting} onClick={onSubmit}>{side.toUpperCase()} {baseCurrency}</SubmitButton>
      </div>
    </div>
  );
}

interface SubmitButtonProps {
  disabled: boolean,
  onClick: () => void,
  loading: boolean,
  children: ReactNode | string
}
const SubmitButton = ({disabled, onClick, loading, children}: SubmitButtonProps) => {
  return (
      <button type="button"
              className="text-white bg-secondary-bg-400 hover:bg-secondary-bg-500 font-medium rounded-xl text-md px-10 py-1.5 mr-2 my-2"
              onClick={onClick}
              disabled={disabled}>{loading ? (<div>Sending...</div>): children}
      </button>

  )
}

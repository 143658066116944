import React, { useState } from 'react';
import DataTable from '../Layout/DataTable';

import styled from 'styled-components';
import {  Col, Row, Tag } from 'antd';
import { cancelOrder, sendTransactionWithNotification } from '../../utils/send';
import { notify } from '../../utils/notifications';
import { DeleteOutlined } from '@ant-design/icons';
import { OrderWithMarketAndMarketName } from '../../utils/types';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import {
  // @ts-ignore
  createColumnHelper, getCoreRowModel,
  flexRender,
  useReactTable,
} from '@tanstack/react-table';
import BN from 'bn.js';
import { PublicKey, Transaction } from '@solana/web3.js';
import { Market } from '@project-serum/serum';
import { Button, LinkButton } from '../Button';
import no_order from '../../assets/no_order.svg';
import { WRAPPED_SOL_MINT } from '@project-serum/serum/lib/token-instructions';
import { Pagination } from 'flowbite-react';

const CancelButton = styled(Button)`
  color: #f23b69;
  border: 1px solid #f23b69;
`;


type Order = {
  orderId: BN;
  openOrdersAddress: PublicKey;
  openOrdersSlot: number;
  price: number;
  priceLots: BN;
  size: number;
  feeTier: number;
  sizeLots: BN;
  side: 'buy' | 'sell';
  market?: Market;
  marketName: string ;
  clientId?: BN;
}




//
// const defaultData: Order[] = [
//   {
//     orderId: new BN("000"),
//     openOrdersAddress: PublicKey.default,
//     openOrdersSlot: 100,
//     price: 20.3,
//     priceLots: new BN("23"),
//     size: 222,
//     feeTier: 20,
//     sizeLots: new BN("34"),
//     side: 'buy',
//     marketName: "NINJA/USDC"
//   },
// ]
export default function OpenOrderTable({
  openOrders,
  onCancelSuccess,
  pageSize,
  loading,
  marketFilter,
}: {
  openOrders: OrderWithMarketAndMarketName[] | null | undefined;
  onCancelSuccess?: () => void;
  pageSize?: number;
  loading?: boolean;
  marketFilter?: boolean;
}) {
  let { wallet, sendTransaction } = useWallet();
  let { connection } = useConnection();
  const [data, setData] = React.useState(() => [...(openOrders || [])])


  const [cancelId, setCancelId] = useState(null);

  async function cancel(order) {
    setCancelId(order?.orderId);
    try {
      if (!wallet) {
        return null;
      }

      await cancelOrder({
        order,
        market: order.market,
        connection,
        wallet: wallet.adapter,
        sendTransaction
      });
    } catch (e) {
      notify({
        message: 'Error cancelling order',
        // @ts-ignore
        description: (e as any).message,
        type: 'error',
      });
      return;
    } finally {
      setCancelId(null);
    }
    onCancelSuccess && onCancelSuccess();
  }
  const columnHelper = createColumnHelper<Order>()

  const columns = [
    columnHelper.accessor('marketName', {
      cell: info => info.getValue(),
      footer: info => info.column.id,
      header: "Market"
    }),columnHelper.accessor('side', {
      cell: info => <span className={"capitalize"}>{info.getValue()}</span>,
      footer: info => info.column.id,
      header: "Side"
    }),
    ,columnHelper.accessor('size', {
      cell: info => info.getValue().toString(),
      footer: info => info.column.id,
      header: "Size"
    }),
    columnHelper.accessor('price', {
      cell: info => <i>{info.getValue()}</i>,
      header: () => <span>Price</span>,
      footer: info => info.column.id,
    }),
    columnHelper.accessor('orderId', {
      header: () => '',
      cell: info => <Button primary={true} onClick={() => cancel(info.getValue())} >Cancel</Button>,
      footer: info => info.column.id,
    }),
  ]
//@ts-ignore
  const table = useReactTable({     data , columns,
    getCoreRowModel: getCoreRowModel(), })
  const marketFilters = [
    ...new Set((openOrders || []).map((orderInfos) => orderInfos.marketName)),
  ].map((marketName) => {
    return { text: marketName, value: marketName };
  });

  // const columns = [
  //   {
  //     title: 'Market',
  //     dataIndex: 'marketName',
  //     key: 'marketName',
  //     filters: marketFilter ? marketFilters : undefined,
  //     onFilter: (value, record) => record.marketName.indexOf(value) === 0,
  //   },
  //   {
  //     title: 'Side',
  //     dataIndex: 'side',
  //     key: 'side',
  //     render: (side) => (
  //       <Tag
  //         color={side === 'buy' ? '#41C77A' : '#F23B69'}
  //         style={{ fontWeight: 700 }}
  //       >
  //         {side.charAt(0).toUpperCase() + side.slice(1)}
  //       </Tag>
  //     ),
  //     sorter: (a, b) => {
  //       if (a.side === b.side) {
  //         return 0;
  //       } else if (a.side === 'buy') {
  //         return 1;
  //       } else {
  //         return -1;
  //       }
  //     },
  //     showSorterTooltip: false,
  //   },
  //   {
  //     title: 'Size',
  //     dataIndex: 'size',
  //     key: 'size',
  //     sorter: (a, b) => b.size - a.size,
  //     showSorterTooltip: false,
  //   },
  //   {
  //     title: 'Price',
  //     dataIndex: 'price',
  //     key: 'price',
  //     sorter: (a, b) => b.price - a.price,
  //     showSorterTooltip: false,
  //   },
  //   {
  //     key: 'orderId',
  //     render: (order) => (
  //       <div style={{ textAlign: 'right' }}>
  //         <CancelButton
  //           icon={<DeleteOutlined />}
  //           onClick={() => cancel(order)}
  //           loading={cancelId + '' === order?.orderId + ''}
  //         >
  //           Cancel
  //         </CancelButton>
  //       </div>
  //     ),
  //   },
  // ];
  const dataSource = (openOrders || []).map((order) => ({
    ...order,
    key: order.orderId,
  }));
  const [page, setPage] = useState(0);
  const onPageChange = (page) => {
    setPage(page - 1);
  };
  return (
    <div className={"overflow-x-auto"}>
      {
        !openOrders  || openOrders.length <= 0 ? (
            <div
              style={{
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={no_order} alt="no order" />
              <div className={"text-xs font-bold "}>You have no open orders</div>
            </div>
          ) :
          (
            <table
              className={' w-full  px-6 py-4 text-left text-sm  bg-secondary-bg'}>
              <thead
                className={'flex justify-around items-center text-xs font-bold uppercase  text-gray-300  rounded-tl-xl py-5 px-5 h-12 border-none w-auto'}>
              <td className={'min-w-[4rem] w-[6rem] max-w-[20%]'}>
                Market
              </td>
              <td className={'min-w-[4rem] w-[6rem]  max-w-[20%]'}>
                Side
              </td>
              <td className={'min-w-[4rem] w-[6rem]  max-w-[20%]'}>
                Size
              </td>
              <td className={'min-w-[4rem] w-[6rem]  max-w-[20%] '}>
                Price
              </td>
              <td className={'min-w-[4rem] w-[6rem]  max-w-[20%]'}>
                Actions
              </td>

              </thead>
                <tbody className='bg-primary-bg-500 max-w-full'>
                {openOrders.slice(10 * page, 10 * page + 10).map((order, idx) => {
                  return (<tr key={idx}
                              className={`px-5 py-2 text-sm text-white font-bold flex justify-around items-center shadow-lineShadowBottom`}>
                    <td
                      className='min-w-[4rem] w-[6rem]  max-w-[20%]  truncate flex items-center'>
                      {order.marketName}
                    </td>
                    <td className='min-w-[4rem] w-[6rem]  max-w-[20%] truncate cursor-pointer'
                       >
                      <Tag
                               color={order.side === 'buy' ? '#41C77A' : '#F23B69'}
                               style={{ fontWeight: 700 }}
                           >
                               {order.side.charAt(0).toUpperCase() + order.side.slice(1)}
                            </Tag>
                    </td>
                    <td className={'min-w-[4rem] w-[6rem]  max-w-[20%] truncate'}>
                      {order.size}
                    </td>
                    <td className={'min-w-[4rem] w-[6rem]  max-w-[20%] truncate'}>
                      {order.price}
                    </td>
                    <td className={'min-w-[4rem] w-[6rem]  max-w-[20%] truncate'}>

                      <CancelButton
                                 icon={<DeleteOutlined />}
                                 onClick={() => cancel(order)}
                                 loading={cancelId + '' === order?.orderId + ''}
                               >
                                 Cancel
                               </CancelButton>
                    </td>
                  </tr>);
                })}

                </tbody>

            </table>

          )
      }
      {openOrders && openOrders.length > 10 && (
        <Pagination
          currentPage={page + 1}
          onPageChange={onPageChange}
          showIcons={true}
          totalPages={Math.ceil(openOrders.length / 10)}
        />
      )}

    </div>
  )
}

import React from 'react';
import { Dropdown, Menu, Button as AntdButton } from 'antd';
import styled from 'styled-components';
import LinkAddress from '../LinkAddress';
import wallet_svg from '../assets/wallet.svg';
import arrow_bottom from '../assets/arrow_bottom.svg';
import { Button, Container } from './subcomponents';
import { useWallet } from '@solana/wallet-adapter-react';
import {
  WalletMultiButton,
  useWalletModal,
  WalletConnectButton,
  WalletModalButton,
} from '@solana/wallet-adapter-react-ui';

const ConnectBtn = styled.button`
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 26px;
  color: #353352;
  padding: 8px 20px;
  text-transform: uppercase;
  background: #ffffff;
  border: none;
  border-radius: 50px;

  @media only screen and (max-width: 786px) {
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 16px;
    padding: 6px 16px;
  }
`;

export default function WalletConnect() {
  const { connected, wallet, select, connect, disconnect, publicKey } = useWallet();
  const { setVisible } = useWalletModal();

  return (
    <Container>
      <ConnectBtn onClick={() => connected ? disconnect() : setVisible(true)}>
        <span>{connected ? 'Disconnect' : 'Wallet'}</span>
      </ConnectBtn>
    </Container>
  );
}

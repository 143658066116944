import React, { ReactNode, useEffect, useState } from 'react';
import { Button, LinkButton, ToggleButton } from '../components/Button';
import {
  useAllOpenOrdersBalances,
  useExchangeRatesForWalletTokens,
  useSelectedTokenAccounts,
  useTokenAccounts,
  useWalletBalancesForAllMarkets,
} from '../utils/markets';
import {
  TOKEN_PROGRAM_ID,
  useMintToTickers,
  useWalletTokens,
} from '../utils/tokens';
import { Breadcrumb, Pagination, Spinner } from 'flowbite-react';
import { HomeIcon, RefreshIcon } from '@heroicons/react/solid';
import { WRAPPED_SOL_MINT } from '@project-serum/serum/lib/token-instructions';
import Modal from '../components/Modal';
import { WalletMetadataToken, WalletToken } from '../utils/types';
import { SuffixInput } from '../components/Input';
//@ts-ignore
import {  ASSOCIATED_TOKEN_PROGRAM_ID,  AuthorityType,  createAssociatedTokenAccountInstruction,  createBurnInstruction,  createCloseAccountInstruction,  createInitializeMintInstruction,  createMintToInstruction,  createSetAuthorityInstruction,  createTransferInstruction,  getAssociatedTokenAddress,  getMinimumBalanceForRentExemptAccount,  getMinimumBalanceForRentExemptMint,  MINT_SIZE,
} from '@solana/spl-token';
import {
  Keypair,
  PublicKey,
  SystemProgram,
  Transaction,
  TransactionSignature,
} from '@solana/web3.js';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { sendTransactionWithNotification } from '../utils/send';
import { abbreviateAddress, isValidUrl } from '../utils/utils';
import { notify } from '../utils/notifications';
import { chunk, findMetadataPda } from '@metaplex-foundation/js';
import {
  createCreateMetadataAccountV2Instruction,
  createUpdateMetadataAccountV2Instruction,
  DataV2,
} from '@metaplex-foundation/mpl-token-metadata';
import {
  ARWEAVE_UPLOAD_URL,
  StorageProvider,
} from '@strata-foundation/spl-utils';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  getArweaveUrl,
  presignCreateArweaveUrl,
  uploadMetadata,
} from '../utils/arweave';
import { useWalletMetadataTokens } from '../utils/metaplex';
import StandaloneTokenAccountsSelect
  from '../components/StandaloneTokenAccountSelect';
import Decimal from 'decimal.js';

function ToolsPage() {
  const [selectedTool, setSelectedTool] = useState('');

  let content;
  const toolWrapper = ({
                         toolName,
                         children,
                       }: { toolName: string, children: ReactNode | string }) => {
    return (
      <div className={'m-20 max-w-[100%] '}>
        <div className={'flex items-start'}>
          <Breadcrumb aria-label='Default breadcrumb '>
            <Breadcrumb.Item
              href='#'
            >
              <>
                <HomeIcon className={'text-text-primary-500 w-4 h-4 mr-2'} />
                <span className={'text-text-primary-500'}>
            Ninja Dex
          </span>
              </>
            </Breadcrumb.Item>
            <Breadcrumb.Item href='#/tools' onClick={() => setSelectedTool('')}
            >
          <span className={'text-text-primary-500'}>
            Tools
          </span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
          <span className={'text-text-primary-300'}>
                      {toolName}

          </span>
            </Breadcrumb.Item>
          </Breadcrumb>

        </div>
        <div
          className={'flex justify-center items-start  text-white  bg-primary-bg-500 font-bold h-[100%] px-10 py-2 mt-5 rounded-xl shadow-lineShadow'}>
          {children}
        </div>
      </div>);
  };
  if (selectedTool == 'close-token') {
    content = toolWrapper({
      toolName: 'Token Manager',
      children: <CloseBurnTokenAccounts />,
    });

  }
  if (selectedTool == 'close-serum') {
    content = toolWrapper({
      toolName: 'Serum',
      children: <SerumAccountManager />,
    });
  }
  if (selectedTool == 'update-token') {
    content = toolWrapper({
      toolName: 'Update Metadata',
      children: <UpdateMetadata />,
    });
  }
  if (selectedTool == 'create-candy') {
    content = toolWrapper({ toolName: 'Serum', children: <WIPTool /> });
  }
  if (selectedTool == 'token-distributor') {
    content = toolWrapper({
      toolName: 'Serum',
      children: <TokenDistributor />,
    });
  }
  if (selectedTool == 'mint-token') {
    content = toolWrapper({ toolName: 'Mint token', children: <MintToken /> });
  }
  return (
    <>
      {content ? content :
        <div className={'m-20 mt-5 max-w-[100%]'}>
          <Breadcrumb aria-label='Default breadcrumb '>
            <Breadcrumb.Item
              href='#'
            >
              <>
                <HomeIcon className={'text-text-primary-500 w-4 h-4 mr-2'} />
                <span className={'text-text-primary-500'}>
            Ninja Dex
          </span>
              </>
            </Breadcrumb.Item>
            <Breadcrumb.Item href='#/tools' onClick={() => setSelectedTool('')}
            >
          <span className={'text-text-primary-300'}>
            Tools
          </span>
            </Breadcrumb.Item>
          </Breadcrumb>
          <section
            className={'px-5 grid place-items-center grid-rows-[20rem_20rem] grid-cols-[repeat(auto-fill,_20rem)] gap-x-10 h-full'}>

            <ToolCard onClick={() => {
              setSelectedTool('close-token');
            }} title={'Token Manager'}
                      description={'Close empty token accounts or burn and close token accounts to make some $SOL'} />
            <ToolCard onClick={() => {
              setSelectedTool('mint-token');
            }} title={'Mint a token'} description={'Mint Solana tokens'} />
            <ToolCard onClick={() => {
              setSelectedTool('update-token');
            }} title={'Update NFT Metadata'}
                      description={'Update metadata of NFT\'s you own update authority of'} />
            <ToolCard onClick={() => {
              setSelectedTool('close-serum');
            }} title={'Close serum DEX accounts'}
                      description={'close your Serum DEX accounts.'} />
            <ToolCard onClick={() => {
              setSelectedTool('token-distributor');
            }} title={'Token Distributor'}
                      description={'send spl tokens to multiple wallets'} />
            {/*<ToolCard onClick={() => {*/}
            {/*  setSelectedTool('create-candy');*/}
            {/*}} title={'Create NFT Collection'}*/}
            {/*          description={'Create a Metaplex Candymachine v2 Collection'} />*/}
          </section>
        </div>


      }
    </>


  );
}

interface ToolCardProps {
  className?: string,
  onClick: (e) => void,
  title: string,
  description: string
}

const ToolCard = ({
                    className,
                    onClick,
                    title,
                    description,
                  }: ToolCardProps) => {
  return (
    <div
      className='p-6 bg-primary-bg rounded-lg shadow-lineShadow w-80 h-60 flex flex-col justify-start items-start relative '>

      <h5
        className='mb-2 text-2xl font-bold tracking-tight text-white'>{title}</h5>
      <p className='mb-3 font-normal text-gray-300'>{description}</p>
      <Button onClick={onClick} primary={true}
              className='text-white flex items-center self-end mt-max absolute bottom-2'
      >
        See more
        <svg aria-hidden='true' className='ml-2 -mr-1 w-4 h-4'
             fill='currentColor' viewBox='0 0 20 20'
             xmlns='http://www.w3.org/2000/svg'>
          <path fill-rule='evenodd'
                d='M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z'
                clip-rule='evenodd' />
        </svg>
      </Button>
    </div>
  );
};

const WIPTool = () => {
  return (
    <h1 className={'text-white text-2xl'}>
      WIP
    </h1>
  );
};

const TokenDistributor = () => {
  interface AccountWithInfo {
    walletAccount: PublicKey,
    associatedTokenAccount: PublicKey,
    associatedPdaExists: boolean,
    amount: number
  }

  const [showWalletMints, setShowWalletMints] = useState(false);
  const [accountRentExemptFees, setAccountRentExemptFees] = useState(0);
  const {
    connected,
    signAllTransactions,
    signTransaction,
    sendTransaction,
    publicKey,
  } = useWallet();
  const [selectedToken, setSelectedToken] = useState<WalletToken | undefined>(undefined);
  const [isConstantAmount, setIsConstantAmount] = useState(false);
  const [constantAmount, setConstantAmount] = useState(0);
  const [accounts, setAccounts] = useState<any | undefined>(undefined);
  const { tokens, walletTokensLoaded, refreshWalletTokens } = useWalletTokens();
  const [distributingTokens, setDistributingTokens] = useState(false);
  const { connection } = useConnection();
  const [showSendModal, setShowSendModal] = useState(false);
  const [creatingAssociatedAccounts, setCreatingAssociatedAccounts] = useState(false);
  const [sendingTokens, setSendingTokens] = useState(false);
  const [showCreateAccountsModal, setShowCreateAccountsModal] = useState(false);
  const [showSendTokensModal, setShowSendTokensModal] = useState(false);
  const [accountsWithInfo, setAccountsWithInfo] = useState<AccountWithInfo[] | undefined>(undefined);
  const [sendModalStatus, setSendStatus] = useState('');
  const [transactions, setTransactions] = useState<Transaction[] | undefined>();
  const [failedAccounts, setFailedAccounts] = useState<AccountWithInfo[] | undefined>(undefined);
  const [done, setDone] = useState(false);
  const [successfulTransactions, setSuccessfulTransactions] = useState<TransactionSignature[]>([]);
  useEffect(() => {
    getMinimumBalanceForRentExemptAccount(connection).then(amount => setAccountRentExemptFees(amount));
  }, [connection, walletTokensLoaded, connected, showWalletMints, showCreateAccountsModal]);
  const estimateDistributionGas = (accounts: AccountWithInfo[]) => {
    if (!accounts) return 0;
    let needsAssociated = accounts.filter(account => !account.associatedPdaExists).length;
    let accountCreationRent = needsAssociated * accountRentExemptFees;
    let txFees = ((Math.max(needsAssociated / 17, 1)) + (Math.max(accounts.length / 20, 1))) * 10000;
    return accountCreationRent + txFees;
  };


  const sendTokens = async () => {
    if (!accountsWithInfo) {
      notify({
        message: 'Choose accounts to continue',
      });
      return;
    }
    setSendingTokens(true);
    let validAccounts = accountsWithInfo.filter(account => account.associatedPdaExists);
    let transferInstructions = await Promise.all(validAccounts.map(async account => {
      return createTransferInstruction(await getAssociatedTokenAddress(selectedToken?.tokenAccount?.mint, publicKey), account.associatedTokenAccount, publicKey, account.amount * Math.pow(10, selectedToken?.mint?.decimals!));
    }));
    let transactions: Transaction[] = [];
    for (let i = 0; i < transferInstructions.length / 20; i++) {
      transactions.push(new Transaction().add(...transferInstructions.slice(i * 20, (i + 1) * 20)));
    }
    setTransactions(transactions);
    setShowSendModal(true);
    setSendStatus(`Sending ${transactions.length} ${transactions.length === 1 ? 'Transaction' : 'Transactions'}`);
    let failedIndices: number[] = await signAndSendTransactions(transactions);
    // update this later when implementing creating ATAs for wallets
    if (failedIndices.length > 0) {
      setFailedAccounts([...accountsWithInfo.filter(a => !a.associatedPdaExists), ...failedIndices.map(ind => validAccounts.slice(ind * 20, (ind + 1) * 20)).flat(1)]);
      notify({
        message: 'Some transactions failed to confirm',
      });
    } else {
      setFailedAccounts(accountsWithInfo.filter(a => !a.associatedPdaExists));
    }
    setShowSendModal(false);
    setSendingTokens(false);
    //setShowSendTokensModal(false)
    notify({
      type: 'success',
      message: 'Distributed Succesfully',
    });
    setDone(true);
  };
  const signAndSendTransactions = async (transactions: Transaction[]) => {
    let failedIndices: number[] = [];

    try {
      let latestBlockhash = await connection.getLatestBlockhash();
      transactions = transactions.map(tx => {
        tx.recentBlockhash = latestBlockhash.blockhash;
        tx.lastValidBlockHeight = latestBlockhash.lastValidBlockHeight;
        tx.feePayer = publicKey || undefined;
        return tx;
      });
      let signedTransactions = await signAllTransactions!(transactions);
      await Promise.all(signedTransactions.map(async (transaction, index) => {
        try {
          let sig = await connection.sendRawTransaction(transaction.serialize({
            requireAllSignatures: true,
            verifySignatures: true,
          }));
          let result = await connection.confirmTransaction({ signature: sig, ...latestBlockhash }, 'finalized');
          if (result.value.err) {
            failedIndices.push(index);
          } else {
            setSuccessfulTransactions((prev) => [sig, ...prev]);
          }
        } catch (e) {
          console.error(e);
          failedIndices.push(index);
        }
      }));
    }catch (e) {
      console.error(e);
      failedIndices = []
      for (let i = 0; i < transactions.length; i++)
        failedIndices.push(i)
    }

    return failedIndices;
  };
  const createAssociatedAccounts = async () => {
    if (!accountsWithInfo) {
      notify({
        message: 'Choose accounts to continue',
      });
      return;
    }
    setCreatingAssociatedAccounts(true);
    let createInstructions = accountsWithInfo.filter(account => !account.associatedPdaExists).map(account => createAssociatedTokenAccountInstruction(publicKey!, account.associatedTokenAccount, account.walletAccount, selectedToken?.tokenAccount?.mint));
    let transactions: Transaction[] = [];
    for (let i = 0; i < createInstructions.length / 17; i++) {
      transactions.push(new Transaction().add(...createInstructions.slice(i * 17, (i + 1) * 17)));
    }
    setTransactions(transactions);
    setShowSendModal(true);
    setSendStatus(`Sending ${transactions.length} ${transactions.length === 1 ? 'Transaction' : 'Transactions'}`);
    let failedIndices: number[] = await signAndSendTransactions(transactions);
    if (failedIndices.length > 0) {
        notify({
          message: 'Some transactions failed to confirm',
        });

    }
    let updatedAccountsWithInfo = accountsWithInfo.map((account, idx) => {
      if (!failedIndices.find((v) => v === Math.floor(idx/17))) {
        account.associatedPdaExists = true
      }
      return account
    })
    setAccountsWithInfo(updatedAccountsWithInfo)
    setCreatingAssociatedAccounts(false);
    setShowSendModal(false);
    setShowCreateAccountsModal(false);
    setShowSendTokensModal(true);
  };
  const distributeTokens = async () => {
    if (selectedToken && accounts && publicKey && connected) {
      let withTokenAccounts = (await Promise.all(
        chunk(accounts, 99).map(
          async accountChunk => {
            let associatedPdas = await Promise.all(accountChunk.map(async (a: any) => {
              return await getAssociatedTokenAddress(selectedToken?.tokenAccount.mint, a.walletAccount);
            }));
            let associatedPdaAccounts = await connection.getMultipleAccountsInfo(associatedPdas);
            let data: any = [];
            for (let i = 0; i < accounts.length; i++) {
              data.push({
                walletAccount: accounts.at(i).walletAccount,
                associatedTokenAccount: associatedPdas.at(i),
                associatedPdaExists: associatedPdaAccounts.at(i) !== null,
                amount: accounts.at(i).amount,
              });
            }
            return data;
          }))).flat(1);

      let estimatedFees = await estimateDistributionGas(withTokenAccounts);
      let walletSolBalance = await connection.getBalance(publicKey);
      if (estimatedFees > walletSolBalance) {
        notify({
          message: `Insufficient balance for fees, Estimated fees: ${estimatedFees / Math.pow(10, 9)} $SOL Wallet balance: ${walletSolBalance / Math.pow(10, 9)} $SOL`,
          type: 'error',
        });
        return;
      }

      let requiredTokens = withTokenAccounts.map(account => account.amount).reduce((acc, nxt) => acc + nxt);
      let walletTokens = ((await connection.getParsedTokenAccountsByOwner(publicKey!, {mint: selectedToken.tokenAccount.mint})).value.at(0)|| { account:{data: {parsed:{info: {tokenAmount: {uiAmount: 0}}} }}}).account.data.parsed.info.tokenAmount.uiAmount ;

      if (!walletTokens || walletTokens < requiredTokens) {
        notify({
          message: `Insufficient token balance, required: ${requiredTokens}  Wallet balance: ${walletTokens}`,
          type: 'error',
        });
        return;
      }
      setAccountsWithInfo(withTokenAccounts);
      if (withTokenAccounts.find(account => !account.associatedPdaExists))
        setShowCreateAccountsModal(true);
      else
        setShowSendTokensModal(true);
    }

  };
  return (
    <div className={'my-10 w-full h-full max-w-[30rem]'}>
      <h1 className={'text-2xl text-gray-200 self-center font-bold mb-4'}>Token
        Distributor</h1>
      <span className={'text-text-primary-300 text-xs'}>Token Mint</span>
      <div className={'relative w-full my-2'}>
        <input
          value={selectedToken && selectedToken?.tokenAccount?.mint?.toBase58()}
          disabled={true} type={'text'}
          className={'w-full text-sm  rounded-sm bg-transparent border-none shadow-lineShadow pr-14'} />
        <LinkButton onClick={setShowWalletMints}
                    className={'absolute right-2 z-10 text-text-primary-200 top-2'}>
          <span>Wallet</span>
        </LinkButton>
      </div>

      <div className='flex flex-col gap-4 text-white'>

        <div>
          <div>
          <span className={'text-text-primary-300 text-xs'}
          >
            Amount
          </span>
          </div>
          <div className={'flex mt-2 items-center h-[32px]'}>

            <input
              className={'rounded-sm'}
              id='amount-radio-variable'
              type='radio'
              name={'amount-radio-variable'}
              required={true}
              checked={!isConstantAmount}
              onChange={(e) => setIsConstantAmount(false)}
            />
            <div className='mx-2 block text-white'>
              <label
                htmlFor='amount-radio-variable'
                className={'text-text-primary-300 text-xs'}
              >Variable</label>
            </div>

            <input
              className={'rounded-sm'}
              id='amount-radio-constant'
              type='radio'
              name={'amount-radio-constant'}
              checked={isConstantAmount}
              onChange={(e) => setIsConstantAmount(true)}
            />
            <div className='mx-2 block text-white'>
              <label
                htmlFor='amount-radio-constant'
                className={'text-text-primary-300 text-xs'}
              >Constant</label>
            </div>
            <input
              value={constantAmount}
              disabled={!isConstantAmount} type={'number'}
              className={`w-full text-sm  rounded-sm bg-transparent border-none shadow-lineShadow pr-14 ${!isConstantAmount
                ? 'hidden'
                : 'visible'}`}

              onInput={(e) => {
                //@ts-ignore
                setConstantAmount(e.target.value);
              }} />
          </div>
        </div>
        <div id='fileUpload'>
          <div className='mb-2 block'>
            <label
              htmlFor='file'
              className={'text-text-primary-300 text-xs'}
            >Choose Accounts</label>
          </div>
          <input
            accept='.csv'
            id='file'
            type={'file'}
            name={'file'}
            onInput={(e) => {
              //@ts-ignore
              if (e.target.files && e.target.files[0]) {
                const reader = new FileReader();
                reader.addEventListener('load', (event) => {
                  console.log(event.target!.result || undefined);
                  let accountLines = event.target!.result?.toString().trim().split('\n');

                  let accounts = (accountLines || []).map(account => {
                    return {
                      walletAccount: isConstantAmount ? new PublicKey(account) : new PublicKey(account.split(',')[0]),
                      amount: isConstantAmount ? constantAmount : Number(account.split(',')[1]),
                    };
                  });
                  setAccounts(accounts);
                });
                //@ts-ignore
                reader.readAsText(e.target.files[0]);

              }
            }
            }
            className='rounded-sm w-full bg-transparent border-none shadow-lineShadow block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 text-sm'
          />
          <p
            className={'text-text-primary-300 text-xs'}>
            a csv file with
            format <i>{isConstantAmount ? 'walletAddress' : 'walletAddress, amount'}</i>
          </p>
        </div>
        <Button onClick={distributeTokens} primary={true}
                disabled={!connected || distributingTokens}
                loading={distributingTokens} className={'mt-4 py-3'}
        >
          {connected ? <span>Send</span> :
            <span>Connect Wallet</span>}
        </Button>
      </div>

      {showWalletMints && (
        <Modal
          isOpen={showWalletMints}
          onClose={() => setShowWalletMints(false)}
          className={'rounded-xl bg-[#201A34] text-white'}
        >

          {!walletTokensLoaded ? (
            <div
              className={'w-full h-[50%] min-h-[20rem] flex justify-center items-center'}>
              <Spinner size={'xl'} color={'warning'} />
            </div>
          ) : (<div>

            {(tokens || []).map((token, key) => {
              return (
                <div onClick={() => {

                  setSelectedToken(token);
                  setShowWalletMints(false);
                }}
                     className={`items-center justify-center grid grid-cols-[2em_4fr_1fr] h-16  p-2 hover:bg-primary-bg-600 cursor-pointer `}
                     key={key}>
                  <img
                    src={token?.token?.logoURI} />
                  <div className={'px-2'}>
                    <span>{token?.token?.symbol || ''} {' '}</span><span>{token?.tokenAccount?.mint?.toBase58().slice(0, 5) + '...'} </span>

                  </div>
                </div>
              );
            })}
          </div>)}

        </Modal>)
      }
      {showCreateAccountsModal && accountsWithInfo && (
        <Modal
          isOpen={showCreateAccountsModal}
          onClose={() => setShowCreateAccountsModal(false)}
          className={'rounded-xl bg-[#201A34] text-white'}
        >

          <div className={'flex flex-col items-center'}>
            <span className={'text-sm font-bold text-gray-400'}>We need to create {accountsWithInfo.filter(account => !account.associatedPdaExists).length} Token accounts</span>
            <span className={'block text-xs text-gray-300'}>
              Estimated rent fees: {accountsWithInfo.filter(account => !account.associatedPdaExists).length * accountRentExemptFees / Math.pow(10, 9)} $SOL
            </span>
            <Button onClick={createAssociatedAccounts} primary={true}
                    loading={creatingAssociatedAccounts}
                    disabled={creatingAssociatedAccounts}
                    className={'mt-4 py-3 self-center'}
            >
              <span>Continue</span>
            </Button>
          </div>

        </Modal>)
      }

      {showSendTokensModal && accountsWithInfo && (
        <Modal
          isOpen={showSendTokensModal}
          onClose={() => {
            setCreatingAssociatedAccounts(false);
            setSendingTokens(false);
            setSuccessfulTransactions([]);
            setDone(false);
            setShowSendTokensModal(false);
          }}
          className={'rounded-xl bg-[#201A34] text-white'}
        >

          <div className={'flex flex-col items-center justify-center'}>
            <span
              className={'text-sm font-bold text-gray-200'}>Distribute {accountsWithInfo.map(account => account.amount).reduce((acc, nxt) => acc + nxt)} {selectedToken?.token ? selectedToken.token.symbol : selectedToken?.tokenAccount.mint!.toBase58().slice(0, 5) + '...'} To {accountsWithInfo.length} wallets?</span>
            <div>
              {!done && (
                <Button onClick={sendTokens} primary={true}
                        loading={sendingTokens} disabled={sendingTokens}
                        className={'mt-4 py-3 self-center'}
                >
                  <span>Continue</span>
                </Button>
              )}

              {failedAccounts && failedAccounts.length > 0 && (

                <Button onClick={() => {
                  navigator.clipboard.writeText(failedAccounts?.map(account => account.walletAccount.toBase58() + ',' + account.amount).reduce((acc, nxt) => acc + '\n' + nxt));
                }} primary={true} className={'mt-4 py-3 self-center'}
                >
                  <span>Copy failed accounts</span>
                </Button>
              )}
              {successfulTransactions.length > 0 && (
                <Button onClick={() => {
                  navigator.clipboard.writeText(successfulTransactions?.reduce((acc, nxt) => acc + '\n' + nxt));
                }} primary={true} className={'mt-4 py-3 self-center'}
                >
                  <span>Copy Transactions</span>
                </Button>
              )}
            </div>
          </div>

        </Modal>)
      }
      {showSendModal && transactions && (
        <Modal
          isOpen={showSendModal}
          onClose={() => setShowSendModal(false)}
          className={'rounded-xl bg-[#201A34] text-white'}
        >

          <div
            className={'min-h-[10rem] flex flex-col items-center justify-center'}>
            <div
              className={'w-full min-h-[5rem] flex justify-center items-center'}>
              <Spinner size={'xl'} color={'warning'} />
            </div>
            <span className={'text-sm text-gray-300'}>{sendModalStatus}</span>

          </div>

        </Modal>)
      }

    </div>
  );
};

function removeNullBytes(str) {
  return str.split('').filter(char => char.codePointAt(0)).join('');
}

const SerumAccountManager = () => {
  let walletBalances = useWalletBalancesForAllMarkets();
  const [tokenAccounts, tokenAccountsConnected] = useTokenAccounts();
  const mintToTickers = useMintToTickers();
  const openOrdersBalances = useAllOpenOrdersBalances();
  const [hideZeroBalance, setHideZeroBalance] = useState(true);
  const { connected, publicKey, sendTransaction } = useWallet();
  const { connection } = useConnection();
  const [page, setPage] = useState(0);
  const [
    selectedTokenAccounts,
    setSelectedTokenAccounts,
  ] = useSelectedTokenAccounts();
  const data = (walletBalances || []).filter(balance => !hideZeroBalance || balance.balance.toLocaleString(undefined, {
    maximumFractionDigits: 10,
  }) !== '0').map((balance) => {
    const balances = {
      coin: mintToTickers[balance.mint],
      mint: balance.mint,
      walletBalance: balance.balance.toLocaleString(undefined, {
        maximumFractionDigits: 10,
      }),
      openOrdersFree: 0,
      openOrdersTotal: 0,
    };
    for (let openOrdersAccount of openOrdersBalances[balance.mint] || []) {
      balances['openOrdersFree'] += openOrdersAccount.free;
      balances['openOrdersTotal'] += openOrdersAccount.total;
    }
    return balances;
  });
  const update = () => {

  };
  const onPageChange = (page) => {
    setPage(page - 1);
  };
  return (
    <div
      className={'flex flex-col items-end justify-center min-w-[100%] text-sm mt-5 '}>
      <div className={'w-full flex items-center justify-between p-2 mb-2'}>
        <ToggleButton onChange={(e) => setHideZeroBalance(!hideZeroBalance)}
                      checked={hideZeroBalance}>Hide Zero Balance</ToggleButton>
        <RefreshIcon onClick={update}
                     className={`mb-2 w-4 h-4 cursor-pointer ${false}) ? 'animate-spin text-gray-600' : ''}`} />
      </div>

      <table
        className={' w-full  px-6 py-4 text-left text-sm rounded-xl bg-secondary-bg'}>
        <thead
          className={'flex justify-around items-center text-xs font-bold uppercase  text-gray-300  rounded-tl-xl p-5 h-12 border-none w-full'}>
        <td className={'min-w-[20%]'}>
          Coin
        </td>
        <td className={'min-w-[20%] '}>
          Wallet Balance
        </td>
        <td className={'min-w-[20%] '}>
          Open orders total balances
        </td>
        <td className={'min-w-[20%] '}>
          Unsettled balances
        </td>
        <td className={'min-w-[20%] '}>
          Selected token account
        </td>

        </thead>
        {connected ? (
          <tbody className='bg-primary-bg-500 max-w-full'>
          {data.slice(10 * page, 10 * page + 10).map((walletBalance, idx) => {
            return (<tr key={idx}
                        className='px-5 py-2 text-sm text-gray-300 flex justify-around items-center shadow-lineShadowBottom'>
              <td
                className='pr-5 min-w-[20%] max-w-[20%]  truncate flex items-center'>
                <a
                  href={`https://explorer.solana.com/address/${walletBalance.mint}`}
                  target={'_blank'}
                  rel='noopener noreferrer'
                >
                  {walletBalance.coin ||
                    abbreviateAddress(new PublicKey(walletBalance.mint))}
                </a>
              </td>
              <td
                className='pr-5 min-w-[20%] max-w-[20%] truncate cursor-pointer'
              >
                {walletBalance.walletBalance}
              </td>
              <td className={'pr-5 min-w-[20%] max-w-[20%] truncate'}>
                {walletBalance.openOrdersTotal}
              </td>
              <td className={'pr-5 min-w-[20%] max-w-[20%] truncate'}>
                {walletBalance.openOrdersFree}
              </td>
              <td className={'pr-5 min-w-[20%] max-w-[20%] truncate'}>
                <StandaloneTokenAccountsSelect
                  accounts={tokenAccounts?.filter(
                    (t) => t.effectiveMint.toBase58() === walletBalance.mint,
                  )}
                  mint={walletBalance.mint}
                />
              </td>
            </tr>);
          })}

          </tbody>
        ) : (
          <div>
            <h1
              className={'text-lg text-text-primary-300 min-h-[20rem] flex justify-center items-center'}>Connect
              Wallet</h1>
          </div>
        )}

      </table>
      <div className={'bg-primary-bg'}>

        {data.length > 10 && (
          <Pagination
            currentPage={page + 1}
            onPageChange={onPageChange}
            showIcons={true}
            totalPages={Math.ceil(data.length / 10)}
          />
        )}

      </div>

    </div>
  );
};

const UpdateMetadata = () => {
  const {
    metadataTokens,
    refreshWalletMetadataTokens,
    walletMetadataTokensLoaded,
    walletMetadataTokensLoading,
  } = useWalletMetadataTokens();
  const [authorityTokens, setAuthorityTokens] = useState<(WalletMetadataToken | undefined)[] | null>(null);
  const { connected, publicKey, sendTransaction } = useWallet();
  const [image, setImage] = useState<string | ArrayBuffer | undefined>(undefined);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [showWalletMints, setShowWalletMints] = useState(false);
  const [tokenName, setTokenName] = useState<string>('');
  const [tokenSymbol, setTokenSymbol] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [externalUrl, setExternalUrl] = useState<string>('');
  const [showUnauthorizedTokens, setShowUnauthorizedTokens] = useState(false);
  const [selectedMetadataToken, setSelectedMetadataToken] = useState<WalletMetadataToken | undefined>(undefined);

  const [updatingMetadata, setUpdatingMetadata] = useState(false);
  useEffect(() => {
    if (metadataTokens && connected && publicKey) {
      setAuthorityTokens(metadataTokens.filter(token => token && token.metadata.updateAuthority.equals(publicKey)));
    }
  }, [metadataTokens, connected]);

  useEffect(() => {
    if (selectedMetadataToken) {
      let externalUrl = selectedMetadataToken?.offChain?.external_url || selectedMetadataToken?.token?.token?.extensions?.website || undefined;
      let description = selectedMetadataToken.offChain?.description;
      setTokenName(removeNullBytes(selectedMetadataToken.metadata.data.name));
      setTokenSymbol(removeNullBytes(selectedMetadataToken.metadata.data.symbol));
      setExternalUrl(externalUrl || '');
      setDescription(description || '');
      setImage(selectedMetadataToken?.offChain?.image || selectedMetadataToken?.token?.token?.logoURI || '');
    }
  }, [selectedMetadataToken]);

  // TODO: load metadata from mint address input
  const loadMint = (mintAddress: string) => {

  };

  const updateMetadata = async () => {
    setUpdatingMetadata(true);

    try {
      let uri = '';
      notify({
        message: 'Uploading metadata to arweave',
      });
      if (file) {
        uri = await uploadMetadata({
          payer: publicKey!,
          provider: StorageProvider.Arweave,
          name: tokenName.trim().replace('\0', ''),
          symbol: tokenSymbol.trim().replace('\0', ''),
          description: description.trim().replace('\0', ''),
          image: file,
          mint: selectedMetadataToken?.metadata.mint,
          externalUrl: externalUrl.trim().replace('\0', ''),
        }, connection, sendTransaction);

      } else if (image && isValidUrl(image.toString())) {
        let { txid, files } = await presignCreateArweaveUrl({
          name: tokenName.trim().replace('\0', ''),
          symbol: tokenSymbol.trim().replace('\0', ''),
          description: description.trim().replace('\0', ''),
          image: image.toString(),
          payer: publicKey!,
          externalUrl: externalUrl.trim().replace('\0', ''),

        }, connection, sendTransaction);

        uri = await getArweaveUrl({
          txid,
          mint: selectedMetadataToken?.metadata.mint!,
          files,
          uploadUrl: ARWEAVE_UPLOAD_URL,
          env: 'mainnet-beta',
        });
      } else {
        notify({
          message: 'Something went wrong, please notify us on discord if the issue persists',
          type: 'error',
        });
      }
      const tokenMetadata = {
        name: tokenName,
        symbol: tokenSymbol,
        uri: uri,
        sellerFeeBasisPoints: 0,
        creators: null,
        collection: null,
        uses: null,
      } as DataV2;
      const updateMetadataTransaction = new Transaction().add(
        createUpdateMetadataAccountV2Instruction(
          {
            metadata: selectedMetadataToken?.metadataAddress!,
            updateAuthority: publicKey!,
          }, {
            updateMetadataAccountArgsV2: {
              data: tokenMetadata,
              updateAuthority: publicKey!,
              isMutable: true,
              primarySaleHappened: true,
            },
          },
        ),
      );
      sendTransactionWithNotification({
        sendTransaction: sendTransaction,
        transaction: updateMetadataTransaction,
        connection,
        message: `Updating token [${tokenSymbol}]`,
        signers: [],
      }).finally(() => setUpdatingMetadata(false),
      );
    } catch (e) {
      setUpdatingMetadata(false);
    }
  };
  const { connection } = useConnection();

  return (
    <div className={'my-10 w-full h-full max-w-[30rem]'}>
      <h1 className={'text-white text-2xl font-bold mb-4'}>Update Token
        Metadata</h1>

      {walletMetadataTokensLoaded || true ? (
        <div>
          <span className={'text-text-primary-300 text-xs'}>Mint Address</span>
          <div className={'relative w-full my-2'}>
            <input
              value={selectedMetadataToken && selectedMetadataToken.metadata.mint.toBase58()}
              disabled={true} type={'text'}
              className={'w-full text-sm  rounded-sm bg-transparent border-none shadow-lineShadow pr-14'} />
            <LinkButton onClick={setShowWalletMints}
                        className={'absolute right-2 z-10 text-text-primary-200 top-2'}>
              <span>Wallet</span>
            </LinkButton>
          </div>
          <form className='flex flex-col gap-4 text-white'>


            <div>
              <div className='mb-2 block text-white'>
                <label
                  htmlFor='token-name'
                  className={'text-text-primary-300 text-xs'}
                >Name</label>
              </div>
              <input
                className={'rounded-sm w-full bg-transparent border-none text-sm shadow-lineShadow'}
                id='token-name'
                type='text'
                name={'tokenName'}
                required={true}
                value={tokenName}
                //@ts-ignore
                onInput={(e) => setTokenName(e.target.value)}
              />

            </div>
            <div>
              <div className='mb-2 block'>
                <label
                  htmlFor='token-symbol'
                  className={'text-text-primary-300 text-xs'}
                >Symbol</label>
              </div>
              <input
                className={'rounded-sm w-full bg-transparent border-none text-sm shadow-lineShadow'}
                id='token-symbol'
                type='text'
                name={'symbol'}
                required={true}
                value={tokenSymbol}
                //@ts-ignore
                onInput={(e) => setTokenSymbol(e.target.value)}
              />

            </div>

            <div id='textarea'>
              <div className='mb-2 block'>
                <label
                  htmlFor='token-description'
                  className={'text-text-primary-300 text-xs'}
                >Description</label>
              </div>
              <textarea
                className={'rounded-sm w-full text-sm bg-transparent border-none shadow-lineShadow'}
                id='token-description'
                required={true}
                rows={4}
                name={'description'}
                value={description}
                //@ts-ignore
                onInput={(e) => setDescription(e.target.value)}
              />
            </div>

            <div>
              <div className='mb-2 block'>
                <label
                  htmlFor='token-url'
                  className={'text-text-primary-300 text-xs'}
                >Website</label>
              </div>
              <input
                className={'rounded-sm w-full text-sm bg-transparent border-none shadow-lineShadow'}
                id='token-url'
                type='text'
                name={'externalUrl'}
                required={true}
                value={externalUrl}
                //@ts-ignore
                onInput={(e) => setExternalUrl(e.target.value)}
              />

            </div>
            <div id='fileUpload'>
              <div className='mb-2 block'>
                <label
                  htmlFor='file'
                  className={'text-text-primary-300 text-xs'}
                >Upload Image</label>
              </div>
              {image && (
                <img className={'w-10 h-10 mb-2 ml-2'} src={image.toString()} />
              )}
              <input
                accept='.jpg, .jpeg, .png'
                id='file'
                type={'file'}
                name={'file'}
                onInput={(e) => {
                  //@ts-ignore
                  if (e.target.files && e.target.files[0]) {
                    //@ts-ignore
                    setFile(e.target.files[0]);
                    const reader = new FileReader();
                    reader.addEventListener('load', (event) => {
                      setImage(event.target!.result || undefined);
                    });
                    //@ts-ignore
                    reader.readAsDataURL(e.target.files[0]);
                  }
                }}
                className='rounded-sm w-full bg-transparent border-none shadow-lineShadow block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 text-sm'
              />
              <p
                className={'text-text-primary-300 text-xs'}>
                The image that will be displayed as the token's logo
              </p>
            </div>

            <Button onClick={updateMetadata} primary={true}
                    disabled={!connected || updatingMetadata || !(publicKey !== null && selectedMetadataToken && selectedMetadataToken.metadata.updateAuthority.equals(publicKey!))}
                    loading={updatingMetadata} className={'mt-4 py-3'}
                    props={{ type: 'submit' }}>
              {connected ? (selectedMetadataToken && selectedMetadataToken.metadata.updateAuthority.equals(publicKey!)) ?
                  <span>Update token</span> : !selectedMetadataToken ?
                    <span>Select token mint</span> :
                    <span>You don't have update authority </span> :
                <span>Connect Wallet</span>}
            </Button>
          </form>

        </div>
      ) : (
        <div className={'w-full h-[50%] flex justify-center items-center'}>
          <Spinner size={'xl'} color={'warning'} />
        </div>
      )}
      {showWalletMints && (
        <Modal
          isOpen={showWalletMints}
          onClose={() => setShowWalletMints(false)}
          className={'rounded-xl bg-[#201A34] text-white'}
        >
          <ToggleButton classNames={'mb-4'}
                        onChange={() => setShowUnauthorizedTokens(!showUnauthorizedTokens)}
                        checked={!showUnauthorizedTokens}>Only show tokens
            with update authority</ToggleButton>
          {walletMetadataTokensLoading || !walletMetadataTokensLoaded ? (
            <div
              className={'w-full h-[50%] min-h-[20rem] flex justify-center items-center'}>
              <Spinner size={'xl'} color={'warning'} />
            </div>
          ) : (<div>

            {((showUnauthorizedTokens ? metadataTokens : authorityTokens) || []).map((token, key) => {
              let externalUrl = token?.offChain?.external_url || token?.token?.token?.extensions?.website || undefined;
              return (
                <div onClick={() => {

                  setSelectedMetadataToken(token);
                  setShowWalletMints(false);
                }}
                     className={`items-center justify-center grid grid-cols-[2em_4fr_1fr] h-16  p-2 hover:bg-primary-bg-600 cursor-pointer `}
                     key={key}>
                  <img
                    src={token?.offChain?.image || token?.token?.token?.logoURI} />
                  <div className={'px-2'}>
                    <span>{token?.metadata.data.name} | {token?.metadata.data.symbol || ''}</span>
                    {externalUrl && (
                      <p className={'m-0'}><a target={'_blank'}
                                              href={externalUrl}>{externalUrl}</a>
                      </p>)
                    }
                  </div>
                </div>
              );
            })}
          </div>)}

        </Modal>)
      }
    </div>

  );
};

const MintToken = () => {
  const schema = Yup.object({
    tokenName: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    symbol: Yup.string().required('Required'),
    externalUrl: Yup.string().optional(),
    amount: Yup.number().optional(),
    decimals: Yup.number().required('Required'),
    file: Yup.mixed().test('fileSelected', 'Select a file', (value) => {
      return (value && value.length);
    }),
  });
  const { connection } = useConnection();
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    values,
    errors,
  } = useFormik({
    initialValues: {
      amount: 0,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      setCreatingToken(true);
      createToken(values);
    },
  });
  const { publicKey, sendTransaction, connected } = useWallet();
  const [keepMint, setKeepMint] = useState(true);
  const [keepFreeze, setKeepFreeze] = useState(true);
  const [image, setImage] = useState<string | ArrayBuffer | undefined>(undefined);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [creatingToken, setCreatingToken] = useState(false);

  const createToken = async (form) => {
    try {
      const lamports = await getMinimumBalanceForRentExemptMint(connection);
      const mintKeypair = Keypair.generate();
      const metadataPDA = await findMetadataPda(mintKeypair.publicKey);
      const tokenATA = await getAssociatedTokenAddress(mintKeypair.publicKey, publicKey);
      notify({
        message: 'Uploading metadata to arweave',
      });
      const uri = await uploadMetadata({
        payer: publicKey!,
        provider: StorageProvider.Arweave,
        name: form.tokenName,
        symbol: form.symbol,
        description: form.description,
        image: file,
        mint: mintKeypair.publicKey,
        externalUrl: form.externalUrl,
      }, connection, sendTransaction);


      const tokenMetadata = {
        name: form.tokenName,
        symbol: form.symbol,
        uri: uri,
        sellerFeeBasisPoints: 0,
        creators: null,
        collection: null,
        uses: null,
      } as DataV2;

      const createNewTokenTransaction = new Transaction().add(
        SystemProgram.createAccount({
          fromPubkey: publicKey!,
          newAccountPubkey: mintKeypair.publicKey,
          space: MINT_SIZE,
          lamports: lamports,
          programId: TOKEN_PROGRAM_ID,
        }),
        createInitializeMintInstruction(
          mintKeypair.publicKey,
          form.decimals,
          publicKey,
          publicKey,
          TOKEN_PROGRAM_ID),
        createAssociatedTokenAccountInstruction(
          publicKey,
          tokenATA,
          publicKey,
          mintKeypair.publicKey,
        ),
        createMintToInstruction(
          mintKeypair.publicKey,
          tokenATA,
          publicKey,
          form.amount * Math.pow(10, form.decimals),
        ),
        createCreateMetadataAccountV2Instruction({
            metadata: metadataPDA,
            mint: mintKeypair.publicKey,
            mintAuthority: publicKey!,
            payer: publicKey!,
            updateAuthority: publicKey!,
          },
          {
            createMetadataAccountArgsV2:
              {
                data: tokenMetadata,
                isMutable: true,
              },
          },
        ),
      );
      if (!keepMint) {
        createNewTokenTransaction.add(
          createSetAuthorityInstruction(
            mintKeypair.publicKey,
            publicKey!,
            0,
            null,
          ),
        );
      }
      if (!keepFreeze) {
        createNewTokenTransaction.add(
          createSetAuthorityInstruction(
            mintKeypair.publicKey,
            publicKey!,
            1,
            null,
          ),
        );
      }
      sendTransactionWithNotification({
        sendTransaction: sendTransaction,
        transaction: createNewTokenTransaction,
        connection,
        message: `Creating token [${form.symbol}]`,
        signers: [mintKeypair],
      }).then(() => {
        notify({
          message: `Minted ${form.amount} ${form.symbol}`,
          address: mintKeypair.publicKey.toBase58(),
          type: 'success',
        });
      }).finally(() => setCreatingToken(false),
      );
    } catch (e) {
      setCreatingToken(false);
    }

  };
  return (
    <form className='flex flex-col gap-4 text-white mt-10'
          onSubmit={handleSubmit}>
      <h1 className={'text-white text-2xl font-bold'}>Create A New Token</h1>

      <div>
        <div className='mb-2 block text-white flex'>
          <label
            htmlFor='token-name'
          >Name </label><span className={'w-2 h-2 text-red-400 pl-1'}> *</span>
        </div>
        <input
          className={'rounded-sm w-full bg-transparent border-none text-sm shadow-lineShadow'}
          id='token-name'
          type='text'
          name={'tokenName'}
          required={true}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {
          //@ts-ignore
          touched.tokenName && errors.tokenName
            ? <div>{
              //@ts-ignore
              errors.tokenName
            }</div>
            : null}
      </div>
      <div>
        <div className='mb-2 block'>
          <label
            htmlFor='token-symbol'
          >Symbol</label><span className={'w-2 h-2 text-red-400 pl-1'}> *</span>
        </div>
        <input
          className={'rounded-sm w-full bg-transparent border-none text-sm shadow-lineShadow'}
          id='token-symbol'
          type='text'
          name={'symbol'}
          required={true}
          //@ts-ignore
          onChange={handleChange}
          onBlur={handleBlur}

        />
        {
          //@ts-ignore
          touched.symbol && errors.symbol
            ? <div>{
              //@ts-ignore
              errors.symbol
            }</div>
            : null}
      </div>
      <div>
        <div className='mb-2 block'>
          <label
            htmlFor='token-decimals'
          >Decimals</label><span
          className={'w-2 h-2 text-red-400 pl-1'}> *</span>
        </div>
        <input
          className={'rounded-sm w-full bg-transparent border-none text-sm shadow-lineShadow'}
          id='token-decimals'
          type='number'
          name={'decimals'}
          min={1}
          max={12}
          required={true}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {
          //@ts-ignore
          touched.decimals && errors.decimals
            ? <div>{
              //@ts-ignore
              errors.decimals
            }</div>
            : null}
      </div>
      <div>
        <div className='mb-2 block'>
          <label
            htmlFor='token-supply'
          >Supply</label>
        </div>
        <input
          className={'rounded-sm w-full bg-transparent border-none text-sm shadow-lineShadow'}
          id='token-supply'
          name={'amount'}
          type='number'
          required={true}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {
          //@ts-ignore
          touched.amount && errors.amount
            ? <div>{
              //@ts-ignore
              errors.amount
            }</div>
            : null}
      </div>
      <div id='textarea'>
        <div className='mb-2 block'>
          <label
            htmlFor='token-description'
          >Description</label><span
          className={'w-2 h-2 text-red-400 pl-1'}> *</span>
        </div>
        <textarea
          className={'rounded-sm w-full bg-transparent border-none text-sm shadow-lineShadow'}
          id='token-description'
          required={true}
          rows={4}
          name={'description'}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {
          //@ts-ignore
          touched.description && errors.description
            ? <div>{
              //@ts-ignore
              errors.description
            }</div>
            : null}
      </div>
      <div>
        <div className='mb-2 block'>
          <label
            htmlFor='token-website'
          >Website</label>
        </div>
        <input
          className={'rounded-sm w-full bg-transparent border-none text-sm shadow-lineShadow'}
          id='token-website'
          type='text'
          name={'externalUrl'}
          required={true}
          //@ts-ignore
          onChange={handleChange}
          onBlur={handleBlur}

        />
        {
          //@ts-ignore
          touched.externalUrl && errors.externalUrl
            ? <div>{
              //@ts-ignore
              errors.externalUrl
            }</div>
            : null}
      </div>
      <div id='fileUpload'>
        <div className='mb-2 block'>
          <label
            htmlFor='file'
          >Upload Image</label><span
          className={'w-2 h-2 text-red-400 pl-1'}> *</span>
        </div>
        {image && (
          <img className={'w-10 h-10 mb-2 ml-2'} src={image.toString()} />
        )}
        <input
          accept='.jpg, .jpeg, .png'
          id='file'
          type={'file'}
          onChange={(e) => {
            handleChange(e);
          }}
          onBlur={handleBlur}
          name={'file'}
          onInput={(e) => {
            //@ts-ignore
            if (e.target.files && e.target.files[0]) {
              //@ts-ignore
              setFile(e.target.files[0]);
              const reader = new FileReader();
              reader.addEventListener('load', (event) => {
                setImage(event.target!.result || undefined);
              });
              //@ts-ignore
              reader.readAsDataURL(e.target.files[0]);
            }
          }}
          className='rounded-lg block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 text-sm'
        />
        {
          //@ts-ignore
          touched.file && errors.file
            ? <div className={'text-red-300'}>{
              //@ts-ignore
              errors.file
            }</div>
            : null}
        <p>
          The image that will be displayed as the token's logo
        </p>
      </div>
      <div className='flex items-center gap-2'>
        <input type={'checkbox'} id='keep-mint-authority' checked={keepMint}
               className={'accent-secondary-bg-500 outline-none focus:outline-none'}
               onInput={() => setKeepMint(!keepMint)} />
        <label htmlFor='keep-mint-authority'>
          Keep mint authority
        </label>
      </div>
      <div className='flex items-center gap-2'>
        <input type={'checkbox'} id='keep-freeze-authority' checked={keepFreeze}
               className={'accent-secondary-bg'}
               onInput={() => setKeepFreeze(!keepFreeze)} />
        <label htmlFor='keep-freeze-authority'>
          Keep freeze authority
        </label>
      </div>
      <Button primary={true} disabled={creatingToken || !connected}
              loading={creatingToken} className={'mt-2 py-3'}
              props={{ type: 'submit' }}>
        {connected ? <span>Create token</span> : <span>Connect Wallet</span>}
      </Button>
    </form>
  );
};
const CloseBurnTokenAccounts = () => {
  const walletBalances = useWalletBalancesForAllMarkets();
  const { tokens, refreshWalletTokens, walletTokensLoaded } = useWalletTokens();
  const { connected, publicKey, sendTransaction } = useWallet();
  const { connection } = useConnection();
  const mintToTickers = useMintToTickers();
  const {
    rates,
    refreshWalletTokenRates,
    walletTokenRatesLoaded,
  } = useExchangeRatesForWalletTokens();
  const [page, setPage] = useState(0);
  const [showBurnModal, setShowBurnModal] = useState(false);
  const [burnToken, setBurnToken] = useState<{ tokenInfo: WalletToken | undefined, coin: string, mint: string, walletBalance: number, walletBalanceUi: string, value: string } | undefined>(undefined);
  const [burning, setBurning] = useState(false);
  const [burnAmount, setBurnAmount] = useState(0);

  const update = () => {
    refreshWalletTokenRates();
    refreshWalletTokens();
  };
  useEffect(update, [page]);
  const balances = walletBalances.sort((a, b) => a.balance > b.balance ? -1 : 1).map((balance) => {
    let rate = (rates || []).find(rate => rate!.id === balance.mint);
    const data = {
      tokenInfo: tokens ? tokens.find(token => token && token.tokenAccount && token.tokenAccount.mint.toBase58() === balance.mint) : undefined,
      coin: mintToTickers[balance.mint],
      mint: balance.mint,
      walletBalanceUi: balance.balance.toLocaleString(undefined, {
        maximumFractionDigits: 10,
      }),
      walletBalance: balance.balance,
      value: rate ? (rate.price * balance.balance).toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }) : '-',
    };
    return data;
  });
  const onPageChange = (page) => {
    setPage(page - 1);
  };
  return (
    <div
      className={'flex flex-col items-end justify-center min-w-[100%] text-sm mt-5 '}>
      <RefreshIcon onClick={update}
                   className={`mb-2 w-4 h-4 cursor-pointer ${!(walletTokenRatesLoaded && walletTokensLoaded) ? 'animate-spin text-gray-600' : ''}`} />
      <table
        className={' w-full  px-6 py-4 text-left text-sm rounded-xl bg-secondary-bg'}>
        <thead
          className={'flex justify-around items-center text-xs font-bold uppercase  text-gray-300  rounded-tl-xl p-5 h-12 border-none w-full'}>
        <td className={'min-w-[20%]'}>
          Token
        </td>
        <td className={'min-w-[20%] '}>
          Mint
        </td>
        <td className={'min-w-[20%] '}>
          Balance
        </td>
        <td className={'min-w-[20%] '}>
          Value
        </td>
        <td className={'min-w-[20%] '}>
          Actions
        </td>

        </thead>
        {connected ? (
          <tbody className='bg-primary-bg-500 max-w-full'>
          {balances.slice(10 * page, 10 * page + 10).map((balance, idx) => {
            return (<tr key={idx}
                        className={`px-5 py-2 text-sm text-gray-300 flex justify-around items-center shadow-lineShadowBottom`}>
              <td
                className='pr-5 min-w-[20%] max-w-[20%]  truncate flex items-center'>
                {balance.tokenInfo && balance.tokenInfo.token && (
                  <img src={balance.tokenInfo.token!.logoURI}
                       className={'w-4 h-4 mr-2'} />
                )}
                {balance.coin || (balance.tokenInfo && balance.tokenInfo.token && balance.tokenInfo.token?.symbol) || (balance.mint === WRAPPED_SOL_MINT.toBase58() ? 'WSOL' : 'Unknown')}
              </td>
              <td
                className='pr-5 min-w-[20%] max-w-[20%] truncate cursor-pointer'
                onClick={() => navigator.clipboard.writeText(balance.mint)}>
                {balance.mint}
              </td>
              <td className={'pr-5 min-w-[20%] max-w-[20%] truncate'}>
                {balance.walletBalanceUi}
              </td>
              <td className={'pr-5 min-w-[20%] max-w-[20%] truncate'}>
                {balance.value === '-' ? balance.value : '$' + balance.value}
              </td>
              <td className={'pr-5 min-w-[20%] max-w-[20%] truncate'}>
                <LinkButton
                  className='font-medium m-2 text-blue-400'
                  primary={true}
                  onClick={() => {
                    setBurnToken(balance);
                    setShowBurnModal(true);
                  }}
                >
                  Burn
                </LinkButton>
                <LinkButton
                  className='font-medium m-2 text-blue-400'
                  primary={true}
                  onClick={() => {
                    if (balance.walletBalance > 0) {
                      notify({
                        message: 'Can\'t close a non empty account',
                        type: 'info',
                      });
                      return;
                    }
                    if (balance.tokenInfo) {
                      let closeTx = new Transaction();
                      closeTx.add(createCloseAccountInstruction(
                        balance.tokenInfo.address,
                        publicKey,
                        publicKey,
                      ));
                      sendTransactionWithNotification({
                        sendTransaction: sendTransaction,
                        transaction: closeTx,
                        connection,
                        message: 'Closing account',
                      }).then(() => {

                      });

                    }

                  }}
                >
                  Close
                </LinkButton>
              </td>
            </tr>);
          })}

          </tbody>
        ) : (
          <div>
            <h1
              className={'text-lg text-text-primary-300 min-h-[20rem] flex justify-center items-center'}>Connect
              Wallet</h1>
          </div>
        )}
      </table>
      <div className={'flex bg-primary-bg'}>
        <Button className={'py-2'} primary={true}
                disabled={!balances || !balances.find(b => b.walletBalance === 0)}
                onClick={async () => {
                  let instructions: any[] = [];
                  for (let [idx, account] of Object.entries(balances)) {
                    let succeeded = true;
                    if (instructions.length >= 27 || Number(idx) === balances.length - 1) {
                      let tx = new Transaction();
                      tx.add(...instructions);
                      try {
                        await sendTransactionWithNotification({
                          sendTransaction: sendTransaction,
                          transaction: tx,
                          connection,
                          message: `Closing ${instructions.length} accounts`,
                        });
                        instructions = [];
                      } catch (e) {
                        notify({
                          //@ts-ignore
                          message: e.message,
                          type: 'error',
                        });
                        succeeded = false;
                        console.error(e);
                      }


                    }
                    if (succeeded && account.tokenInfo && account.walletBalance === 0) {
                      instructions.push(createCloseAccountInstruction(
                        account.tokenInfo.address,
                        publicKey,
                        publicKey,
                      ));
                    }
                  }
                }}>Close All</Button>
        {balances.length > 10 && (
          <Pagination
            currentPage={page + 1}
            onPageChange={onPageChange}
            showIcons={true}
            totalPages={Math.ceil(balances.length / 10)}
          />
        )}

      </div>
      {showBurnModal && burnToken && (
        <Modal
          isOpen={showBurnModal}
          onClose={() => setShowBurnModal(false)}
          className={'rounded-xl bg-[#201A34] text-white'}
        >
          <div className={'flex justify-end w-full'}>
            <LinkButton
              onClick={() => setBurnAmount(burnToken.walletBalance)}>max</LinkButton>
          </div>
          <div className={'flex flex-col items-center'}>
            <SuffixInput onChange={(e) => {
              setBurnAmount(Number(e.target.value));
            }}
                         suffix={burnToken.coin || (burnToken.tokenInfo && burnToken.tokenInfo.token && burnToken.tokenInfo.token?.symbol) || (burnToken.mint === WRAPPED_SOL_MINT.toBase58() ? 'WSOL' : burnToken.mint.slice(0, 5) + '..')}
                         props={{
                           type: 'number',
                           max: burnToken.walletBalance,
                           value: burnAmount,
                         }} />
            <Button className={'mt-4'} primary={true} disabled={burning}
                    onClick={() => {
                      if (burnToken && burnToken.tokenInfo) {
                        setBurning(true);
                        let burnTx = new Transaction();
                        burnTx.add(createBurnInstruction(
                          burnToken!.tokenInfo!.address,
                          new PublicKey(burnToken.mint),
                          publicKey,
                          Number(new Decimal(burnAmount).mul(Math.pow(10, burnToken.tokenInfo.mint.decimals))),
                        ));
                        sendTransactionWithNotification({
                          sendTransaction: sendTransaction,
                          transaction: burnTx,
                          connection,
                          message: `Burning ${burnAmount} ${burnToken.coin || (burnToken.tokenInfo && burnToken.tokenInfo.token && burnToken.tokenInfo.token?.symbol) || (burnToken.mint === WRAPPED_SOL_MINT.toBase58() ? 'WSOL' : burnToken.mint.slice(0, 5) + '..')}`,
                        }).finally(() => {
                          setBurning(false);
                          setShowBurnModal(false);
                        });
                      }

                    }
                    }>
              Burn
            </Button>
          </div>
        </Modal>
      )
      }
    </div>
  );
};
export default ToolsPage;

import OpenOrderTable from './OpenOrderTable';
import React from 'react';
import FillsTable from './FillsTable';
import { useOpenOrders, useBalances, useMarket } from '../../utils/markets';



export default function UserInfoTable() {
  const openOrders = useOpenOrders();
  console.log(openOrders)
  return (
    <div className={'rounded-2xl bg-primary-bg w-full shadow-lineShadow h-full md:col-span-2'}>
      <div className={'w-full text-center font-bold bg-secondary-bg shadow-lineShadowBottom rounded-tr-2xl rounded-tl-2xl uppercase m-0 py-3'}>
        <span className={"text-md font-bold text-white"}>Open Orders</span>
      </div>
      <div className={"bg-primary-bg w-full "}>
        <OpenOrderTable openOrders={openOrders} />

      </div>
    </div>
  );
}

const OpenOrdersTab = () => {

};

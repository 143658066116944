import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../../theme/useTheme';
import { getFromLS } from '../../theme/storage';
import { WalletMultiButton } from '../Button';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { useInterval } from '../../utils/useInterval';
import { connected } from 'process';
import {Dropdown} from 'flowbite-react'
import { MenuIcon, XIcon } from '@heroicons/react/solid';


export default function TopBar() {

  //TODO: implement custom endpoint, theme
  const [addEndpointVisible, setAddEndpointVisible] = useState(false);
  const [testingConnection, setTestingConnection] = useState(false);
  const { setMode, theme } = useTheme();
  const themesFromStore = getFromLS('all-themes');
  const [data] = useState(themesFromStore.data);
  const [menuVisible, setMenuVisible] = useState(false);
  const [tps, setTps] = useState(0);
  const { connection } = useConnection();
  const {connected} = useWallet();
  const updateTps = async () => {
    try {

      const samples = 2;
      const response = await connection.getRecentPerformanceSamples(samples);
      const totalSecs = response.map(res => res.samplePeriodSecs).reduce((acc, nxt) => nxt);
      const totalTransactions = response.map(res => res.numTransactions).reduce((acc, nxt) => nxt);
      const tps = totalTransactions / totalSecs;
      setTps(tps);
    } catch {
      console.log('Unable to fetch TPS');
    }
  };
  useEffect(() => {
    updateTps();
  }, []);
  useInterval(async () => {
    await updateTps();
  }, 1000);

  return (
    <>
      <div className={'flex items-center justify-between p-4 '}>
        <div className={"md:hidden flex max-w-[20%] w-auto"}>
          <button onClick={() => setMenuVisible(!menuVisible)}
                  className="text-white bg-transparent"
                  type="button">
           <MenuIcon className={"text-white w-8 h-8"}/>
           </button>
          <div
            className={`${menuVisible ? "flex" : "hidden"}  z-10 absolute top-0 left-0 w-full h-[100vh] bg-primary-bg-600   justify-start flex-col items-center`}>
            <XIcon className={"w-6 h-6 self-end text-white mr-6 mt-6 cursor-pointer"} onClick={() => setMenuVisible(false)}/>

            <Link onClick={() => setMenuVisible(false)}
              className={'text-white text-lg hover:text-secondary-bg-100 flex items-center bg-transparent font-bold px-3'}
              to={'/market/J4oPt5Q3FYxrznkXLkbosAWrJ4rZLqJpGqz7vZUL4eMM'}>Trade</Link>
            <Link to={'/swap'} onClick={() => setMenuVisible(false)}
                  className={'text-white text-lg hover:text-secondary-bg-100 flex items-center font-bold px-3'}>Swap</Link>
            <Link to={'/tools'} onClick={() => setMenuVisible(false)}
                  className={'text-white text-lg hover:text-secondary-bg-100 flex items-center font-bold px-3'}>Tools</Link>
            <div
              className={`ml-2 whitespace-nowrap rounded-full px-1.5 py-0.5 text-xs `}
            >
              Solana TPS:{' '}
              <span className="font-bold">
              {tps?.toLocaleString(undefined, {
                maximumFractionDigits: 0,
              })}
            </span>
            </div>

          </div>
        </div>
        <div
          className={'sm:min-w-[8rem] sm2:min-w-[10rem] md:min-w-[15rem] xl:min-w-[20rem] md:ml-4'}>
          <div
            className={'bg-header-logo w-full lg:h-[35px] sm:h-[30px] bg-no-repeat bg-contain bg-center'} />
        </div>
        <div
          className={'sm:hidden md:flex w-fit '}>
          <div className="hidden md:flex items-center justify-end ">
            <div className="flex w-full items-center justify-end p-1">

              <div
                className={`ml-2 whitespace-nowrap rounded-full px-1.5 py-0.5 text-xs `}
              >
                Solana TPS:{' '}
                <span className="font-bold">
              {tps?.toLocaleString(undefined, {
                maximumFractionDigits: 0,
              })}
            </span>
              </div>
            </div>
          </div>
          <Link
            className={'text-white text-md hover:text-secondary-bg-100 flex items-center bg-transparent font-bold px-3'}
            to={'/market/J4oPt5Q3FYxrznkXLkbosAWrJ4rZLqJpGqz7vZUL4eMM'}>Trade</Link>
          <Link to={'/swap'}
                className={'text-white text-md hover:text-secondary-bg-100 flex items-center font-bold px-3'}>Swap</Link>
          <Link to={'/tools'}
                className={'text-white text-md hover:text-secondary-bg-100 flex items-center font-bold px-3 pr-8'}>Tools</Link>

          <WalletMultiButton className={'primary'} />
        </div>
        <div className={"md:hidden"}>
          <WalletMultiButton className={'primary'} />
        </div>
      </div>
    </>
  );
}

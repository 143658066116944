import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteInfo, TOKEN_LIST_URL } from '@jup-ag/core';
import { Token } from '../../utils/types';
import { useLocalStorageState } from '../../utils/utils';
import {
  getTokenAccountsByOwnerWithWrappedSol,
  nativeToUi,
  zeroKey,
} from '@blockworks-foundation/mango-client'
import { PublicKey } from '@solana/web3.js';
import { useJupiter } from '@jup-ag/react-hook';
import sortBy from 'lodash/sortBy'
import {
  ChevronDownIcon,
  CogIcon, ExclamationCircleIcon, RefreshIcon,
  SwitchHorizontalIcon,
  SwitchVerticalIcon,
} from '@heroicons/react/solid';

import FloatingElement from '../Layout/FloatingElement';
import { Col, Row } from 'antd';
import React from 'react';
import SwapTokenSelect from './subcomponents/token-select';
import { Button, IconButton, LinkButton } from '../Button';
import Modal from '../Modal';
import SwapSettingsModal from './subcomponents/settings-modal';
import { useInterval } from '../../utils/useInterval';
import { notify } from '../../utils/notifications';
import { useAsyncMemo } from 'use-async-memo';
import { TokenInfo } from '@solana/spl-token-registry';
import axios from 'axios';
export interface SwapForm {
  inToken: undefined | Token,
  outToken: undefined | Token,
  amount: undefined | number,
  inputMint: PublicKey,
  outputMint: PublicKey
}
const JupiterSwapForm = () => {
  const { wallet, publicKey, connected, signAllTransactions, signTransaction } =
    useWallet()
  const { connection } = useConnection();
  const [showSettings, setShowSettings] = useState(false)
  const [depositAndFee, setDepositAndFee] = useState<any | null>(null)
  const [selectedRoute, setSelectedRoute] = useState<RouteInfo | null>(null)
  const [showInputTokenSelect, setShowInputTokenSelect] = useState(false)
  const [showOutputTokenSelect, setShowOutputTokenSelect] = useState(false)
  const [swapping, setSwapping] = useState(false)
  const [tokens, setTokens] = useState<Token[]>([])
  const [tokenPrices, setTokenPrices] = useState<any | null>(null)
  const [coinGeckoList, setCoinGeckoList] = useState<any[] | null>(null)
  const [walletTokens, setWalletTokens] = useState<any[]>([])
  const [slippage, setSlippage] = useState(0.5)
  const [formValue, setFormValue] = useState<SwapForm>({
    amount: undefined,
    inToken: undefined,
    outToken: undefined,
    inputMint: new PublicKey("EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"),
    outputMint: new PublicKey("FgX1WD9WzMU3yLwXaFSarPfkgzjLb2DZCqmkx9ExpuvJ"),
  })
  const [hasSwapped, setHasSwapped] = useLocalStorageState('hasSwapped', false)
  const [showWalletDraw, setShowWalletDraw] = useState(false)
  const [walletTokenPrices, setWalletTokenPrices] = useState<any[] | null>(null)
  const [feeValue, setFeeValue] = useState<number | null>(null)
  const [showRoutesModal, setShowRoutesModal] = useState(false)
  const [loadWalletTokens, setLoadWalletTokens] = useState(false)
  const [swapRate, setSwapRate] = useState(false)
  const [activeTab, setActiveTab] = useState('Market Data')
  const [smallScreen, setSmallScreen] = useState(false)

  const fetchWalletTokens = useCallback(async () => {
    if (!publicKey) {
      return
    }
    const ownedTokens: any[] = []
    const ownedTokenAccounts = await getTokenAccountsByOwnerWithWrappedSol(
      connection,
      publicKey
    )

    ownedTokenAccounts.forEach((account) => {
      const decimals = tokens.find(
        (t) => t?.address === account.mint.toString()
      )?.decimals

      const uiBalance = nativeToUi(account.amount, decimals || 6)
      ownedTokens.push({ account, uiBalance })
    })
    setWalletTokens(ownedTokens)
  }, [publicKey, connection, tokens])

  // @ts-ignore
  const [inputTokenInfo, outputTokenInfo] = useMemo(() => {
    return [
      tokens.find(
        (item) => item?.address === formValue.inputMint?.toBase58() || ''
      ),
      tokens.find(
        (item) => item?.address === formValue.outputMint?.toBase58() || ''
      ),
    ]
  }, [
    formValue.inputMint?.toBase58(),
    formValue.outputMint?.toBase58(),
    tokens,
  ])

  useEffect(() => {
    const fetchCoinGeckoList = async () => {
      const response = await fetch(
        'https://api.coingecko.com/api/v3/coins/list'
      )
      const data = await response.json()
      setCoinGeckoList(data)
    }

    fetchCoinGeckoList()
  }, [])

  useEffect(() => {
    if (connected) {
      fetchWalletTokens()
    }
  }, [connected, fetchWalletTokens])

  useEffect(() => {
    if (!coinGeckoList?.length) return
    setTokenPrices(null)
    const fetchTokenPrices = async () => {
      const inputId = coinGeckoList.find((x) =>
        inputTokenInfo?.extensions?.coingeckoId
          ? x?.id === inputTokenInfo.extensions.coingeckoId
          : x?.symbol?.toLowerCase() === inputTokenInfo?.symbol?.toLowerCase()
      )?.id
      const outputId = coinGeckoList.find((x) =>
        outputTokenInfo?.extensions?.coingeckoId
          ? x?.id === outputTokenInfo.extensions.coingeckoId
          : x?.symbol?.toLowerCase() === outputTokenInfo?.symbol?.toLowerCase()
      )?.id

      if (inputId && outputId) {
        const results = await fetch(
          `https://api.coingecko.com/api/v3/simple/price?ids=${inputId},${outputId}&vs_currencies=usd`
        )
        const json = await results.json()
        if (json[inputId]?.usd && json[outputId]?.usd) {
          setTokenPrices({
            inputTokenPrice: json[inputId].usd,
            outputTokenPrice: json[outputId].usd,
          })
        }
      }
    }

    if (inputTokenInfo && outputTokenInfo) {
      fetchTokenPrices()
    }
  }, [inputTokenInfo, outputTokenInfo, coinGeckoList])

  const amountInDecimal = useMemo(() => {
    if (typeof formValue?.amount === 'number') {
      return formValue.amount * 10 ** (inputTokenInfo?.decimals || 1)
    }
  }, [inputTokenInfo, formValue.amount])

  const { routeMap, allTokenMints, routes, loading, exchange, error, refresh } =
    useJupiter({
      ...formValue,
      amount: amountInDecimal ? amountInDecimal : 0,
      slippage,
    })

  useEffect(() => {
    // Fetch token list from Jupiter API
    fetch(TOKEN_LIST_URL['mainnet-beta'])
      .then((response) => response.json())
      .then((result) => {
        const tokens = allTokenMints.map((mint) =>
          result.find((item) => item?.address === mint)
        )
        setTokens(tokens)
      })
  }, [allTokenMints])

  useEffect(() => {
    if (routes) {
      setSelectedRoute(routes[0])
    }
  }, [routes])

  useEffect(() => {
    const getDepositAndFee = async () => {
      const fees = await selectedRoute?.getDepositAndFee()
      if (fees) {
        setDepositAndFee(fees)
      }
    }
    if (selectedRoute && connected) {
      getDepositAndFee()
    }
  }, [selectedRoute])

  const outputTokenMints: any[] = useMemo(() => {
    if (routeMap.size && formValue.inputMint) {
      const routeOptions = routeMap.get(formValue.inputMint.toString())

      const routeOptionTokens =
        routeOptions?.map((address) => {
          return tokens.find((t) => {
            return t?.address === address
          })
        }) ?? []

      return routeOptionTokens
    } else {
      return sortedTokenMints
    }
  }, [routeMap, tokens, formValue.inputMint])

  const handleConnect = useCallback(() => {
    if (wallet) {
      //handleWalletConnect(wallet)
    }
  }, [wallet])

  const inputWalletBalance = () => {
    if (walletTokens.length) {
      const walletToken = walletTokens.filter((t) => {
        return t.account.mint.toString() === inputTokenInfo?.address
      })
      const largestTokenAccount = sortBy(walletToken, 'uiBalance').reverse()[0]
      return largestTokenAccount?.uiBalance || 0.0
    }

    return 0.0
  }

  const outputWalletBalance = () => {
    if (walletTokens.length) {
      const walletToken = walletTokens.filter((t) => {
        return t.account.mint.toString() === outputTokenInfo?.address
      })
      const largestTokenAccount = sortBy(walletToken, 'uiBalance').reverse()[0]
      return largestTokenAccount?.uiBalance || 0.0
    }
    return 0.0
  }

  const [walletTokensWithInfos] = useMemo(() => {
    const userTokens: any[] = []
    tokens.map((item) => {
      const found = walletTokens.find(
        (token) => token.account.mint.toBase58() === item?.address
      )
      if (found) {
        userTokens.push({ ...found, item })
      }
    })
    return [userTokens]
  }, [walletTokens, tokens])

  const getWalletTokenPrices = async () => {
    const ids = walletTokensWithInfos.map(
      (token) => token.item.extensions?.coingeckoId
    )
    const response = await fetch(
      `https://api.coingecko.com/api/v3/simple/price?ids=${ids.toString()}&vs_currencies=usd`
    )
    const data = await response.json()
    setWalletTokenPrices(data)
  }

  const refreshWallet = async () => {
    setLoadWalletTokens(true)
    await fetchWalletTokens()
    await getWalletTokenPrices()
    setLoadWalletTokens(false)
  }

  const getSwapFeeTokenValue = async () => {
    if (!selectedRoute) return
    const mints = selectedRoute.marketInfos.map((info) => info.lpFee.mint)
    const response = await fetch(
      `https://api.coingecko.com/api/v3/simple/token_price/solana?contract_addresses=${mints.toString()}&vs_currencies=usd`
    )
    const data = await response.json()
    // @ts-ignore
    const feeValue: number = selectedRoute.marketInfos.reduce((a, c) => {
      const feeToken = tokens.find((item) => item?.address === c.lpFee?.mint)
      // @ts-ignore
      const amount = c.lpFee?.amount / Math.pow(10, feeToken.decimals)
      if (data[c.lpFee?.mint]) {
        return a + data[c.lpFee?.mint].usd * amount
      }
      if (c.lpFee?.mint === 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v') {
        return a + 1 * amount
      }
    }, 0)
    if (feeValue) {
      setFeeValue(feeValue)
    }
  }

  useEffect(() => {
    if (selectedRoute) {
      getSwapFeeTokenValue()
    }
  }, [selectedRoute])

  useEffect(() => {
    getWalletTokenPrices()
  }, [walletTokensWithInfos])

  useInterval(() => {
    refresh()
  }, 10000)

  const handleSelectRoute = (route) => {
    setShowRoutesModal(false)
    setSelectedRoute(route)
  }

  const handleSwitchMints = () => {
    setFormValue((val) => ({
      ...val,
      inputMint: formValue.outputMint,
      outputMint: formValue.inputMint,
    }))
  }

  const sortedTokenMints = sortBy(tokens, (token) => {
    return token?.symbol.toLowerCase()
  })

  const outAmountUi = selectedRoute
    ? selectedRoute.outAmount / 10 ** (outputTokenInfo?.decimals || 1)
    : null
  const swapDisabled = loading || !selectedRoute || routes?.length === 0

  return (
    <div className="grid w-full justify-center items-center text-white ">

      <div className=" w-[25rem]  mb-10">
        <div className="flex flex-col justify-center  ">
          <div className="w-full " >
            <div className="relative shadow-lineShadow rounded-xl">

              <div className="rounded-xl bg-primary-bg p-6 ">
                <div className={"bg-primary-bg-600 rounded-lg pb-6 pt-2 px-3"}>
                <div className="flex justify-between">
                  <label
                    htmlFor="inputMint"
                    className="block text-sm font-semibold"
                  >
                    {"Pay"}
                  </label>
                  <div className="space-x-3">
                    <label htmlFor="amount" className="text-xs text-th-fgd-3">
                      {"Balance"} {inputWalletBalance()}
                    </label>
                    {connected ? (
                      <>
                        <LinkButton
                          className="text-xs text-th-primary"
                          onClick={() => {
                            setFormValue((val) => ({
                              ...val,
                              amount: inputWalletBalance() / 2,
                            }))
                          }}
                        >
                          {"half"}
                        </LinkButton> <LinkButton
                          className="text-xs text-th-primary"
                          onClick={() => {
                            setFormValue((val) => ({
                              ...val,
                              amount: inputWalletBalance() ,
                            }))
                          }}
                        >
                          {"max"}
                        </LinkButton>
                      </>
                    ) : null}
                  </div>
                </div>
                <div className="mt-2 grid grid-cols-2 ">
                  <div className="col-span-1">
                    <button
                      className="-ml-2 p-2 "
                      onClick={() => setShowInputTokenSelect(true)}
                    >
                      <div className="flex h-8 items-center">
                        {inputTokenInfo?.logoURI ? (
                          <img
                            className="rounded-full"
                            src={inputTokenInfo?.logoURI}
                            width="24"
                            height="24"
                            alt={inputTokenInfo?.symbol}
                          />
                        ) : null}
                        <div className="ml-2 text-base xl:text-lg">
                          {inputTokenInfo?.symbol}
                        </div>
                        <ChevronDownIcon className="ml-1 h-5 w-5 flex-shrink-0 text-th-fgd-3" />
                      </div>
                    </button>
                  </div>
                  <div className="col-span-1">
                    <input
                      name="amount"
                      id="amount"
                      className="default-transition h-12 w-full rounded-md border border-th-bkg-4 bg-th-bkg-1 pr-4 text-right text-base font-bold tracking-wide hover:border-th-fgd-4 focus:border-th-fgd-4 focus:outline-none text-black"
                      value={formValue.amount || ''}
                      placeholder="0.00"
                      type="number"
                      pattern="[0-9]*"
                      onInput={(e: any) => {
                        let newValue = e.target?.value || 0
                        newValue = Number.isNaN(newValue) ? 0 : newValue

                        setFormValue((val) => ({
                          ...val,
                          amount: Number(newValue),
                        }))
                      }}
                    />
                  </div>
                </div>
                </div>
                <div className="my-4 flex justify-center">
                  <button onClick={handleSwitchMints} className={"rounded-full bg-secondary-bg-600"}>
                    <SwitchVerticalIcon className="default-transition h-8 w-8 rounded-full bg-th-bkg-4 p-1.5 text-th-fgd-1 hover:text-th-primary" />
                  </button>
                </div>
                <div className="flex items-center justify-between">
                  <label htmlFor="outputMint" className="font-semibold">
                    {"Receive"}
                  </label>
                  <span className="text-xs text-th-fgd-3">
                    {"Balance"} {outputWalletBalance()}
                  </span>
                </div>
                <div className="mt-2 grid grid-cols-2">
                  <div className="col-span-1">
                    <button
                      className="-ml-2 flex h-12 items-center p-2 "
                      onClick={() => setShowOutputTokenSelect(true)}
                    >
                      {outputTokenInfo?.logoURI ? (
                        <img
                          className="rounded-full"
                          src={outputTokenInfo?.logoURI}
                          width="24"
                          height="24"
                          alt={outputTokenInfo?.symbol}
                        />
                      ) : null}
                      <div className="ml-2 text-base xl:text-lg">
                        {outputTokenInfo?.symbol}
                      </div>
                      <ChevronDownIcon className="ml-1 h-5 w-5 flex-shrink-0 text-th-fgd-3" />
                    </button>
                  </div>
                  <div className="relative col-span-1">
                    <input
                      name="amount"
                      id="amount"
                      className="h-12 w-full cursor-not-allowed rounded-md  border-none  pr-4 text-right text-lg font-bold tracking-wide focus:outline-none"
                      disabled
                      placeholder="0.00"
                      value={
                        selectedRoute?.outAmount && formValue.amount
                          ? Intl.NumberFormat('en', {
                            minimumSignificantDigits: 1,
                            maximumSignificantDigits: 6,
                          }).format(
                            selectedRoute?.outAmount /
                            10 ** (outputTokenInfo?.decimals || 1)
                          )
                          : ''
                      }
                    />
                    {selectedRoute?.outAmount &&
                    formValue.amount &&
                    tokenPrices?.outputTokenPrice ? (
                      <div className="absolute right-0 mt-1 text-xs text-th-fgd-3">
                        ≈ $
                        {(
                          (selectedRoute?.outAmount /
                            10 ** (outputTokenInfo?.decimals || 1)) *
                          tokenPrices?.outputTokenPrice
                        ).toFixed(2)}
                      </div>
                    ) : null}
                  </div>
                </div>
                {routes?.length && selectedRoute ? (
                  <div className="mt-8 text-xs text-th-fgd-3">
                    <div className="relative mb-4 rounded-md border border-secondary-bg-100 px-3 pb-4 pt-4">
                      {selectedRoute === routes[0] ? (
                        <div className="absolute -top-4 rounded-sm bg-th-primary px-1 text-xs font-bold text-th-bkg-1">
                          {"Best Route"}
                        </div>
                      ) : null}
                      <div className="flex items-center justify-between">
                        <div>
                          <span className="overflow-ellipsis whitespace-nowrap text-sm font-bold text-th-fgd-1">
                            {selectedRoute?.marketInfos.map((info, index) => {
                              let includeSeparator = false
                              if (
                                selectedRoute?.marketInfos.length > 1 &&
                                index !== selectedRoute?.marketInfos.length - 1
                              ) {
                                includeSeparator = true
                              }
                              return (
                                <span key={index}>{`${info.amm.label} ${
                                  includeSeparator ? 'x ' : ''
                                }`}</span>
                              )
                            })}
                          </span>
                          <div className="mr-2 mt-0.5 text-xs font-normal text-th-fgd-3">
                            {inputTokenInfo?.symbol} →{' '}
                            {selectedRoute?.marketInfos.map((r, index) => {
                              const showArrow =
                                index !== selectedRoute?.marketInfos.length - 1
                                  ? true
                                  : false
                              return (
                                <span key={index}>
                                  <span>
                                    {
                                      tokens.find(
                                        (item) =>
                                          item?.address ===
                                          r?.outputMint?.toString()
                                      )?.symbol
                                    }
                                  </span>
                                  {showArrow ? ' → ' : ''}
                                </span>
                              )
                            })}
                          </div>
                        </div>
                        <Button
                          className="rounded-md border border-th-fgd-4 bg-transparent px-2 pb-1 pt-1 text-center text-xs font-normal text-th-fgd-3"
                          disabled={routes?.length === 1}
                          onClick={() => setShowRoutesModal(true)}
                        >
                          {routes?.length} Routes Found
                        </Button>
                      </div>
                    </div>
                    <div className="space-y-2 px-3">
                      <div className="mb-4 flex items-center justify-between">
                        <div className="text-sm font-bold text-th-fgd-1">
                          {"Swap Details"}
                        </div>
                        <div className="flex items-center space-x-2">
                          <IconButton onClick={() => refresh()}>
                            <RefreshIcon
                              className={`h-4 w-4 ${
                                loading ? 'animate-spin' : ''
                              }`}
                            />
                          </IconButton>
                          <IconButton onClick={() => setShowSettings(true)}>
                            <CogIcon className="h-4 w-4" />
                          </IconButton>
                        </div>
                      </div>
                      {outAmountUi && formValue?.amount ? (
                        <div className="flex justify-between">
                          <span>{"rate"}</span>
                          <div>
                            <div className="flex items-center justify-end">
                              <div className="text-right text-th-fgd-1">
                                {swapRate ? (
                                  <>
                                    1 {inputTokenInfo?.symbol} ≈{' '}
                                    {(
                                      outAmountUi / formValue?.amount
                                    ).toFixed(5)}{' '}
                                    {outputTokenInfo?.symbol}
                                  </>
                                ) : (
                                  <>
                                    1 {outputTokenInfo?.symbol} ≈{' '}
                                    {(
                                      formValue?.amount / outAmountUi
                                    ).toFixed(5)}{' '}
                                    {inputTokenInfo?.symbol}
                                  </>
                                )}
                              </div>
                              <SwitchHorizontalIcon
                                className="default-transition ml-1 h-4 w-4 cursor-pointer text-th-fgd-3 hover:text-th-fgd-2"
                                onClick={() => setSwapRate(!swapRate)}
                              />
                            </div>
                            {tokenPrices?.outputTokenPrice &&
                            tokenPrices?.inputTokenPrice ? (
                              <div
                                className={`text-right ${
                                  ((formValue?.amount / outAmountUi -
                                      tokenPrices?.outputTokenPrice /
                                      tokenPrices?.inputTokenPrice) /
                                    (formValue?.amount / outAmountUi)) *
                                  100 <=
                                  0
                                    ? 'text-th-green'
                                    : 'text-th-red'
                                }`}
                              >
                                {Math.abs(
                                  ((formValue?.amount / outAmountUi -
                                      tokenPrices?.outputTokenPrice /
                                      tokenPrices?.inputTokenPrice) /
                                    (formValue?.amount / outAmountUi)) *
                                  100
                                ).toFixed(1)}
                                %{' '}
                                <span className="text-th-fgd-4">{`${
                                  ((formValue?.amount / outAmountUi -
                                      tokenPrices?.outputTokenPrice /
                                      tokenPrices?.inputTokenPrice) /
                                    (formValue?.amount / outAmountUi)) *
                                  100 <=
                                  0
                                    ? "cheaper than"
                                    : "more expensive than"
                                } CoinGecko`}</span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                      <div className="flex justify-between">
                        <span>{"Price Impact"}</span>
                        <div className="text-right text-th-fgd-1">
                          {selectedRoute?.priceImpactPct * 100 < 0.1
                            ? '< 0.1%'
                            : `~ ${(
                              selectedRoute?.priceImpactPct * 100
                            ).toFixed(4)}%`}
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <span>{"Minimum received"}</span>
                        {outputTokenInfo?.decimals ? (
                          <div className="text-right text-th-fgd-1">
                            {(
                              selectedRoute?.outAmountWithSlippage /
                              10 ** outputTokenInfo.decimals || 1
                            ).toFixed(5)}{' '}
                            {outputTokenInfo?.symbol}
                          </div>
                        ) : null}
                      </div>
                      {typeof feeValue === 'number' ? (
                        <div className="flex justify-between">
                          <span>{"Fee"}</span>
                          <div className="flex items-center">
                            <div className="text-right text-th-fgd-1">
                              ≈ ${feeValue?.toFixed(2)}
                            </div>
                          </div>
                        </div>
                      ) : (
                        selectedRoute?.marketInfos.map((info, index) => {
                          const feeToken = tokens.find(
                            (item) => item?.address === info.lpFee?.mint
                          )
                          return (
                            <div className="flex justify-between" key={index}>
                              <span>
                                Fees paid to {info?.amm?.label}
                              </span>
                              {feeToken?.decimals && (
                                <div className="text-right text-th-fgd-1">
                                  {(
                                    info.lpFee?.amount /
                                    Math.pow(10, feeToken.decimals)
                                  ).toFixed(6)}{' '}
                                  {feeToken?.symbol} ({info.lpFee?.pct * 100}%)
                                </div>
                              )}
                            </div>
                          )
                        })
                      )}
                      {connected ? (
                        <>
                          <div className="flex justify-between">
                            <span>{"Transaction Fee"}</span>
                            <div className="text-right text-th-fgd-1">
                              {depositAndFee
                                ? depositAndFee?.signatureFee / Math.pow(10, 9)
                                : '-'}{' '}
                              SOL
                            </div>
                          </div>
                          {depositAndFee?.ataDepositLength ||
                          depositAndFee?.openOrdersDeposits?.length ? (
                            <div className="flex justify-between">
                              <div className="flex items-center">
                                <span>{"Deposit"}</span>
                              </div>
                            </div>
                          ) : null}
                        </>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {error && (
                  <div className="mt-2 flex items-center justify-center text-th-red">
                    <ExclamationCircleIcon className="mr-1.5 h-5 w-5" />
                    Jupiter Error
                  </div>
                )}
                <Button
                  primary={true}
                  disabled={swapDisabled}
                  onClick={async () => {
                    if (!connected && zeroKey !== publicKey) {
                      handleConnect()
                    } else if (
                      !loading &&
                      selectedRoute &&
                      connected &&
                      wallet &&
                      signAllTransactions &&
                      signTransaction
                    ) {
                      setSwapping(true)
                      let txCount = 1
                      let errorTxid
                      const swapResult = await exchange({
                        wallet: {
                          sendTransaction: wallet?.adapter?.sendTransaction,
                          publicKey: wallet?.adapter?.publicKey,
                          signAllTransactions,
                          signTransaction,
                        },
                        routeInfo: selectedRoute,
                        onTransaction: async (txid, totalTxs) => {
                          console.log('txid, totalTxs', txid, totalTxs)
                          if (txCount === totalTxs) {
                            errorTxid = txid
                            notify({
                              type: 'info',
                              message: 'Confirming Transaction',
                              txid,
                            })
                          }
                          await connection.confirmTransaction(txid, 'confirmed')

                          txCount++
                          return await connection.getTransaction(txid, {
                            commitment: 'confirmed',
                          })
                        },
                      })
                      console.log('swapResult', swapResult)

                      setSwapping(false)
                      fetchWalletTokens()
                      if ('error' in swapResult) {
                        console.log('Error:', swapResult.error)
                        notify({
                          type: 'error',
                          message: swapResult?.error?.name
                            ? swapResult.error.name
                            : '',
                          description: swapResult?.error?.message,
                          txid: errorTxid,
                        })
                      } else if ('txid' in swapResult) {
                        const description =
                          swapResult?.inputAmount && swapResult.outputAmount
                            ? `Swapped ${
                              swapResult.inputAmount /
                              10 ** (inputTokenInfo?.decimals || 1)
                            } ${inputTokenInfo?.symbol} to ${
                              swapResult.outputAmount /
                              10 ** (outputTokenInfo?.decimals || 1)
                            } ${outputTokenInfo?.symbol}`
                            : ''
                        notify({
                          type: 'success',
                          message: 'Swap Successful',
                          description,
                          txid: swapResult.txid,
                        })
                        setFormValue((val) => ({
                          ...val,
                          amount: undefined,
                        }))
                      }
                    }
                  }}
                  className="mt-6 h-12 w-full text-base"
                >
                  {connected
                    ? swapping
                      ? "Swapping"
                      : 'Swap'
                    : 'Connect Wallet'}
                </Button>
              </div>


              {showRoutesModal ? (
                <Modal
                  isOpen={showRoutesModal}
                  onClose={() => setShowRoutesModal(false)}
                  className={"rounded-xl bg-[#201A34] text-white"}
                >
                  <div className="mb-4 text-center text-lg font-bold text-th-fgd-1">
                    Routes Found{ routes?.length}
                  </div>
                  <div className="thin-scroll max-h-96 overflow-y-auto overflow-x-hidden pr-1">
                    {routes?.map((route, index) => {
                      const selected = selectedRoute === route
                      return (
                        <div
                          key={index}
                          className={`default-transition mb-2 rounded border bg-th-bkg-3 hover:bg-th-bkg-4 ${
                            selected
                              ? 'border-secondary-bg-200 text-th-primary hover:border-th-primary'
                              : 'border-transparent text-th-fgd-1'
                          }`}
                        >
                          <button
                            className="w-full p-4"
                            onClick={() => handleSelectRoute(route)}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex flex-col text-left">
                                <div className="overflow-ellipsis whitespace-nowrap">
                                  {route.marketInfos.map((info, index) => {
                                    let includeSeparator = false
                                    if (
                                      route.marketInfos.length > 1 &&
                                      index !== route.marketInfos.length - 1
                                    ) {
                                      includeSeparator = true
                                    }
                                    return (
                                      <span key={index}>{`${info.amm.label} ${
                                        includeSeparator ? 'x ' : ''
                                      }`}</span>
                                    )
                                  })}
                                </div>
                                <div className="text-xs font-normal text-th-fgd-4">
                                  {inputTokenInfo?.symbol} →{' '}
                                  {route.marketInfos.map((r, index) => {
                                    const showArrow =
                                      index !== route.marketInfos.length - 1
                                        ? true
                                        : false
                                    return (
                                      <span key={index}>
                                        <span>
                                          {
                                            tokens.find(
                                              (item) =>
                                                item?.address ===
                                                r?.outputMint?.toString()
                                            )?.symbol
                                          }
                                        </span>
                                        {showArrow ? ' → ' : ''}
                                      </span>
                                    )
                                  })}
                                </div>
                              </div>
                              <div className="text-lg">
                                {(
                                  route.outAmount /
                                  10 ** (outputTokenInfo?.decimals || 1)
                                ).toFixed(5)}
                              </div>
                            </div>
                          </button>
                        </div>
                      )
                    })}
                  </div>
                </Modal>
              ) : null}
              {showInputTokenSelect ? (
                <SwapTokenSelect
                  isOpen={showInputTokenSelect}
                  onClose={() => setShowInputTokenSelect(false)}
                  sortedTokenMints={sortedTokenMints}
                  onTokenSelect={(token) => {
                    setShowInputTokenSelect(false)
                    setFormValue((val) => ({
                      ...val,
                      inputMint: new PublicKey(token?.address),
                    }))
                  }}
                />
              ) : null}
              {showOutputTokenSelect ? (
                <SwapTokenSelect
                  isOpen={showOutputTokenSelect}
                  onClose={() => setShowOutputTokenSelect(false)}
                  sortedTokenMints={outputTokenMints}
                  onTokenSelect={(token) => {
                    setShowOutputTokenSelect(false)
                    setFormValue((val) => ({
                      ...val,
                      outputMint: new PublicKey(token?.address),
                    }))
                  }}
                />
              ) : null}
              {showSettings ? (
                <SwapSettingsModal
                  isOpen={showSettings}
                  onClose={() => setShowSettings(false)}
                  slippage={slippage}
                  setSlippage={setSlippage}
                />
              ) : null}
              {showInputTokenSelect ? (
                <SwapTokenSelect
                  isOpen={showInputTokenSelect}
                  onClose={() => setShowInputTokenSelect(false)}
                  sortedTokenMints={sortedTokenMints}
                  onTokenSelect={(token) => {
                    setShowInputTokenSelect(false)
                    setFormValue((val) => ({
                      ...val,
                      inputMint: new PublicKey(token?.address),
                    }))
                  }}
                />
              ) : null}
              {showOutputTokenSelect ? (
                <SwapTokenSelect
                  isOpen={showOutputTokenSelect}
                  onClose={() => setShowOutputTokenSelect(false)}
                  sortedTokenMints={outputTokenMints}
                  onTokenSelect={(token) => {
                    setShowOutputTokenSelect(false)
                    setFormValue((val) => ({
                      ...val,
                      outputMint: new PublicKey(token?.address),
                    }))
                  }}
                />
              ) : null}
            </div>
          </div>

          {inputTokenInfo && (
            <PriceLineChart token={inputTokenInfo} />
          )}
          {outputTokenInfo && (
            <PriceLineChart token={outputTokenInfo} />
          )}

        </div>
      </div>
    </div>
  )
}

function PriceLineChart({
                         token
                        }: {
  token: Token | undefined
}) {

  const pricePoints = useAsyncMemo(
    async () =>
      token?.extensions?.coingeckoId ? await getCoingeckoChartPriceData(token?.extensions?.coingeckoId) : undefined,
    [token]
  )

  const startPrice = pricePoints?.[0]
  const endPrice = pricePoints?.[pricePoints.length - 1]
  const floatPercent = startPrice && endPrice ? (endPrice - startPrice) / startPrice : 0
  const isPositive = floatPercent > 0
  const isNegative = floatPercent < 0

  const canShowKline = Boolean(token && pricePoints?.length)


  const lineColor = isPositive ? 'rgba(44,209,31,0.76)' : isNegative ? 'rgba(239,46,97,0.65)' : '#0a41c6'

  const maxPrice = Math.max(...pricePoints || [])
  const minPrice = Math.min(...pricePoints || [])
  const diff = maxPrice - minPrice
  const xLength = pricePoints ? pricePoints.length : 0

  function getPriceYPoint(p: number) {
    const minH = 0.07 * maxPrice
    if (diff > minH) {
      return ((diff - (p - minPrice)) / diff) * 1000
    } else {
      return 1000 - (((minH - diff) / 2 + (p - minPrice)) * 1000) / minH
    }
  }

  return (
    <div>
      {canShowKline && (
        <div className="grid grid-cols-[1fr_1fr_1fr_2fr] gap-2 p-3 py-4 w-full self-center items-center">
          <div className="items-center justify-self-center w-16 w-8 flex-shrink-0">
            <div className="gap-1 flex flex-col justify-center  items-center">
              <img src={token?.logoURI} className={"w-10 h-10"} />
              <div className="font-bold text-xs text-gray-300">{token?.symbol ?? '--'}</div>
            </div>

          </div>

          <div className="items-end items-center justify-self-center ml-0 text-end w-full">
            <div className="text-xs font-medium text-white">Price</div>
            <div className="text-xs font-bold text-white whitespace-nowrap">
              {'$' +
                endPrice?.toFixed(endPrice < 0.1 ? token?.decimals ?? 4 : 2) ?? '--'}
            </div>
          </div>

          <div className="items-start items-center justify-self-center ml-8  ml-0 w-full text-start">
            <div className="text-xs font-medium text-white">24H%</div>
            <div
              className={`text-xs  font-bold ${
                isPositive ? 'text-[rgba(44,209,31,0.76)]' : isNegative ? 'text-[rgba(239,46,97,0.65)]' : 'text-white'
              }`}
            >
              {floatPercent.toFixed(2)}
            </div>
          </div>
          <svg  viewBox={`0 0 2000 1000`} preserveAspectRatio="none">
            <defs>
              <filter id={`k-line-glow-${isPositive ? 'positive' : isNegative ? 'negative' : 'normal'}`}>
                <feFlood result="flood" floodColor={lineColor} floodOpacity=".8"/>
                <feComposite in="flood" result="mask" in2="SourceGraphic" operator="in"/>
                <feMorphology in="mask" result="dilated" operator="dilate" radius="3"/>
                <feGaussianBlur in="dilated" result="blurred" stdDeviation="8"/>
                <feMerge>
                  <feMergeNode in="blurred"/>
                  <feMergeNode in="SourceGraphic"/>
                </feMerge>
              </filter>
            </defs>
            <g filter={`url(#k-line-glow-${isPositive ? 'positive' : isNegative ? 'negative' : 'normal'})`}>
              {Boolean(xLength) &&
                (diff && pricePoints ? (
                  <polyline
                    vectorEffect="non-scaling-stroke"
                    points={pricePoints
                      .map((p, i) => {
                        const y = getPriceYPoint(p)
                        const x = (i / xLength) * 2000
                        return `${x},${y}`
                      })
                      .join(' ')}
                    stroke={lineColor}
                    fill="none"
                  />
                ) : (
                  <line
                    x1="0"
                    y1="499.9" // filter will not render 0 height, so have to let it a little diff from end point's y
                    x2="2000"
                    y2="500"
                    stroke={lineColor}
                    fill="none"
                    strokeWidth="16"
                  />
                ))}
            </g>
          </svg>

        </div>
      )}
    </div>
  )
}

async function getCoingeckoChartPriceData(coingeckoId: string | undefined): Promise<number[]> {
  if (!coingeckoId) return []
  const response = await axios.get(
    `https://api.coingecko.com/api/v3/coins/${coingeckoId}/market_chart?vs_currency=usd&days=1`
  )
  if (!response) return []
  return response.data.prices.map(([, price]) => price)
}

export default JupiterSwapForm;

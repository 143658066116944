import { Row, Col, Divider, Popover } from 'antd';
import React, { useState } from 'react';
import FloatingElement from '../Layout/FloatingElement';
import {
  useBalances,
  useMarket,
  useSelectedBaseCurrencyAccount,
  useSelectedOpenOrdersAccount,
  useSelectedQuoteCurrencyAccount,
  useTokenAccounts,
  getSymbol,
} from '../../utils/markets';
import DepositDialog from '../DepositDialog';
import logo from '../../assets/discord.svg';
import { settleFunds } from '../../utils/send';
import { notify } from '../../utils/notifications';
import { Balances } from '../../utils/types';
import StandaloneTokenAccountsSelect from '../StandaloneTokenAccountSelect';
import LinkAddress from '../LinkAddress';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useInterval } from '../../utils/useInterval';
import { useLocalStorageState } from '../../utils/utils';
import RowBox from './subcomponents/row-box';
import SettleButton from './subcomponents/settle-button';
import styled from 'styled-components';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { Button } from '../Button';

const FormWrapper = styled(Col)`
  display: flex;
  justify-content: space-between;
`;

const FormInfoSpan = styled('span')`
  flex: 1;
  font-style: normal;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
  color: white;
`;

const FormInfoLighSpan = styled('span')`
  flex: 1;
  font-style: normal;
  line-height: 18px;
  font-weight: 500;
  text-align: center;
  color: white;
`;

export default function StandaloneBalancesDisplay({
  styleProps = {},
  smallScreen,
}: any) {
  const { baseCurrency, quoteCurrency, market } = useMarket();
  // @ts-ignore
  const wewe = getSymbol(market?.address.toBase58()) as any;
  const balances = useBalances();
  const openOrdersAccount = useSelectedOpenOrdersAccount(true);
  const { connection } = useConnection();
  const { wallet, sendTransaction, connected } = useWallet();
  const [baseOrQuote, setBaseOrQuote] = useState('');
  const baseCurrencyAccount = useSelectedBaseCurrencyAccount();
  const quoteCurrencyAccount = useSelectedQuoteCurrencyAccount();
  const [tokenAccounts] = useTokenAccounts();
  const baseCurrencyBalances =
    balances && balances.find((b) => b.coin === baseCurrency);
  const quoteCurrencyBalances =
    balances && balances.find((b) => b.coin === quoteCurrency);
  const [autoSettleEnabled] = useLocalStorageState('autoSettleEnabled', true);
  const [lastSettledAt, setLastSettledAt] = useState<number>(0);

  async function onSettleFunds() {
    if (!wallet) {
      notify({
        message: 'Wallet not connected',
        description: 'wallet is undefined',
        type: 'error',
      });
      return;
    }

    if (!market) {
      notify({
        message: 'Error settling funds',
        description: 'market is undefined',
        type: 'error',
      });
      return;
    }
    if (!openOrdersAccount) {
      notify({
        message: 'Error settling funds',
        description: 'Open orders account is undefined',
        type: 'error',
      });
      return;
    }
    if (!baseCurrencyAccount) {
      notify({
        message: 'Error settling funds',
        description: 'Open orders account is undefined',
        type: 'error',
      });
      return;
    }
    if (!quoteCurrencyAccount) {
      notify({
        message: 'Error settling funds',
        description: 'Open orders account is undefined',
        type: 'error',
      });
      return;
    }

    try {
      await settleFunds({
        market,
        openOrders: openOrdersAccount,
        connection,
        wallet: wallet.adapter,
        baseCurrencyAccount,
        quoteCurrencyAccount,
        sendTransaction
      });
    } catch (e) {
      notify({
        message: 'Error settling funds',
        description: (e as any).message,
        type: 'error',
      });
    }
  }


  const formattedBalances: [
    string | undefined,
    Balances | undefined,
    string,
    string | undefined,
    string | undefined,
  ][] = [
    [
      baseCurrency,
      baseCurrencyBalances,
      'base',
      market?.baseMintAddress.toBase58(),
      wewe?.symbol1,
    ],
    [
      quoteCurrency,
      quoteCurrencyBalances,
      'quote',
      market?.quoteMintAddress.toBase58(),
      wewe?.symbol2,
    ],
  ];
  return (
    <div className={'rounded-2xl self-start bg-primary-bg w-full shadow-lineShadow md:h-full md:p-4 flex flex-col justify-between md:col-span-2 lg:col-span-1 xl:col-span-2 '}>

    <div className={"p-5"}>
        <FormWrapper>
          <FormInfoSpan> Token</FormInfoSpan>
          {connected && (
            <FormInfoSpan>Account</FormInfoSpan>
          )}
          <FormInfoSpan>Wallet</FormInfoSpan>
          <FormInfoSpan>Unsettled</FormInfoSpan>
        </FormWrapper>
        {formattedBalances.map(
          ([currency, balances, baseOrQuote, mint, symbol1], index) => (
            <React.Fragment key={index}>
              <FormWrapper
                style={{
                  margin: 4,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <FormInfoSpan style={{ display: 'flex' }}>
                  <img
                    src={symbol1 ? symbol1 : logo}
                    style={{
                      height: '22px',
                      width: '22px',
                      padding: '0px',
                      borderRadius: '50%',
                    }}
                    alt="symbol"
                  />
                  <span style={{ paddingLeft: 4 }}>{currency}</span>
                  <span className="text-white" style={{ paddingLeft: 4 }}>
                    {mint && (
                      <Popover
                        content={<LinkAddress address={mint} />}
                        placement="top"
                        title="Token mint address"
                        trigger="hover"
                      >
                        <InfoCircleOutlined style={{ color: '#851CEF' }} />
                      </Popover>
                    )}
                  </span>
                </FormInfoSpan>
                 {connected && (
                <div className={"flex"}>
                  <StandaloneTokenAccountsSelect
                    accounts={tokenAccounts?.filter(
                      (account) => account.effectiveMint.toBase58() === mint,
                    )}
                    mint={mint}
                  />
                </div>
              )}
                <FormInfoLighSpan>
                  {(balances && balances.wallet) || '-'}
                </FormInfoLighSpan>
                <FormInfoLighSpan>
                  {(balances && balances.unsettled) || '-'}
                </FormInfoLighSpan>
              </FormWrapper>

            </React.Fragment>
          ),
        )}
      </div>
      <div className={"w-full h-max flex items-end justify-end self-end"}>
          <Button primary={true} disabled={!connected}  onClick={onSettleFunds}>
            Settle all funds
          </Button>
      </div>
    </div>
  );
}

import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import TradePage from './pages/TradePage';
import OpenOrdersPage from './pages/OpenOrdersPage';
import React, { lazy, useEffect } from 'react';
import BalancesPage from './pages/BalancesPage';
import ConvertPage from './pages/ConvertPage';
import DexLearnPage from './pages/DexLearnPage';
import BasicLayout from './components/BasicLayout';
import { getTradePageUrl } from './utils/markets';
import SwapPage from './pages/SwapPage';
import { useWallet } from '@solana/wallet-adapter-react';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import ToolsPage from './pages/ToolsPage';

export function Routes(props) {
  const {connect} = useWallet()

  return (
    <>
      <HashRouter basename={'/'}>

      <BasicLayout>
          <Switch>
            <Route exact path="/">
              <Redirect to={getTradePageUrl()} />
            </Route>
            <Route exact path="/market/:marketAddress">
              <TradePage />
            </Route>
            <Route path="/swap">
              <SwapPage/>
            </Route>
            <Route path={"/tools"}>
              <ToolsPage />
            </Route>
            <Route exact path="/orders" component={OpenOrdersPage} />
            <Route exact path="/convert" component={ConvertPage} />
            <Route exact path="/dexlearn" component={DexLearnPage} />
          </Switch>
        </BasicLayout>
      </HashRouter>
    </>
  );
}

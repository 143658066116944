import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Col, Row, Select, Typography } from 'antd';
import styled, { keyframes } from 'styled-components';
import moment from 'moment';
import marketCategories from '../constants/marketCategories';
import {
  getMarketInfos,
  getTradePageUrl,
  MarketProvider,
  useAllVolumes,
  useMarket,
  useMarketsList,
  useMarkPrice,
  useNinjaTrades,
  useUnmigratedDeprecatedMarkets,
  useVolume,
} from '../utils/markets';
import TradeForm from '../components/TradeForm';
import { CaretDownOutlined, CloseOutlined } from '@ant-design/icons';
import { notify } from '../utils/notifications';
import { useHistory, useParams } from 'react-router-dom';
import { TVChartContainer } from '../components/TradingView';
import { abbreviateNumber, isNullOrUndefined } from '../utils/utils';
import SearchIcon from '../assets/search.svg';
import { TokenInfo, TokenListProvider } from '@solana/spl-token-registry';
import marketTokens from '../token-mints.json';

import { parseTokenMintData } from '../utils/tokens';
import BN from 'bn.js';
import { useGetAccountInfo } from '../hooks/connection';
import { Button, ExternalLinkButton } from '../components/Button';
import {
  ArrowRightSharp,
  ChangeCircle, ChangeHistory,
  PriceChange,
} from '@mui/icons-material';
import OrderBook from '../components/OrderBook';
import PublicTrades from '../components/TradesTable';
import UserInfoTable from '../components/UserInfoTable';
import StandaloneBalancesDisplay from '../components/StandaloneBalancesDisplay';
import { useConnection } from '@solana/wallet-adapter-react';
import { useInterval } from '../utils/useInterval';
import { Spinner } from 'flowbite-react';


const dropInAnimation = keyframes`
  0% {
    height: 100vh;
    width: 0px;
  }
  100% {
    height: 100vh;
    width: 500px;
  }
`;
const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 16px;

  .borderNone .ant-select-selector {
    border: none !important;
  }
`;
const AddLink = styled.a`
  padding: 5px;
  color: #ffffff;

  &:hover {
    color: #851cef;
  }
`;

const Main = styled('div')`
  font-family: sans-serif;
  background: #f0f0f0;
  height: 100vh;
`;


export default function TradePage() {
  const { marketAddress } = useParams();

  useEffect(() => {
    if (marketAddress) {
      localStorage.setItem('marketAddress', JSON.stringify(marketAddress));
    }
  }, [marketAddress]);
  const history = useHistory();

  function setMarketAddress(address) {
    history.push(getTradePageUrl(address));
  }

  return (
    <MarketProvider
      marketAddress={marketAddress}
      setMarketAddress={setMarketAddress}
    >
      <TradePageInner />
    </MarketProvider>
  );
}

function TradePageInner() {
  const {
    market,
    marketName,
    customMarkets,
    setCustomMarkets,
    setMarketAddress,
    quoteCurrency,
  } = useMarket();
  const markets = useMarketsList();
  const [handleDeprecated, setHandleDeprecated] = useState(false);
  const deprecatedMarkets = useUnmigratedDeprecatedMarkets();
  const [marketsListVisible, setMarketsListVisible] = useState(false);
  const [volumes] = useAllVolumes();

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const markPrice = useMarkPrice();
  const [trades, tradesLoaded] = useNinjaTrades();
  const [volume, volumeLoaded] = useVolume();
  const [tokenMap, setTokenMap] = useState<Map<string, TokenInfo>>(new Map());

  const { accountInfo, loaded } = useGetAccountInfo(market?.baseMintAddress);

  const accountInfoData = useMemo(
    () => (accountInfo ? parseTokenMintData(accountInfo.data) : null),
    [accountInfo],
  );

  useEffect(() => {
    new TokenListProvider().resolve().then((tokens) => {
      const tokenList = tokens
        .filterByClusterSlug('mainnet-beta')
        .getList()
        .filter((token) =>
          marketTokens.some((t) => t.address === token.address),
        );

      setTokenMap(
        tokenList.reduce((map, item) => {
          map.set(item.address, item);
          return map;
        }, new Map()),
      );
    });
  }, [setTokenMap]);

  const token = market
    ? tokenMap.get(market!.baseMintAddress.toBase58())
    : null;

  let decimals = '1';
  let totalSupply = '0';
  if (accountInfoData) {
    for (let i = 0; i < accountInfoData.decimals; i++) decimals += '0';
    totalSupply = accountInfoData!.supply.div(new BN(decimals)).toString();
  }

  useEffect(() => {
    document.title = marketName ? `${marketName} — Serum` : 'Serum';
  }, [marketName]);

  const changeOrderRef = useRef<
    // @ts-ignore
    ({ size, price }: { size?: number; price?: number }) => void
    >();

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const componentProps = {
    onChangeOrderRef: (ref) => (changeOrderRef.current = ref),
    onPrice: useCallback(
      (price) => changeOrderRef.current && changeOrderRef.current({ price }),
      [],
    ),
    onSize: useCallback(
      (size) => changeOrderRef.current && changeOrderRef.current({ size }),
      [],
    ),
  };

  const dayPercentChange = useMemo(() => {
    if (!tradesLoaded || !trades || trades.length == 0 || !markPrice) return;

    const compareTime = moment().subtract(1, 'days').unix();
    let minAbs = Number.MAX_SAFE_INTEGER;
    let minIndex = -1;
    for (const [index, trade] of trades.entries()) {
      const timeDiff = Math.abs(trade.time - compareTime);
      if (minAbs > timeDiff) {
        minAbs = timeDiff;
        minIndex = index;
      }
    }

    const yesterdayValue = trades[minIndex].price;
    const change = Number(markPrice! - yesterdayValue);
    const percentChange = (change * 100) / yesterdayValue;
    return percentChange;
  }, [tradesLoaded, trades, markPrice]);

  const yesterdayHighPrice = useMemo(() => {
    if (!tradesLoaded || !trades || trades.length == 0 || !markPrice) return;
    const compareTime = moment().subtract(1, 'days').unix();
    let minAbs = Number.MAX_SAFE_INTEGER;
    let minIndex = -1;
    for (const [index, trade] of trades.entries()) {
      const timeDiff = Math.abs(trade.time - compareTime);
      if (minAbs > timeDiff) {
        minAbs = timeDiff;
        minIndex = index;
      }
    }

    const yesterdayValue = trades[minIndex].price;
    return yesterdayValue;
  }, [tradesLoaded, trades, markPrice]);

  const volumeChange = useMemo(() => {
    if (!volumeLoaded || !volume) return;
    if (Array.isArray(volume)) return volume[0]['24H'].totalVolumeUsd;
    return volume['24H'].totalVolumeUsd;
  }, [volume, volumeLoaded]);


  const onDeleteCustomMarket = (address) => {
    const newCustomMarkets = customMarkets.filter((m) => m.address !== address);
    setCustomMarkets(newCustomMarkets);
  };

  const getChangeColor = () => {
    if (isNullOrUndefined(dayPercentChange)) return '#FFF';
    return dayPercentChange! >= 0 ? '#33cc33' : '#ec0408';
  };

  const coinPrice = useMemo(() => {
    if (markPrice) {
      return markPrice > 1 ? markPrice.toFixed(3) : markPrice;
    }
  }, [markPrice]);

  const Volume24Hr = useMemo(() => {
    if (volumeChange == 0) return '-';

    return !isNullOrUndefined(volumeChange)
      ? volumeChange!.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      })
      : '-';
  }, [volumeChange]);

  const fdmc =
    coinPrice !== undefined
      ? typeof coinPrice === 'string'
        ? Number.parseFloat(coinPrice) * Number.parseFloat(totalSupply)
        : coinPrice * Number.parseFloat(totalSupply)
      : 0;


  return (
    <>
      {marketsListVisible && (
        <MarketSelectorNew
          markets={markets}
          setHandleDeprecated={setHandleDeprecated}
          placeholder={'Select market'}
          customMarkets={customMarkets}
          onDeleteCustomMarket={onDeleteCustomMarket}
          onClose={() => setMarketsListVisible(false)}
          volumes={volumes}
        />
      )}
      <section
        className='text-white body-font overflow-hidden'>
        <div className='p-5 pt-1 w-full overflow-hidden'>
          <div className='grid grid-cols-1 grid-rows-[auto_auto_repeat(6,_23rem)] gap-4  items-center md:grid-cols-2 md:grid-rows-[auto_auto_23rem_23rem_15rem_15rem] lg:grid-rows-[auto_auto_23rem_15rem]  lg:grid-cols-3 xl:grid-cols-4 xl:grid-rows-[auto_23rem_23rem_15rem]'>
            <div className={'rounded-2xl bg-primary-bg w-full p-2 shadow-lineShadow md:col-span-2 lg:col-span-3 xl:col-span-4' }>
              <div className={'grid md:flex'}>
                <Button className={"md:border-r-2"}
                  onClick={() => {
                    setMarketsListVisible(!marketsListVisible);
                  }}
                >
                  <div className={"flex justify-center items-center text-xl "}>Markets <ArrowRightSharp/></div>
                </Button>
                <div className={'grid grid-cols-2  md:grid-cols-4 justify-start px-3 m-1'}>
                  <div className={'grid'}>
                    <span className={"font-bold text-sm"}>Last Price</span>
                    <div>
                      <small>
                        <b
                          className={`text-[${getChangeColor()}]`}
                        >
                          ${coinPrice}
                        </b>
                      </small>
                    </div>
                  </div>
                  <div className={'grid'}>
                      <span className={"font-bold text-sm"}>24H Change</span>
                      <div>
                        <small>
                          <b
                            style={{
                              color: getChangeColor(),
                            }}
                          >
                            {!isNullOrUndefined(dayPercentChange) ? (
                              <span className={"text-xs font-normal"}>
                                {dayPercentChange! >= 0 ? '+' : ''}
                                {dayPercentChange!.toFixed(2)}%
                              </span>
                            ) : (
                              '-'
                            )}
                          </b>
                        </small>
                      </div>
                  </div>
                  <div
                  >
                    <span className={"font-bold text-sm"}>24H High Price</span>
                    <div>
                      <span>
                        <b>
                          {!isNullOrUndefined(yesterdayHighPrice) ? (
                            <span className={"text-xs font-normal"}>{yesterdayHighPrice}</span>
                          ) : (
                            '-'
                          )}
                        </b>
                      </span>
                    </div>
                  </div>
                  <div>
                          <span className={"font-bold text-sm"}>
                            24hr Volume ({quoteCurrency})
                          </span>
                    <div>
                      <span className={"text-xs font-normal"}>
                        <b>{Volume24Hr}</b>
                      </span>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <TVChartContainer  />
            <TradeForm setChangeOrderRef={componentProps.onChangeOrderRef} />
            <OrderBook
                    onPrice={componentProps.onPrice}
                    onSize={componentProps.onSize}
            />
            <PublicTrades />
            <TokenData
                      token={token}
                      coinPrice={coinPrice}
                      Volume24Hr={Volume24Hr}
                      totalSupply={totalSupply}
                      fdmc={fdmc}
                      quoteCurrency={quoteCurrency}
                    ></TokenData>
            <UserInfoTable />
            <StandaloneBalancesDisplay />
          </div>
        </div>
      </section>

    </>
  );
}

enum MarketListSortType {
  MARKET,
  CHANGE24H,
  CHANGE7D,
  VOLUME24H,
}

enum MarketCategory {
  ALL,
  NINJA,
  USDC,
}

function MarketSelectorNew({
                             markets,
                             placeholder,
                             setHandleDeprecated,
                             customMarkets,
                             onDeleteCustomMarket,
                             onClose,
                             volumes,
                           }) {
  const { market, setMarketAddress } = useMarket();

  const [sortBy, setSortBy] = useState<MarketListSortType>(0);
  const [cat, setCategory] = useState<MarketCategory>(0);
  const [allMarkets, setAllMarkets] = useState<any[]>(
    [...markets, ...customMarkets].map((market) => ({
      value: market.name,
      address: market.address.toBase58(),
      symbol: market.symbol1,
      volume: volumes
        ?.filter((v) => v.address === market.address.toBase58())
        .pop(),
    })),
  );
  const [visibleMarkets, setVisibleMarkets] = useState<any[]>(
    [...markets, ...customMarkets].map((market) => ({
      value: market.name,
      address: market.address.toBase58(),
      symbol: market.symbol1,
      volume: volumes
        ?.filter((v) => v.address === market.address.toBase58())
        .pop(),
    })),
  );

  useEffect(() => sort(), [visibleMarkets]);

  const filterMarkets = (event) => {
    let text = event.target.value;
    setVisibleMarkets(
      allMarkets.filter((market) => market.value.includes(text.toUpperCase())),
    );
  };

  const setInputText = (event) => {
    event.target.value = currentMarket.value;
  };

  const resetVisibleMarkets = (event) => {
    setVisibleMarkets(allMarkets);
  };

  const selectInputText = (event) => {
    event.target.select();
  };

  const searchFocused = (event) => {
    selectInputText(event);
    resetVisibleMarkets(event);
  };

  const selectedMarket = getMarketInfos(customMarkets).find(
    (proposedMarket) =>
      market?.address && proposedMarket.address.equals(market.address),
  );

  const currentMarket = selectedMarket
    ? {
      value: selectedMarket.name,
      address: selectedMarket.address.toBase58(),
      symbol: selectedMarket.symbol1,
    }
    : {
      value: 'Select market',
      address: '',
      symbol: '',
    };

  const sort = () => {
    switch (sortBy) {
      case MarketListSortType.CHANGE7D: {
        sortBy7dChange();
        break;
      }
      case MarketListSortType.MARKET: {
        sortByMarket();
        break;
      }
      case MarketListSortType.CHANGE24H: {
        sortBy24hChange();

        break;
      }
      case MarketListSortType.VOLUME24H: {
        sortBy24hVolume();
        break;
      }
    }
  };

  const sortByMarket = () => {
    setSortBy(MarketListSortType.MARKET);
    setVisibleMarkets(
      visibleMarkets.sort((a, b) => (a.value > b.value ? 1 : -1)),
    );
  };
  const sortBy24hChange = () => {
    setSortBy(MarketListSortType.CHANGE24H);
    setVisibleMarkets(
      visibleMarkets.sort((a, b) =>
        a.volume['24H'].change > b.volume['24H'].change ? -1 : 1,
      ),
    );
  };
  const sortBy24hVolume = () => {
    setSortBy(MarketListSortType.VOLUME24H);
    setVisibleMarkets(
      visibleMarkets.sort((a, b) =>
        a.volume['24H'].totalVolumeUsd > b.volume['24H'].totalVolumeUsd
          ? -1
          : 1,
      ),
    );
  };

  const sortBy7dChange = () => {
    setSortBy(MarketListSortType.CHANGE7D);
    setVisibleMarkets(
      visibleMarkets.sort((a, b) =>
        a.volume['7d'].change > b.volume['7d'].change ? -1 : 1,
      ),
    );
  };

  const filterForCategory = (category: MarketCategory) => {
    switch (category) {
      case MarketCategory.ALL: {
        setVisibleMarkets(
          allMarkets.filter((market) =>
            marketCategories.all.some((m) => m.address === market.address),
          ),
        );
        break;
      }
      case MarketCategory.NINJA: {
        setVisibleMarkets(
          allMarkets.filter((market) =>
            marketCategories.ninja.some((m) => m.address === market.address),
          ),
        );
        break;
      }
      case MarketCategory.USDC: {
        setVisibleMarkets(
          allMarkets.filter((market) =>
            marketCategories.usdc.some((m) => m.address === market.address),
          ),
        );
        break;
      }
    }
    setCategory(category);
  };
  return (
    <div className={'animate- text-white fixed left-0  p-3 bg-secondary-bg min-h-full max-h-full min-w-[20rem] z-10'}>

      {volumes ? (
        <>
          <div className={'flex font-bold justify-end p-2'}>
            <CloseOutlined onClick={onClose} />
          </div>
            <div className={'flex justify-around w-full'}>
              <Button
                className={
                  'bg-transparent text-primary-bg-300' +
                  (cat === MarketCategory.ALL ? 'text-text-primary-200' : '')
                }
                onClick={() => filterForCategory(MarketCategory.ALL)}
              >
                All
              </Button>
              <Button
                className={
                  'bg-transparent text-primary-bg-300' +
                  (cat === MarketCategory.NINJA ? 'text-text-primary-200' : '')
                }
                onClick={() => filterForCategory(MarketCategory.NINJA)}
              >
                NINJA
              </Button>
              <Button
                className={
                  'bg-transparent text-primary-bg-300' +
                  (cat === MarketCategory.USDC ? 'text-text-primary-200' : '')
                }
                onClick={() => filterForCategory(MarketCategory.USDC)}
              >
                USDC
              </Button>
            </div>
          <div>
            <div className={'flex bg-primary-bg-600 rounded-full items-center shadow-lineShadow'}>
              <img className={"w-4 h-4 text-text-primary-600 m-2"} src={SearchIcon} alt='search' />
              <input className={"w-full p-2 rounded-full text-text-primary-200 bg-transparent"} placeholder={'Search'} onInput={filterMarkets} />
            </div>
          </div>
          <div className={'mt-4 justify-start grid grid-cols-[6rem_5rem_5rem_6rem] px-2'}>
              <Button className={'bg-transparent text-start px-0 py-2 w-full'} onClick={sortByMarket}>
                Market{' '}
                {sortBy === MarketListSortType.MARKET && <CaretDownOutlined />}
              </Button>
            <Button className={'bg-transparent text-end px-0 py-2 w-full'}onClick={sortBy24hChange}>
                24H {' '}
                {sortBy === MarketListSortType.CHANGE24H && (
                  <CaretDownOutlined />
                )}
            </Button>
            <Button className={'bg-transparent text-end px-0 py-2 w-full'} onClick={sortBy7dChange}>
                7D{' '}
                {sortBy === MarketListSortType.CHANGE7D && (
                  <CaretDownOutlined />
                )}
            </Button>
            <Button className={'bg-transparent text-end px-0 py-2 w-full'} onClick={sortBy24hVolume}>
                Volume{' '}
                {sortBy === MarketListSortType.VOLUME24H && (
                  <CaretDownOutlined />
                )}
            </Button>
          </div>
          <div className={'overflow-y-scroll max-h-[80vh]'}>
            {visibleMarkets.map((market, idx) => {
              return (
                <div
                  key={idx}
                  className={'shadow-lineShadowBottom cursor-pointer justify-start grid grid-cols-[6rem_5rem_5rem_6rem] px-2'}
                  onClick={() => {
                    setMarketAddress(market.address);
                    onClose();
                  }}
                >
                    <div className={'bg-transparent text-start flex px-0 py-2 w-full items-center '}>
                        <img
                          src={market.symbol}
                          className={"mx-2 w-4 h-4"}
                        />
                      <span className={"text-xs font-bold"}>{market.value}</span>
                    </div>
                  <div className={"bg-transparent text-end px-0 py-2 w-full"}>

                    {market.volume ? (
                      <span
                        className={
                          market.volume['24H'].change > 0
                            ? 'green-text'
                            : 'red-text'
                        }
                      >
                          {market.volume['24H'].change.toFixed(2)}%
                        </span>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                  <div className={"bg-transparent text-end px-0 py-2 w-full"}>

                    {market.volume ? (
                      <span
                        className={
                          market.volume['7d'].change > 0
                            ? 'green-text'
                            : 'red-text'
                        }
                      >
                          {market.volume['7d'].change.toFixed(2)}%
                        </span>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                  <div className={"bg-transparent text-end px-0 py-2 w-full"}>

                    {market.volume ? (
                      <span>
                          {abbreviateNumber(
                            market.volume['24H'].totalVolumeUsd,
                          )}
                        </span>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div className={'w-full h-[50%] min-h-[20rem] flex justify-center items-center'}>
          <Spinner size={'xl'} color={'warning'} />
        </div>
      )}
    </div>
  );
}

const TokenData = ({
                     token,
                     coinPrice,
                     quoteCurrency,
                     Volume24Hr,
                     totalSupply,
                     fdmc,
                   }) => {
  return (
    <div className={'rounded-2xl bg-primary-bg w-full shadow-lineShadow h-full flex flex-col items-around'}>
        <div
          className={"flex items-center flex-col mt-10 mb-0"}
        >
          <img
            className={"w-10 h-10"}
            src={token?.logoURI}
            alt='logo'
          />
          <span className={"font-bold"}>{token?.name}({token?.symbol})</span>
            <div style={{ marginLeft: 4 }}>
              <a
                href={`https://explorer.solana.com/address/${token?.address}`}
                target='_blank'
                rel='noreferrer'
              >
                {token?.address.substring(0, 6)}...
                {token?.address.substring(
                  token?.address.length - 6,
                  token?.address.length,
                )}
              </a>
            </div>
        </div>
        <div className={"flex justify-center mt-5"}>
          <div className={"flex flex-col justify-center items-end w-[50%]"}>
            <div className={"text-sm mr-3"}>
              Price:
            </div>
            <div className={"text-sm mr-3"}>
              24hr Volume ({quoteCurrency}):
            </div>
            <div className={"text-sm mr-3"}>
              Max Total Supply:
            </div>
            <div className={"text-sm mr-3"}>
              FDMC:
            </div>
          </div>
          <div className={"flex flex-col justify-center items-start font-bold w-[50%]"}>
            <div
            >
              ${coinPrice}
            </div>
            <div

            >
              {Volume24Hr}
            </div>
            <div
            >
              {totalSupply === '0'
                ? '-'
                : Number.parseFloat(totalSupply).toLocaleString()}
            </div>
            <div
            >
              {fdmc === 0 ? '-' : '$' + fdmc.toLocaleString()}
            </div>

          </div>

        </div>

        <div className={"flex justify-center mt-2"}>
          {token?.extensions?.website && (
            <ExternalLinkButton
              className={"mr-4"}
            >
              <a href={token?.extensions?.website} target={"_blank"}>
              Website

              </a>
            </ExternalLinkButton>
          )}
          {token?.extensions?.twitter && (
            <ExternalLinkButton
              className={"mr-4"}

            >
              <a href={token?.extensions?.twitter} target={"_blank"}>
                Twitter
              </a>
            </ExternalLinkButton>
          )}
          {token?.extensions?.coingeckoId && (
            <ExternalLinkButton
            >
              <a
              href={`https://www.coingecko.com/en/coins/${token?.extensions?.coingeckoId}`}
              target='_blank'
              rel='noreferrer'
            >
              Coingecko
              </a>
            </ExternalLinkButton>
          )}
        </div>
    </div>
  );
};

/*

function MarketSelector({
  markets,
  placeholder,
  setHandleDeprecated,
  customMarkets,
  onDeleteCustomMarket,
}) {
  const { market, setMarketAddress } = useMarket();

  const onSetMarketAddress = (marketAddress) => {
    setHandleDeprecated(false);
    setMarketAddress(marketAddress);
  };

  const extractBase = (a) => a.split('/')[0];
  const extractQuote = (a) => a.split('/')[1];

  const selectedMarket = getMarketInfos(customMarkets)
    .find(
      (proposedMarket) =>
        market?.address && proposedMarket.address.equals(market.address),
    )
    ?.address?.toBase58();

  return (
    <Select
      showSearch
      size={'large'}
      style={{ width: 200 }}
      placeholder={placeholder || 'Select a market'}
      optionFilterProp="name"
      onSelect={onSetMarketAddress}
      listHeight={400}
      value={selectedMarket}
      filterOption={(input, option) =>
        option?.name?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {customMarkets && customMarkets.length > 0 && (
        <OptGroup label="Custom">
          {customMarkets.map(({ address, name }, i) => (
            <Option
              value={address}
              key={nanoid()}
              name={name}
              style={{
                padding: '10px',
                // @ts-ignore
                backgroundColor: i % 2 === 0 ? 'rgb(39, 44, 61)' : null,
              }}
            >
              <Row>
                <Col flex="auto">{name}</Col>
                {selectedMarket !== address && (
                  <Col>
                    <DeleteOutlined
                      onClick={(e) => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                        onDeleteCustomMarket && onDeleteCustomMarket(address);
                      }}
                    />
                  </Col>
                )}
              </Row>
            </Option>
          ))}
        </OptGroup>
      )}
      <OptGroup label="NINJA MARKETS">
        {markets
          .sort((a, b) =>
            extractQuote(a.name) === 'USDT' && extractQuote(b.name) !== 'USDT'
              ? -1
              : extractQuote(a.name) !== 'USDT' &&
                extractQuote(b.name) === 'USDT'
              ? 1
              : 0,
          )
          .sort((a, b) =>
            extractBase(a.name) < extractBase(b.name)
              ? -1
              : extractBase(a.name) > extractBase(b.name)
              ? 1
              : 0,
          )
          .map(({ address, name, deprecated, symbol1 }, i) => (
            <Option
              value={address.toBase58()}
              key={nanoid()}
              name={name}
              style={{
                // @ts-ignore
                backgroundColor: i % 2 === 0 ? 'rgb(39, 44, 61)' : null,
              }}
            >
              {
                <span>
                  <img src={symbol1} width="20px" height="18px" />
                </span>
              }{' '}
              {name} {deprecated ? ' (Deprecated)' : null}
            </Option>
          ))}
      </OptGroup>
    </Select>
  );
}

const DeprecatedMarketsPage = ({ switchToLiveMarkets }) => {
  return (
    <>
      <Row>
        <Col flex="auto">
          <DeprecatedMarketsInstructions
            switchToLiveMarkets={switchToLiveMarkets}
          />
        </Col>
      </Row>
    </>
  );
};

const RenderNormal = ({ onChangeOrderRef, onPrice, onSize }) => {
  return (
    <div>
      <Row
        style={{
          minHeight: '500px',
          flexWrap: 'nowrap',
        }}
      >
        <Col flex={'auto'} style={{ height: '100%' }}>
          <TVChartContainer />
        </Col>
        <Col flex={'450px'} style={{ height: '100%' }}>
          <Orderbook smallScreen={true} onPrice={onPrice} onSize={onSize} />

          <TradesTable smallScreen={true} />
        </Col>
        <Col flex="450px" style={{ height: '100%', flexDirection: 'column' }}>
          <TradeForm setChangeOrderRef={onChangeOrderRef} smallScreen={true} />

          <StandaloneBalancesDisplay styleProps={{}} smallScreen={true} />
        </Col>
      </Row>
      <Row>
        <UserInfoTable />
      </Row>
    </div>
  );
};

const RenderSmall = ({ onChangeOrderRef, onPrice, onSize }) => {
  return (
    <>
      <Row>
        <Col flex="auto" style={{ height: '100%' }}>
          <TVChartContainer />
        </Col>
        <Col
          className="tradetabletwo"
          flex="410px"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <TradesTable smallScreen={false} />
        </Col>
      </Row>
      <Row className="tradepagerowtwo">
        <Col flex="33.3333%" style={{ height: '100%' }}>
          <StandaloneBalancesDisplay styleProps={{ height: 403 }} />
        </Col>
        <Col flex="33.3333%" style={{ height: '100%' }}>
          <TradeForm setChangeOrderRef={onChangeOrderRef} smallScreen={false} />
        </Col>
        <Col flex="33.3333%" style={{ height: '100%' }}>
          <Orderbook smallScreen={false} onPrice={onPrice} onSize={onSize} />
        </Col>
      </Row>
      <Row>
        <Col flex="auto">
          <UserInfoTable />
        </Col>
      </Row>
    </>
  );
};

const RenderSmaller = ({ onChangeOrderRef, onPrice, onSize }) => {
  return (
    <>
      <Row>
        <Col flex="auto" style={{ height: '100%' }}>
          <TVChartContainer />
        </Col>
      </Row>
      <Row className="tradepagerowtwosmaller">
        <Col xs={24} sm={12} style={{ height: '100%', display: 'flex' }}>
          <TradeForm
            style={{ flex: 1 }}
            setChangeOrderRef={onChangeOrderRef}
            smallScreen={false}
          />
        </Col>
        <Col xs={24} sm={12} style={{ height: '100%' }}>
          <StandaloneBalancesDisplay />
        </Col>
      </Row>
      <Row className="tradepagerowthreesmaller">
        <Col xs={24} sm={12} style={{ height: '100%', display: 'flex' }}>
          <Orderbook smallScreen={false} onPrice={onPrice} onSize={onSize} />
        </Col>
        <Col xs={24} sm={12} style={{ height: '100%', display: 'flex' }}>
          <TradesTable smallScreen={false} />
        </Col>
      </Row>
      <Row>
        <Col flex="auto">
          <UserInfoTable />
        </Col>
      </Row>
    </>
  );
};
*/

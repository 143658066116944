import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 16px 24px;
  background-color: purple;
`;

export default function FloatingElement({
  style = undefined,
  children,
  stretchVertical = false,
}: {
  style?: any | undefined;
  children: any;
  stretchVertical?: boolean | undefined;
}) {
  return (
    <Wrapper
      style={{
        ...style,
      }}
    >
      {children}
    </Wrapper>
  );
}

import React, { useEffect, useState } from 'react';
import Modal from '../../Modal';
import { Button, ButtonGroup, LinkButton } from '../../Button';
import Input from '../../Input';

const slippagePresets = ['0.1', '0.5', '1', '2']

const SwapSettingsModal = ({
                             isOpen,
                             onClose,
                             slippage,
                             setSlippage,
                           }: {
  isOpen: boolean
  onClose?: () => void
  slippage: number
  setSlippage: (x) => void
}) => {
  const [tempSlippage, setTempSlippage] = useState(slippage)
  const [inputValue, setInputValue] = useState(
    tempSlippage ? tempSlippage.toString() : ''
  )
  const [showCustomSlippageForm, setShowCustomSlippageForm] = useState(false)

  const handleSetTempSlippage = (s) => {
    setTempSlippage(s)
    setInputValue('')
  }

  const handleSave = () => {
    setSlippage(inputValue ? parseFloat(inputValue) : tempSlippage)
    onClose?.()
  }

  useEffect(() => {
    if (!slippagePresets.includes(tempSlippage.toString())) {
      setShowCustomSlippageForm(true)
    }
  }, [])

  return (
    <Modal isOpen={isOpen} onClose={onClose} hideClose className={"bg-[#201A34] text-white"}>
      <Modal.Header>
        <h2 className="text-lg font-bold text-white ">
          Slippage settings
        </h2>
      </Modal.Header>
      <div className="flex justify-between">
        Slippage
      </div>
        <Input
          className={"text-black"}
          type="text"
          placeholder="0.00"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          suffix="%"
        />
      <Button className="mt-6 w-full" onClick={handleSave}>
        Save
      </Button>
    </Modal>
  )
}

export default SwapSettingsModal

import React from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { TokenAccount } from '../../utils/types';
import { useSelectedTokenAccounts } from '../../utils/markets';
import { abbreviateAddress } from '../../utils/utils';
import { notify } from '../../utils/notifications';

import {
  PublicKey,
  SYSVAR_RENT_PUBKEY,
  TransactionInstruction,
} from '@solana/web3.js';
import { useWallet } from '@solana/wallet-adapter-react';
import { Button } from '../Button';

export const TOKEN_PROGRAM_ID = new PublicKey(
  'TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA',
);



export default function StandaloneTokenAccountsSelect({
                                                        accounts,
                                                        mint,
                                                        label,
                                                      }: {
  accounts: TokenAccount[] | null | undefined;
  mint: string | undefined;
  label?: boolean;
}) {



  const { wallets, connected, wallet, select, connect, disconnect, publicKey } = useWallet();
  const userPubKey = (connected && publicKey?.toBase58()) || '';

  const [
    selectedTokenAccounts,
    setSelectedTokenAccounts,
  ] = useSelectedTokenAccounts();

  let selectedValue: string | undefined;
  if (mint && mint in selectedTokenAccounts) {
    selectedValue = selectedTokenAccounts[mint];
  } else if (accounts && accounts?.length > 0) {
    selectedValue = accounts[0].pubkey.toBase58();
  } else {
    selectedValue = undefined;
  }

  const setTokenAccountForCoin = (value) => {
    if (!mint) {
      notify({
        message: 'Error selecting token account',
        description: 'Mint is undefined',
        type: 'error',
      });
      return;
    }
    const newSelectedTokenAccounts = { ...selectedTokenAccounts };
    newSelectedTokenAccounts[mint] = value;
    setSelectedTokenAccounts(newSelectedTokenAccounts);
  };

  return (
    <React.Fragment>
      {label && <div className={"w-20"}>Token account:</div>}
      <div className={"bg-primary-bg w-12 md:w-28"}>
        <select
          className={"w-full bg-primary-bg border-none outline-none ring-none"}
          value={selectedValue}
          onSelect={setTokenAccountForCoin}
        >
          {accounts?.map((account) => (
            <option
              className={"bg-primary-bg w-24 truncate"}
              key={account.pubkey.toBase58()}
              value={account.pubkey.toBase58()}
            >
                {label
                  ? abbreviateAddress(account.pubkey, 8)
                  : account.pubkey.toBase58()}
            </option>
          ))}
        </select>
      </div>
        <Button
          className={"bg-primary-bg text-white px-0 flex items-center"}
          onClick={() =>
            selectedValue && navigator.clipboard.writeText(selectedValue)
          }
        ><CopyOutlined /></Button>

      {/*<Col span={2} offset={1}>
        <Button
          shape="round"
          icon={<DeleteOutlined />}
          size={'small'}
          onClick={() =>
            selectedValue && wallets.closeTokenAccount(publicKey)
          }
        />
      </Col>*/}
    </React.Fragment>
  );
}

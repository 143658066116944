import styled from 'styled-components';

export default styled.button`
  background: transparent;
  padding: 0 1.2em;
  display: flex;
  align-items: center;
  height: 41px;
  border: 0px;
  border-radius: 50px;
  background: #F8F8F8;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  &:hover {
    color: white;
  }
  > span {
    font-family: 'Roboto';
    font-style: normal;
    color: black;
    font-size: 22px;
    font-weight: 900;
    line-height: 25.78px;
    margin: 19px 9px;
  }
`;

import React, { useEffect, useState } from 'react';
import { useMarket, useNinjaTrades } from '../../utils/markets';
import { getDecimalCount } from '../../utils/utils';
import { NinjaTrade } from '../../utils/types';


export default function PublicTrades() {
  const { baseCurrency, quoteCurrency, market } = useMarket();
  const [trades, loaded] = useNinjaTrades();

  const [sortedTrades, setSortedTrades] = useState<NinjaTrade[]>([]);

  useEffect(() => {
    if (!trades) return;

    const maxTrades = 50;
    if (trades.length > maxTrades) {
      setSortedTrades(
        trades
          .filter(
            (t, idx) =>
              !trades.some((tr, idex) => {
                return (
                  idx !== idex &&
                  t.size === tr.size &&
                  t.side === tr.side &&
                  t.price === tr.price &&
                  t.feeCost === tr.feeCost &&
                  t.orderId === tr.orderId
                );
              }),
          )
          .sort((a, b) => b.time - a.time)
          .slice(0, maxTrades),
      );
    } else {
      setSortedTrades(trades);
    }
  }, [trades]);
  return (
    <div
      className={'rounded-2xl bg-primary-bg p-5 shadow-lineShadow h-full'}>

      <p className={'text-medium font-bold uppercase w-full text-center'}>Market
        trades</p>
      <div
        className={'grid grid-cols-3 grid-cols-[2fr_1fr_2fr] justify-items-center w-full'}>
        <span
          className={'text-xs opacity-50 justify-self-center'}
        >
          Price({quoteCurrency})
        </span>
        <span
          className={'text-xs opacity-50 justify-self-end'}
        >
          Size({baseCurrency})
        </span>
        <span
          className={'text-xs opacity-50 justify-self-end'}
        >
          Time
        </span>
      </div>
      {sortedTrades && loaded && (
        <div
          className='max-h-[80%] overflow-y-scroll mt-1'
        >
          {sortedTrades.map((trade: NinjaTrade, i: number) => (
            <div key={i}
                 className={'grid grid-cols-3  grid-cols-[2fr_1fr_2fr] justify-self-right'}>
              <div
                style={{
                  color: trade.side === 'buy' ? '#33cc33' : '#ec0408',
                  fontSize: 13,
                  lineHeight: '16px',
                  fontWeight: 700,
                  justifySelf: 'center',
                }}
              >
                {market?.tickSize && !isNaN(trade.price)
                  ? Number(trade.price).toFixed(
                    getDecimalCount(market.tickSize),
                  )
                  : trade.price}
              </div>
              <div
                style={{
                  justifySelf: 'right',
                  fontSize: 13,

                }}
              >
                {market?.minOrderSize && !isNaN(trade.size)
                  ? Number(trade.size).toFixed(
                    getDecimalCount(market.minOrderSize),
                  )
                  : trade.size}
              </div>
              <div
                style={{
                  textAlign: 'right',
                  fontSize: 13,

                }}
              >
                {new Date(trade.time * 1000).toLocaleTimeString()}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

import React from 'react';
import TopBar from '../TopBar';
import { CustomFooter as Footer } from '../Footer';

export default function BasicLayout({ children }) {
  return (
    <div className="grid bg-primary-bg grid-rows-3 grid-rows-[5rem_1fr_5rem] grid-cols-[1fr] min-h-[100vh] w-full relative">
      <header className="text-white max-w-full body-font max-h-20 h-[70px] bg-primary-bg">
        <TopBar />
      </header>
      {children}
      <Footer />
    </div>
  );
}

import { Button } from 'antd';
import styled from 'styled-components';

const ActionButton = styled(Button)`
  color: #2abdd2;
  background-color: #212734;
  border-width: 0px;
`;

export default ActionButton;

import React, { Suspense, useEffect, useMemo, useState } from 'react';
// import './style.less';
import 'antd/dist/antd.css'
import { ErrorBoundary } from '../ErrorBoundary';
import { Routes } from '../../routes';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  BitKeepWalletAdapter,
  BitpieWalletAdapter,
  BloctoWalletAdapter,
  CloverWalletAdapter,
  Coin98WalletAdapter,
  CoinhubWalletAdapter,
  GlowWalletAdapter,
  LedgerWalletAdapter,
  MathWalletAdapter,
  PhantomWalletAdapter,
  SafePalWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  SolongWalletAdapter,
  TokenPocketWalletAdapter,
  TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import {
  WalletModalProvider,
} from '@solana/wallet-adapter-react-ui';
import {
  ConnectionProvider, WalletProvider,
} from '@solana/wallet-adapter-react';


import '@solana/wallet-adapter-react-ui/styles.css';
import { Toaster } from 'react-hot-toast';


export default function App() {

  const network = WalletAdapterNetwork.Mainnet;

  // Todo: add an option for users to provide custom rpc
  const endpoint = useMemo(() => 'https://ninja.genesysgo.net', [network]);

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new GlowWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new TorusWalletAdapter(),
      new SolletWalletAdapter(),
      new SolletExtensionWalletAdapter(),
      new CoinhubWalletAdapter(),
      new SolongWalletAdapter(),
      new CloverWalletAdapter(),
      new SafePalWalletAdapter(),
      new MathWalletAdapter(),
      new TokenPocketWalletAdapter(),
      new Coin98WalletAdapter(),
      new BitKeepWalletAdapter(),
      new LedgerWalletAdapter(),
      new BitpieWalletAdapter(),
      new BloctoWalletAdapter(),
    ],
    [network],
  );

  return (
    <ErrorBoundary>
      <ConnectionProvider endpoint={endpoint}>
        <Toaster
          position="bottom-right"
          reverseOrder={true}
          toastOptions={{
            className: '',
            duration: 15000,
          }}
        />
      <WalletProvider wallets={wallets}  >
            <WalletModalProvider>

                <Routes
                />

            </WalletModalProvider>
      </WalletProvider>


      </ConnectionProvider>

    </ErrorBoundary>
  );
}

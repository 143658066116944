import { WalletMetadataToken, WalletToken } from './types';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { useEffect, useState } from 'react';
import { chunk, findMetadataPda, Metaplex } from '@metaplex-foundation/js';
import { useWalletTokens } from './tokens';
import { PublicKey } from '@solana/web3.js';
import {
  DataV2,
  Metadata,
} from '@metaplex-foundation/mpl-token-metadata';
import axios from 'axios';
import { isValidUrl } from './utils';
export const METAPLEX_METADATA_PROGRAM = new PublicKey("metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s")

export interface UpdateMetadataInstructionsArgs {
  data?: DataV2 | null;
  newAuthority?: PublicKey | null;
  metadata: PublicKey;
  payer?: PublicKey;
  updateAuthority?: PublicKey;
}


export function useWalletMetadataTokens(): {
  metadataTokens: (WalletMetadataToken  | undefined)[] | null;
  walletMetadataTokensLoaded: boolean;
  walletMetadataTokensLoading: boolean;
  refreshWalletMetadataTokens: () => void;
} {
  const { connection } = useConnection();
  const { connected, wallet, publicKey } = useWallet();
  const { tokens, refreshWalletTokens, walletTokensLoaded } = useWalletTokens();

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [metadataTokens, setMetadataTokens] = useState<
    (WalletMetadataToken  | undefined)[] | null
    >(null);
  const [lastRefresh, setLastRefresh] = useState(0);

  const refreshMetadataTokens = () => {
    if (new Date().getTime() - lastRefresh > 10 * 1000) {
      setRefresh((prev) => prev + 1);
    } else {
      console.log('not refreshing');
    }
  };

  useEffect(() => {
    setRefresh(prevState => prevState + 1)
  }, [walletTokensLoaded, connected])
  useEffect(() => {
    if (connected  && tokens ) {
      const getWalletMetadataTokens = async () => {
        setLoading(true);
        let _walletMetadataTokens: WalletMetadataToken[] = []
        let metadataPdas = await Promise.all(tokens.filter(t=> t && t.tokenAccount).map(async t => {
          return {
            token: t,
            address: await findMetadataPda(t?.tokenAccount.mint)

          }
        }))
        let metadataPdaAccountsResults = await Promise.all(chunk(metadataPdas, 99).map(async pdas => await connection.getMultipleAccountsInfo(pdas.map(p => p.address))))

        let metadataPdaAccounts = metadataPdaAccountsResults.flat(1)
        let withMetadata: {token: WalletToken|undefined, metadata: Metadata, metadataAccount: PublicKey}[] = []
        for (let i = 0; i < metadataPdas.length; i ++) {
          if (!metadataPdaAccounts[i]) continue
          let [decodedMetadata, key] = Metadata.fromAccountInfo(metadataPdaAccounts[i]!)

          withMetadata.push({
            token: metadataPdas[i].token,
            metadata: decodedMetadata,
            metadataAccount: metadataPdas[i].address
          })
        }
        await Promise.all(withMetadata.map(async (data) => {
          let final = {
            token: data.token,
            metadata: data.metadata,
            offChain: undefined,
            metadataAddress: data.metadataAccount
          }

          try {
            if (isValidUrl(data.metadata.data.uri)) {
              let offChainData = (await axios.get(data.metadata.data.uri)).data
              final.offChain = offChainData

            }
          } catch (e) {
            console.warn(e)
          } finally {
            _walletMetadataTokens.push(final)
          }
        }))

        setMetadataTokens(_walletMetadataTokens)
        setLastRefresh(new Date().getTime());
        setLoaded(true);
        setLoading(false);
      };
      getWalletMetadataTokens();
    }
  }, [connection, connected, wallet, refresh]);
  return {
    metadataTokens,
    walletMetadataTokensLoaded: loaded,
    refreshWalletMetadataTokens: refreshMetadataTokens,
    walletMetadataTokensLoading: loading
  };
};

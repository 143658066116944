import React from 'react';

import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import JupiterSwapForm from '../components/JupiterSwapForm';
import { JupiterProvider } from '@jup-ag/react-hook';

const SwapPage = ( ) => {


  const { wallet, connected, publicKey } = useWallet();
  const { connection } = useConnection();


  return (
    //@ts-ignore
    <JupiterProvider
      connection={connection}
      cluster="mainnet-beta"
      userPublicKey={connected ? publicKey! : undefined}
    >
      <div className={'min-h-max'}>

      <div className={"grid w-full justify-center items-center text-white "}>
      <div className={"w-[25rem]"}>
        <div className="flex flex-col md:flex-row md:space-x-6 justify-center">
          <div className={"w-full flex justify-between align-bottom flex-col mt-10 z-10"}>
            <h1 className={"text-white text-3xl self-center"}>Ninja Swap</h1>
            <span className={"text-white-600/25 text-xs self-end p-2"}>Powered by jupiter</span>
          </div>
        </div>
      </div>
    </div>
    <JupiterSwapForm />
      </div>

    </JupiterProvider>
  );
};


export default SwapPage;

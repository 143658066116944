import React, { useRef, useEffect, useState } from 'react';
import { useMarket, useOrderbook, useMarkPrice } from '../../utils/markets';
import { isEqual, getDecimalCount } from '../../utils/utils';
import { useInterval } from '../../utils/useInterval';
import usePrevious from '../../utils/usePrevious';
import { ArrowDownwardSharp, ArrowUpwardSharp } from '@mui/icons-material';

export default function OrderBook({ depth = 500, onPrice, onSize }) {
  const markPrice = useMarkPrice();
  const [orderbook] = useOrderbook();
  const { baseCurrency, quoteCurrency } = useMarket();

  const currentOrderbookData = useRef(null);
  const lastOrderbookData = useRef(null);

  const [orderbookData, setOrderbookData] = useState(null);

  const callInterval = 500;

  useInterval(() => {
    if (
      !currentOrderbookData.current ||
      JSON.stringify(currentOrderbookData.current) !==
        JSON.stringify(lastOrderbookData.current)
    ) {
      let bids = orderbook?.bids || [];
      let asks = orderbook?.asks || [];

      let sum = (total, [, size], index) =>
        index < depth ? total + size : total;
      let totalSize = bids.reduce(sum, 0) + asks.reduce(sum, 0);

      let bidsToDisplay = getCumulativeOrderbookSide(bids, totalSize, false);
      let asksToDisplay = getCumulativeOrderbookSide(asks, totalSize, true);

      currentOrderbookData.current = {
        bids: orderbook?.bids,
        asks: orderbook?.asks,
      };

      setOrderbookData({ bids: bidsToDisplay, asks: asksToDisplay });
    }
  }, callInterval);

  useEffect(() => {
    lastOrderbookData.current = {
      bids: orderbook?.bids,
      asks: orderbook?.asks,
    };
  }, [orderbook]);

  function getCumulativeOrderbookSide(orders, totalSize, backwards = false) {
    let cumulative = orders
      .slice(0, depth)
      .reduce((cumulative, [price, size], i) => {
        const cumulativeSize = (cumulative[i - 1]?.cumulativeSize || 0) + size;
        cumulative.push({
          price,
          size,
          cumulativeSize,
          sizePercent: Math.round((cumulativeSize / (totalSize || 1)) * 100),
        });
        return cumulative;
      }, []);
    if (backwards) {
      cumulative = cumulative.reverse();
    }
    return cumulative;
  }

  return (
    <div
      className={
        'rounded-2xl bg-primary-bg w-full p-5 shadow-lineShadow h-full grid'
      }
    >
      <div>
        <div className={'text-center'}>
          <MarkPriceComponent markPrice={markPrice} />
        </div>
      </div>
      <div className={'flex justify-between'}>
        <span className={'text-xs font-bold   opacity-50'}>
          Size({baseCurrency})
        </span>
        <span
          className={'text-xs font-bold   opacity-50'}
          span={12}
          style={{
            textAlign: 'center',
          }}
        >
          Price({quoteCurrency})
        </span>
        <span
          className={'text-xs font-bold   opacity-50'}
          span={6}
          style={{
            textAlign: 'right',
          }}
        >
          Size({baseCurrency})
        </span>
      </div>
      <div className="mt-2 grid grid-cols-2 overflow-y-scroll ">
        <div className={'text-left'}>
          {orderbookData?.bids.map(({ price, size, sizePercent }, idx) => (
            <OrderbookRow
              key={price + ''}
              price={price}
              size={size}
              side={'buy'}
              sizePercent={sizePercent}
              onPriceClick={() => onPrice(price)}
              onSizeClick={() => onSize(size)}
              isTop={idx === 0}
            />
          ))}
        </div>
        <div>
          {orderbookData?.asks
            .map(({ price, size, sizePercent }, idx) => (
              <OrderbookRowTwo
                key={price + ''}
                price={price}
                size={size}
                side={'sell'}
                sizePercent={sizePercent}
                onPriceClick={() => onPrice(price)}
                onSizeClick={() => onSize(size)}
              />
            ))
            .reverse()}
        </div>
      </div>
    </div>
  );
}

const OrderbookRow = React.memo(
  ({ side, price, size, sizePercent, onSizeClick, onPriceClick }) => {
    const element = useRef();

    const { market } = useMarket();

    useEffect(() => {
      // eslint-disable-next-line
      !element.current?.classList.contains('flash') &&
        element.current?.classList.add('flash');
      const id = setTimeout(
        () =>
          element.current?.classList.contains('flash') &&
          element.current?.classList.remove('flash'),
        250,
      );
      return () => clearTimeout(id);
    }, [price, size]);
    let formattedSize =
      market?.minOrderSize && !isNaN(size)
        ? Number(size).toFixed(getDecimalCount(market.minOrderSize) + 1)
        : size;

    let formattedPrice =
      market?.tickSize && !isNaN(price)
        ? Number(price).toFixed(getDecimalCount(market.tickSize) + 1)
        : price;

    return (
      <div ref={element} className={'mb-[1px]'} onClick={onSizeClick}>
        <div
          className={
            'flex justify-between relative text-left text-xs font-bold'
          }
        >
          <span>{formattedSize}</span>
          <div className={'w-full flex justify-end'}>
            <div
              className={`absolute right-0 text-[#33cc33] `}
              onClick={onPriceClick}
            >
              {formattedPrice}
            </div>
            <div
              style={{
                width: sizePercent + '%',
                backgroundColor: side === 'buy' ? '#33cc33' : '#ec0408',
                opacity: 0.3,
              }}
              className={`h-[100%]`}
            />
          </div>
        </div>
      </div>
    );
  },
  (prevProps, nextProps) =>
    isEqual(prevProps, nextProps, ['price', 'size', 'sizePercent']),
);

const OrderbookRowTwo = React.memo(
  ({ side, price, size, sizePercent, onSizeClick, onPriceClick }) => {
    const element = useRef();

    const { market } = useMarket();

    useEffect(() => {
      // eslint-disable-next-line
      !element.current?.classList.contains('flash') &&
        element.current?.classList.add('flash');
      const id = setTimeout(
        () =>
          element.current?.classList.contains('flash') &&
          element.current?.classList.remove('flash'),
        250,
      );
      return () => clearTimeout(id);
    }, [price, size]);

    let formattedSize =
      market?.minOrderSize && !isNaN(size)
        ? Number(size).toFixed(getDecimalCount(market.minOrderSize) + 1)
        : size;
    let formattedPrice =
      market?.tickSize && !isNaN(price)
        ? Number(price).toFixed(getDecimalCount(market.tickSize) + 1)
        : price;
    return (
      <div ref={element} className={'mb-[1px] max-h-fit'} onClick={onSizeClick}>
        <div
          className={
            'flex justify-between relative text-left text-xs font-bold'
          }
        >
          <div className={'w-full flex justify-start'}>
            <span
              className={`absolute left-0 text-[#ec0408] m-0 `}
              onClick={onPriceClick}
            >
              {formattedPrice}
            </span>
            <div
              style={{
                width: sizePercent + '%',
                backgroundColor: side === 'buy' ? '#33cc33' : '#ec0408',
                opacity: 0.3,
              }}
              className={`h-[100%]`}
            />
          </div>
          <span>{formattedSize}</span>
        </div>
      </div>
    );
  },
  (prevProps, nextProps) =>
    isEqual(prevProps, nextProps, ['price', 'size', 'sizePercent']),
);

const MarkPriceComponent = React.memo(
  ({ markPrice }) => {
    const { market } = useMarket();
    const previousMarkPrice = usePrevious(markPrice);

    let markPriceColor =
      markPrice > previousMarkPrice
        ? '#33CC33'
        : markPrice < previousMarkPrice
        ? '#EC0408'
        : 'white';

    let formattedMarkPrice =
      markPrice &&
      market?.tickSize &&
      markPrice.toFixed(getDecimalCount(market.tickSize));

    return (
      <div className={'grid items-center justify-center'}>
        <p className={'text-md font-bold uppercase'}>
          Orderbook
          <div className={'inline-block'}>
            {markPrice > previousMarkPrice && (
              <ArrowUpwardSharp
                style={{
                  color: '#33CC33',
                  fontSize: '1em',
                }}
              />
            )}
            {markPrice < previousMarkPrice && (
              <ArrowDownwardSharp
                style={{
                  color: '#EC0408',
                  fontSize: '1em',
                }}
              />
            )}
          </div>
        </p>

        <span
          className={`text-sm font-bold`}
          style={{
            color: markPriceColor,
          }}
        >
          {formattedMarkPrice || '----'}
        </span>
      </div>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps, ['markPrice']),
);

export const footerElements = [
  {
    description: 'Tiktok',
    link: 'https://tiktok.com/NinjaProtocol',
    img: '/images/tiktok.svg',
  },
  {
    description: 'Medium',
    link: 'https://www.medium.com/@NinjaSolProto',
    img: '/images/medium.svg',
  },
  {
    description: 'Telegram',
    link: 'https://t.me/ninjamoney',
    img: '/images/telegram.svg',
  },
  {
    description: 'Discord',
    link: 'https://discord.gg/ninjaprotocol',
    img: '/images/discord.svg',
  },
  {
    description: 'Instagram',
    link: 'https://www.instagram.com/ninjaprotocol/',
    img: '/images/instagram.svg',
  },
  {
    description: 'Twitter',
    link: 'https://www.twitter.com/@NinjaSolProto',
    img: '/images/twitter.svg',
  },
  {
    description: 'Youtube',
    link: 'https://www.youtube.com/ninjaprotocol',
    img: '/images/youtube.svg',
  },
  {
    description: 'Twitch',
    link: 'http://www.twitch.tv/ninjaprotocol',
    img: '/images/twitch.svg',
  },
];

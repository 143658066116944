

import React, { forwardRef, InputHTMLAttributes, ReactNode } from 'react';

interface InputProps {
  type: string
  value: any
  onChange: (e) => void
  className?: string
  disabled?: boolean
  prefixClassname?: string
  error?: boolean
  [x: string]: any
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    type,
    value,
    onChange,
    className,
    error,
    wrapperClassName = 'w-full',
    disabled,
    prefix,
    prefixClassName,
    suffix,
  } = props
  return (
    <div className={`relative flex ${wrapperClassName}`}>
      {prefix ? (
        <div
          className={`absolute left-2 top-1/2 -translate-y-1/2 transform ${prefixClassName}`}
        >
          {prefix}
        </div>
      ) : null}
      <input
        className={`${className} h-10 w-full flex-1 rounded-md border bg-th-bkg-1 px-2 pb-px
          text-th-fgd-1 ${
          error ? 'border-th-red' : 'border-th-bkg-4'
        } default-transition hover:border-th-fgd-4
          focus:border-th-fgd-4 focus:outline-none
          ${
          disabled
            ? 'cursor-not-allowed bg-th-bkg-3 text-th-fgd-3 hover:border-th-fgd-4'
            : ''
        }
          ${prefix ? 'pl-7' : ''}
          ${suffix ? 'pr-11' : ''}`}
        disabled={disabled}
        ref={ref}
        {...props}
        type={type}
        value={value}
        onChange={onChange}
      />
      {suffix ? (
        <span className="absolute right-0 flex h-full items-center bg-transparent pr-2 text-xs text-th-fgd-4">
          {suffix}
        </span>
      ) : null}
    </div>
  )
})

export default Input

interface LabelProps {
  children: ReactNode
  className?: string
}

export const Label = ({ children, className }: LabelProps) => (
  <label className={`mb-1.5 block text-th-fgd-2 ${className}`}>
    {children}
  </label>
)

interface PrefixSuffixInputProps {
  classNames?: string,
  onChange?: (e) => void,
  prefix: ReactNode | string,
  suffix:  ReactNode | string,
  props?: InputHTMLAttributes<any>
}

export const PrefixSuffixInput = ({ classNames, onChange, prefix, suffix, props}: PrefixSuffixInputProps) => (
  <div
    className={`grid grid-cols-[3.5rem_1fr_3.5rem] w-full items-center rounded-full h-10 bg-primary-bg-600 hover:shadow-md ${classNames}`}>
              <span
                className="rounded-l-2xl flex justify-center items-center rounded-r-md w-14 h-full bg-primary-bg-300 text-white text-xs font-bold uppercase outline-none">{prefix}
              </span>
    <input
           className="bg-transparent rounded-full w-full py-1 pr-4 outline-none text-sm text-end border-none"
           onChange={onChange}
           {...props}
    />
    <div className="pr-5 w-14">
      <span className={"text-sm font-bold"}>{suffix}</span>
    </div>
  </div>
)


interface SuffixInputProps {
  classNames?: string,
  onChange?: (e) => void,
  suffix:  ReactNode | string,
  props?: InputHTMLAttributes<any>
}
export const SuffixInput = ({ classNames, onChange, suffix, props}: SuffixInputProps) => (
  <div
    className={`grid grid-cols-[1fr_3.5rem] w-full items-center  rounded-l h-10  bg-primary-bg-600 hover:shadow-md`}>
    <input
           className="bg-transparent w-full rounded-full text-sm text-end border-none focus:outline-none active:outline-none "
           onChange={onChange}
           {...props}
    />
    <div className="pr-5">
      <span className={"text-sm font-bold"}>{suffix}</span>
    </div>
  </div>
)

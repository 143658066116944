import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';

import App from './components/App';
import * as serviceWorker from './serviceWorker';
import * as themes from './theme/schema.json';
import { setToLS } from './theme/storage';

const Index = () => {
  setToLS('all-themes', themes.default);

  return <App />;
};
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Index />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import styled from 'styled-components';

export default styled.div`
  display: flex;
  border-radius: 6px;
  align-items: center;

  @media screen and (max-width: 600px) {
    min-height: 43px;
  }
`;

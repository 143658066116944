import {
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey, sendAndConfirmTransaction,
  Signer,
  SystemProgram, Transaction,
  TransactionInstruction,
} from '@solana/web3.js';
import { Creator, DataV2 } from '@metaplex-foundation/mpl-token-metadata';
import { MintInfo } from './tokens';
import {
  AR_SOL_HOLDER_ID,
  ARWEAVE_UPLOAD_URL,
  ArweaveEnv,
  StorageProvider,
  truthy,
} from '@strata-foundation/spl-utils';
import { calculate } from '@metaplex/arweave-cost';
import forge from 'node-forge';
import { useWallet } from '@solana/wallet-adapter-react';
import {
  SendTransactionFunc,
  sendTransactionWithNotification,
  signSendAndConfirmTransaction,
} from './send';
import { notify } from './notifications';
export interface IUploadMetadataArgs {
  payer: PublicKey;
  name: string;
  symbol: string;
  description?: string;
  image?: File;
  creators?: Creator[];
  attributes?: Attribute[];
  animationUrl?: string;
  externalUrl?: string;
  extraMetadata?: any;
  provider?: StorageProvider;
  mint?: PublicKey;
}

export interface ICreateArweaveUrlArgs {
  payer: PublicKey;
  name: string;
  symbol: string;
  description?: string;
  image?: string;
  creators?: Creator[];
  files?: File[];
  existingFiles?: FileOrString[];
  attributes?: Attribute[];
  animationUrl?: string;
  externalUrl?: string;
  extraMetadata?: any;
}

export type Attribute = {
  trait_type?: string;
  display_type?: string;
  value: string | number;
};

export type MetadataFile = {
  uri: string;
  type: string;
};

export type FileOrString = MetadataFile | string;

export interface IMetadataExtension {
  name: string;
  symbol: string;

  creators: Creator[] | null;
  description: string;
  // preview image absolute URI
  image: string;
  animation_url?: string;

  attributes?: Attribute[];

  // stores link to item on meta
  external_url: string;

  seller_fee_basis_points: number;

  properties: {
    files?: FileOrString[];
    category: MetadataCategory;
    maxSupply?: number;
    creators?: {
      address: string;
      shares: number;
    }[];
  };
}

const MEMO_ID = new PublicKey('MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr');

export interface ICreateMasterEditionInstructionsArgs {
  mint: PublicKey;
  mintAuthority?: PublicKey;
  payer?: PublicKey;
}

export interface IVerifyCollectionInstructionsArgs {
  collectionMint: PublicKey;
  nftMint: PublicKey;
  payer?: PublicKey;
}

export interface ICreateMetadataInstructionsArgs {
  data: DataV2;
  authority?: PublicKey;
  mintAuthority?: PublicKey;
  mint: PublicKey;
  payer?: PublicKey;
}

export interface IUpdateMetadataInstructionsArgs {
  data?: DataV2 | null;
  newAuthority?: PublicKey | null;
  metadata: PublicKey;
  payer?: PublicKey;
  /** The update authority to use when updating the metadata. **Default:** Pulled from the metadata object. This can be useful if you're chaining transactions */
  updateAuthority?: PublicKey;
}

export enum MetadataCategory {
  Audio = 'audio',
  Video = 'video',
  Image = 'image',
  VR = 'vr',
}

export interface ITokenWithMeta {
  displayName?: string;
  metadataKey?: PublicKey;
  mint?: MintInfo;
  data?: IMetadataExtension;
  image?: string;
  description?: string;
}

type ArweaveFile = {
  filename: string;
  status: 'success' | 'fail';
  transactionId?: string;
  error?: string;
};

interface IArweaveResult {
  error?: string;
  messages?: Array<ArweaveFile>;
}

const USE_CDN = false; // copied from metaplex. Guess support isn't there yet?
const routeCDN = (uri: string) => {
  let result = uri;
  if (USE_CDN) {
    result = uri.replace(
      'https://arweave.net/',
      'https://coldcdn.com/api/cdn/bronil/',
    );
  }

  return result;
};


export async function getArweaveMetadata(
  uri: string | undefined,
): Promise<IMetadataExtension | undefined> {
  if (uri && uri.length > 0) {
    const newUri = routeCDN(uri);

    const cached = localStorage.getItem(newUri);
    if (cached) {
      return JSON.parse(cached);
    } else {
      try {
        // TODO: BL handle concurrent calls to avoid double query
        const result = await fetch(newUri);
        let data = await result.json();
        if (data.uri) {
          data = {
            ...data,
            ...(await getArweaveMetadata(data.uri)),
          };
        }
        try {
          localStorage.setItem(newUri, JSON.stringify(data));
        } catch (e) {
          // ignore
        }
        return data;
      } catch (e) {
        console.log(`Could not fetch from ${uri}`, e);
        return undefined;
      }
    }
  }
}

export async function uploadMetadata(args: IUploadMetadataArgs, connection: Connection, sendTransaction: SendTransactionFunc): Promise<string> {
  return createArweaveMetadata({
    ...args,
    image: args.image?.name,
    files: [args.image].filter(truthy),
    mint: args.mint!,
  }, connection, sendTransaction);
}


export async function uploadToArweave(
  txid: string,
  mintKey: PublicKey,
  files: File[],
  uploadUrl: string = ARWEAVE_UPLOAD_URL,
  env: ArweaveEnv = 'mainnet-beta',
): Promise<IArweaveResult> {
  // this means we're done getting AR txn setup. Ship it off to ARWeave!
  const data = new FormData();
  data.append('transaction', txid);
  data.append('env', env);

  const tags = files.reduce(
    (acc: Record<string, Array<{ name: string; value: string }>>, f) => {
      acc[f.name] = [{ name: 'mint', value: mintKey.toBase58() }];
      return acc;
    },
    {},
  );

  data.append('tags', JSON.stringify(tags));
  files.map((f) => data.append('file[]', f));

  // TODO: convert to absolute file name for image

  const resp = await fetch(uploadUrl, {
    method: 'POST',
    // @ts-ignore
    body: data,
  });

  if (!resp.ok) {
    return Promise.reject(
      new Error(
        'Unable to upload the artwork to Arweave. Please wait and then try again.',
      ),
    );
  }

  const result: IArweaveResult = await resp.json();

  if (result.error) {
    return Promise.reject(new Error(result.error));
  }

  return result;
}


export async function getArweaveUrl({
                                      txid,
                                      mint,
                                      files = [],
                                      uploadUrl = ARWEAVE_UPLOAD_URL,
                                      env = 'mainnet-beta',
                                    }: {
  env: ArweaveEnv;
  uploadUrl?: string;
  txid: string;
  mint: PublicKey;
  files?: File[];
}): Promise<string> {
  const result = await uploadToArweave(txid, mint, files, uploadUrl, env);

  const metadataFile = result.messages?.find(
    (m) => m.filename === 'manifest.json',
  );

  if (!metadataFile) {
    throw new Error('Metadata file not found');
  }

// Use the uploaded arweave files in token metadata
  return `https://arweave.net/${metadataFile.transactionId}`;
}

export async function createArweaveMetadata(
  args: ICreateArweaveUrlArgs & {
    env?: ArweaveEnv;
    uploadUrl?: string;
    mint: PublicKey;
  },connection: Connection,
  sendTransaction: SendTransactionFunc
): Promise<string> {
  const { txid, files } = await presignCreateArweaveUrl(args, connection, sendTransaction);
  let env = args.env;
  if (!env) {
    // @ts-ignore
    const url: string = connection._rpcEndpoint;
    if (url.includes('devnet')) {
      env = 'devnet';
    } else {
      env = 'mainnet-beta';
    }
  }


  const uri = await getArweaveUrl({
    txid,
    mint: args.mint,
    files,
    env,
    uploadUrl: args.uploadUrl || ARWEAVE_UPLOAD_URL,
  });

  return uri;
}

export function getFilesWithMetadata(
  files: File[],
  metadata: {
    name: string;
    symbol: string;
    description: string;
    image: string | undefined;
    animationUrl: string | undefined;
    externalUrl: string;
    properties: any;
    attributes: Attribute[] | undefined;
    creators: Creator[] | null;
    sellerFeeBasisPoints: number;
  },
): File[] {
  const metadataContent = {
    name: metadata.name,
    symbol: metadata.symbol,
    description: metadata.description,
    seller_fee_basis_points: metadata.sellerFeeBasisPoints,
    image: metadata.image,
    animation_url: metadata.animationUrl,
    external_url: metadata.externalUrl,
    attributes: metadata.attributes,
    properties: {
      ...metadata.properties,
      creators: metadata.creators?.map((creator) => {
        return {
          address: creator.address,
          share: creator.share,
        };
      }),
    },
  };

  const realFiles: File[] = [
    ...files,
    new File([JSON.stringify(metadataContent)], 'metadata.json'),
  ];

  return realFiles;
}

export const prePayForFilesInstructions = async (
  payer: PublicKey,
  files: File[],
): Promise<TransactionInstruction[]> => {
  const instructions: TransactionInstruction[] = [];
  const sizes = files.map((f) => f.size);
  const result = await calculate(sizes);

  const lamports = Math.ceil(LAMPORTS_PER_SOL * result.solana);
  instructions.push(
    SystemProgram.transfer({
      fromPubkey: payer,
      toPubkey: AR_SOL_HOLDER_ID,
      lamports,
    }),
  );

  for (let i = 0; i < files.length; i++) {

    const hashSum = forge.md.sha256.create();
    hashSum.update(await files[i].text());
    const hex = hashSum.digest().toHex();
    instructions.push(
      new TransactionInstruction({
        keys: [],
        programId: MEMO_ID,
        data: Buffer.from(hex),
      }),
    );
  }

  return instructions;
};

export async function presignCreateArweaveUrlInstructions({
                                                            name,
                                                            symbol,
                                                            description = '',
                                                            image,
                                                            creators,
                                                            files = [],
                                                            existingFiles,
                                                            attributes,
                                                            payer,
                                                            externalUrl,
                                                            animationUrl,
                                                            extraMetadata,
                                                          }: ICreateArweaveUrlArgs): Promise<{ instructions: TransactionInstruction[], signers: Signer[], files: File[] }> {
  const metadata = {
    name,
    symbol,
    description,
    image,
    attributes,
    externalUrl: externalUrl || '',
    animationUrl,
    properties: {
      category: MetadataCategory.Image,
      files: [...(existingFiles || []), ...files],
    },
    creators: creators ? creators : null,
    sellerFeeBasisPoints: 0,
    ...(extraMetadata || {}),
  };
  const realFiles = getFilesWithMetadata(files, metadata);

  const prepayTxnInstructions = await prePayForFilesInstructions(
    payer,
    realFiles,
  );
  return {
    instructions: prepayTxnInstructions,
    signers: [],
    files: realFiles,
  };
}

export async function presignCreateArweaveUrl(
  args: ICreateArweaveUrlArgs, connection: Connection, sendTransaction: SendTransactionFunc
): Promise<{ files: File[]; txid: string }> {
  const {
    files,
    instructions,
    signers,
  } = await presignCreateArweaveUrlInstructions(args);

  const txid = await signSendAndConfirmTransaction({
    sendTransaction,
    transaction: new Transaction().add(...instructions),
    connection,
    signers
  })

  return {
    files,
    txid,
  };
}
